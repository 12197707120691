import { Col } from 'react-bootstrap';
import GeneralBox from '../GeneralBox/generalBox.component';
import TableData from '../TableData/tableData.component';
const colors = ['danger']

export const BarProgress =({data}) => {
  // Titulos de las columnas
  const columns = [
    {
      text: 'Waste',
      key: 'nombre',
      sortable: true,
    },
    {
      text: 'Cm2',
      key: 'cm2',
      sortable: true,
    },
    {
      text: 'Costo',
      key: 'costo',
      sortable: true,
    }   
  ];

  return (    
    data.map(({items, title}, index) => (
        <Col xs='12' md="12" lg="12">
            <GeneralBox 
              styles={{textAlign: 'center', alignItems: 'center'}}
            >
              <h3 className={`my-0 fw-500 text-${colors[index]}`}>
                {title}
              </h3>
              {items.length > 0 ? <TableData isCountable={false} columns={columns} data={items} /> : <div>No se encontraron datos</div>}
          </GeneralBox>
      </Col> 
    ))  
  )
}


