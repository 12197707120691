import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Componentes Bootstrap
import {
  Col,
  Row,
} from 'react-bootstrap';
// Estilos
import './viewBoxes.styled.css';
// Assets
import { ReactComponent as IconQrCode } from '../../assets/svg/icon-qr.svg';

// Componente de caja
const BoxItem = ({ 
  quantity,
  title,
  emptySpace,
  onClickMain,
  onClickDetail,
  onClickQr,
  detailText,
  detail2Text }) => {
  // Manejar cambio de tamaño de pantalla
  const [elementSize, setElementSize] = useState(0);
  const handleResize = e => {
    const WindowWidth = window.innerWidth;
    if (WindowWidth < 650) {
      setElementSize(20);
    } else if (WindowWidth >= 650 && WindowWidth < 1024) {
      setElementSize(30);
    } else if (WindowWidth >= 1024) {
      setElementSize(50);
    }
  }
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  if (emptySpace)
    return (
      <Col>
        <div className="section-bx">
          <div className={`w-${elementSize} h-${elementSize} m-5`}>	</div>
        </div>
      </Col>
    )
  const isEmpty = quantity === 0;
  return (
    <Col>
      <div className="section-bx">
        <div
          className={`info-container w-${elementSize} h-${elementSize} m-5 d-block rounded10 ${isEmpty ? "bg-warning-light" : "bg-warning"}`}>
          <div className="bx-dec">
            <div className="section-dec d-flex align-items-center">
              <div className="box-img" onClick={() => { onClickQr() }}>
                <div className="svg-container">
                  <IconQrCode />
                </div>
              </div>
              <div className="dec">
                <h4 className="text-white my-0">{title}</h4>
                <p className="text-white">
                  {!isEmpty ? `${quantity} Productos` : 'vacío'}
                </p>
                {
                  detailText !== ''
                    ? <button
                      className={`text-white hover-big bg-info py-5 rounded mb-5`}
                      onClick={() => { onClickDetail() }}
                    >
                      {detailText}
                    </button>
                    : <></>
                }
                <button className={`text-white hover-big bg-primary py-5 rounded`} onClick={() => { onClickMain() }}>
                  {detail2Text}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}
// Documentacion con PropTypes
BoxItem.propTypes = {
  quantity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  emptySpace: PropTypes.bool.isRequired,
  onClickMain: PropTypes.func,
  onClickDetail: PropTypes.func,
  onClickQr: PropTypes.func,
  detailText: PropTypes.string,
  detail2Text: PropTypes.string,
}
BoxItem.defaultProps = {
  quantity: 0,
  name: '',
  emptySpace: false,
  onClickMain: () => { },
  onClickDetail: () => { },
  onClickQr: () => { },
  detailText: '',
  detail2Text: 'Detalle',
}

// --------------------------------------------------
// Componente para dibujar filas y columnas
const ViewBoxes = ({
  title,
  arrayBoxes,
  onBoxClick,
  onDetailClick,
  onClickQr,
  detailText,
  detail2Text, }) => {
  // Array con numeros de columnas
  const colsBoxIndex = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  
  return (
    <Row className="mb-50">
      <Col xs="12" >
        <h3 className="mb-20 px-5">{title}</h3>
      </Col>
      {/* Contenido de cajas */}
      <Col xs="12">
        <Row className="mb-10">
          <Col xs="12">
            {
              arrayBoxes.map((row, index) => (
                <Row className="g-0 row-cols-auto" key={index}>
                  {
                    colsBoxIndex.map(j => {
                      const element = row[j];
                      return (
                        typeof element === "undefined"
                          ? <BoxItem
                            key={j}
                            emptySpace={true}
                          />
                          : <BoxItem
                            key={j}
                            quantity={element.quantity}
                            title={element.name}
                            onClickMain={() => { onBoxClick(element.id) }}
                            onClickDetail={() => { onDetailClick(element.id) }}
                            onClickQr={() => { onClickQr(element.id) }}
                            detailText={detailText}
                            detail2Text={detail2Text}
                          />
                      )
                    })
                  }
                </Row>
              ))
            }
          </Col>
        </Row>
      </Col>
      {/* Leyenda */}
      <Col xs="12" className="px-20">
        <div className="d-flex mt-20">
          <div className="d-flex">
            <div className="w-20 h-20 bg-warning-light rounded"></div>
            <h5 className="mx-15 my-0">Lugar Libre</h5>
          </div>
          <div className="d-flex">
            <div className="w-20 h-20 bg-warning rounded"></div>
            <h5 className="mx-15 my-0">Lugar Ocupado</h5>
          </div>
        </div>
      </Col>
    </Row>
  );
}
// Documentacion con PropTypes
ViewBoxes.propTypes = {
  title: PropTypes.string.isRequired,
  arrayBoxes: PropTypes.array.isRequired,
  onBoxClick: PropTypes.func,
  onDetailClick: PropTypes.func,
  onClickQr: PropTypes.func,
  detailText: PropTypes.node,
  detail2Text: PropTypes.node,
}
ViewBoxes.defaultProps = {
  onBoxClick: () => { },
  onTextClick: () => { },
  detailText: ''
}
export default ViewBoxes;