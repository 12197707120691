import { Col } from 'react-bootstrap';
import { SmallDetailBox2 } from '../SmallDetailBox2/SmallDetailBox2';

const fondos = ['#6cbbfa', '#00875a', '#fbc66c', '#fb5ea8']
export const CardTotales2 = ({totales}) => {

    return(
        <>
            {
                totales.map(({title, data, costo, unidad, porcentaje, leftColumnTitle}, index) => (
                    <Col xs='12' md="6" lg="3">  
                        <SmallDetailBox2
                            principalData={Intl.NumberFormat('en-MX').format(data) !== "NaN" ? Intl.NumberFormat('en-MX').format(data) : '0'}
                            complementPrincipalData={unidad ? ` ${unidad}` : <br></br>}
                            principalTitle={title.charAt(0).toUpperCase()+title.slice(1)}
                            rigthColumnData={costo ? `${Intl.NumberFormat('en-MX').format(costo)} USD` : undefined}
                            rigthColumnTitle={costo ? title : undefined}
                            leftColumnData={porcentaje}
                            leftColumnTitle={leftColumnTitle}
                            fontColorPricipalData = '#FFFFFF'
                            fontColorPricipalTitle = '#FFFFFF'
                            fontColorDataColumns =  {title === "productivity" ? '#00875a' : title === "waste" ? '#fbc66c' : '#6cbbfa'}
                            fontColorTitleColumns =  '#1C2D4C'
                            backgroundColor={fondos[index % fondos.length]}
                        />
                    </Col>
                ))
            }
        </>
    )
}