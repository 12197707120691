import { useState, useEffect, useContext } from "react";
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from "sweetalert2";
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
    apiGetRackId,
    apiPatchLevel,
    apiGetLevelId
} from '../../../common/api-invent';

const EditLevelModal = ({ show, setShow, id }) => {
    const { onReloadComponent } = useContext(ReloadContext);

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [rackInfo, setRackInfo] = useState({});

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        nombre: '',
        nivel: '',
        codigo_ubicacion: '',
        id_rack: '0',
        cantidad_cajas: '',
        descripcion: '',
        golden: ''
    });

    // Carga de datos del nivel
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Solicitud del nivel
                const reqLev = await apiGetLevelId(id);
                setData({
                    ...reqLev.data.content,
                    cantidad_cajas: reqLev.data.content.cantidad_cajas ?? '0'
                })
                // Solicitud de racks
                const reqRac = await apiGetRackId(reqLev.data.content.id_rack);
                setRackInfo({ ...reqRac.data.content })
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData();
        return () => {
            setLoading(false);
        }
    }, [show, setLoading, setRackInfo, setData, id])

    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre',
                show: true
            });
            return false;
        }
        if (data.nivel === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nivel',
                show: true
            });
            return false;
        }
        if (data.descripcion === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere descripción',
                show: true
            });
            return false;
        }
        if (data.cantidad_cajas === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere cantidad de embalajes',
                show: true
            });
            return false;
        }
        return true;
    }
    // Insertar un nuevo nivel
    const handleSubmit = async event => {
        event.preventDefault();

        if (isFormValid()) {
            try {
                const sendData = { ...data };
                delete sendData.id_rack;
                const response = await apiPatchLevel(id, sendData);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu nivel ha sido editado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Carga de componente
                    onReloadComponent(1);
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al editar nivel");
                }
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data?.msg ?? 'Error al editar nivel',
                        text: 'Intentelo nuevamente',
                        confirmButtonText: 'Aceptar',
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al conectar con los servicios',
                        confirmButtonText: 'Aceptar',
                    });
                }
            }
        }
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Editar Nivel"
                buttonTitle="Guardar"
                handleClick={handleSubmit}
                size="md"
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_rack" className="form-label">
                                            Rack:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="id_rack"
                                            defaultValue={`${rackInfo.nombre} - ${rackInfo.codigo_rack}`}
                                            disabled={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="nombre" className="form-label">
                                            Nombre: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            value={data.nombre}
                                            onChange={handleFormChange}
                                            placeholder="Nivel 1-A"
                                        />
                                    </div>
                                </Col>
                                <Col md="4" xs="6">
                                    <div className="form-group">
                                        <label htmlFor="codigo_ubicacion" className="form-label">
                                            Código Ubicación: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="codigo_ubicacion"
                                            name="codigo_ubicacion"
                                            value={data.codigo_ubicacion}
                                            onChange={handleFormChange}
                                            placeholder="UB-0001"
                                        />
                                    </div>
                                </Col>
                                <Col md="2" xs="6">
                                    <div className="form-group">
                                        <label htmlFor="nivel" className="form-label">
                                            Nivel: *
                                        </label>
                                        <input
                                            value={data.nivel}
                                            onChange={handleFormChange}
                                            type="number"
                                            className="form-control"
                                            name="nivel"
                                            id="nivel"
                                            min="1"
                                            step="1"
                                            placeholder="1"
                                        />
                                    </div>
                                </Col>
                                <Col xs="5">
                                    <div className="form-group">
                                        <label htmlFor="cantidad_cajas" className="form-label">
                                            Cantidad de Embalajes: *
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="cantidad_cajas"
                                            name="cantidad_cajas"
                                            value={data.cantidad_cajas}
                                            onChange={handleFormChange}
                                            placeholder="3"
                                            min="1"
                                        />
                                    </div>
                                </Col>
                                <Col xs="7">
                                    <div className="form-group">
                                        <label htmlFor="descripcion" className="form-label">
                                            Descripción:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="descripcion"
                                            name="descripcion"
                                            value={data.descripcion}
                                            onChange={handleFormChange}
                                            placeholder="Nivel para almacenar placas de 60x60"
                                        />
                                    </div>
                                </Col>
                                <Col xs="5">
                                    <div className="form-group">
                                        <label htmlFor="golden" className="form-label">
                                            Golden:
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="golden"
                                            name="golden"
                                            value={data.golden}
                                            onChange={handleFormChange}
                                            
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default EditLevelModal;