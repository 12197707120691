const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className="main-footer">
            <div className="pull-right d-none d-sm-inline-block">
                Version 1.1.0 |
            </div>
            {` ${year} `}
            <a
                href="https://www.ferazinventarios.com"
                target="_blank"
                rel="noreferrer"
            >
                Feraz Inventarios.
            </a>
            Todos los derechos reservados.
        </footer>
    );
}

export default Footer;