import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const CustomSelect = ({ options, value, onChange, name }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(options.find((option) => option.value === value));
  }, [options, value]);

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    const selectedValue = selectedOption ? selectedOption.value : '';
    const event = {
      target: {
        name,
        value: selectedValue,
      },
    };
    onChange(event);
  };

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={handleSelectChange}
      isClearable
    />
  );
};

CustomSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default CustomSelect;
