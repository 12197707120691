import { useEffect, useState } from 'react';
import { Row, ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import Load from '../Load/load.component';
import GeneralBox from '../GeneralBox/generalBox.component';
import { FormParams, CardTotales2, ChartDonuts, ChartApex, ChartBarApex, BarProgress } from './index';
import { apiGetIndicators } from '../../common/api-invent';
import { useForm } from '../../hooks/useForm';

// Contiene los elementos que deben generar un chartDonut
const elementsChartDonuts = ['productivity', 'yield', 'waste']
// Contiene los elementos que deben generar un chart
const elementChart = ['spinning']
// Contiene los elementos que deben generar un chart
const elementChartBar = ['yieldByReason']
// Contiene los elementos que deben generar un progress
const elementProgress = ['waste']

//Devuelve los datos para generar un donutChart
const dataCharDonut = (items, prop) => {
    const data = {
        title: prop,
        dataKey: 'porcentaje',
        items: items.map((el) => {
            return{
                ...el,
                cm2: parseFloat(el.cm2),
                porcentaje: parseFloat(el.porcentaje),
                label: `${el.porcentaje}
                        CM2: ${parseFloat(el.cm2)}
                        COSTO: ${el.costo !== null ? el.costo.toFixed(2) : ''}`
            }
        })
    }
    return data
}

//Devuelve los datos para generar un chart
const dataChart = (items, prop) => {
    const data = {
        title: prop,
        dataKey: 'cantidad',
        items: items.map((ele) => {
            return{
                ...ele,
                cantidad: Number(ele.cantidad),
            }
        })
    }
    return data
} 

//Devuelve los datos para generar un chartBar
const dataChartBar = (items, prop) => {
    const data = {
        title: 'yield',
        dataKey: 'costo',
        items: items.map((ele) => {
            return{
                ...ele,
                cm2: parseFloat(ele.cm2),
                costo: Number(ele.costo !== null ? ele.costo.toFixed(2) : ''),
            }
        })
    }
    return data
} 

//Devuelve los datos para generar un progress
const dataProgress = (items, prop) => {
    const data = {
        title: prop,
        dataKey: 'costo',
        items: items.map((row) => {
            return{
                ...row,
                cm2: (
                    <ProgressBar 
                        variant='success'
                        animated 
                        now={parseFloat(row.porcentaje.replace("%", "")) <= 25 ? parseFloat(row.porcentaje.replace("%", "")) + 25 :  parseFloat(row.porcentaje.replace("%", ""))}  
                        label={row.cm2+" cm2"} 
                        style={{height: "35px", width:"400px"}}
                    />
                ),
                costo: (
                    <ProgressBar 
                        variant='danger'
                        animated 
                        now={parseFloat(row.porcentaje.replace("%", "")) <= 25 ? parseFloat(row.porcentaje.replace("%", "")) + 25 :  parseFloat(row.porcentaje.replace("%", ""))} 
                        label={row.costo !== null ? "USD $" + row.costo.toFixed(2) : ""} 
                        style={{height: "35px", width:"400px"}}
                    />
                )
            }
        })
    }
    return data
} 

export const Indicators = () => {
    const [loading, setLoading] = useState(true);

    
    const {form,onChangeField} = useForm({
        fecha_inicio: moment().startOf('month').format('YYYY-MM-DD'),
        fecha_fin: moment().format('YYYY-MM-DD'),
        operador: '0',
        turno: '0',
        cdi: '0',    
    });

    const [totales, setTotales] = useState([]);
    const [donuts, setDonuts] = useState([]);
    const [chart, setChart] = useState([]);
    const [chartBar, setChartBar] = useState([]);
    const [progress, setProgress] = useState([]);
    const {fecha_inicio, fecha_fin, operador, turno, cdi} = form

    useEffect(() => {
        const loadData = async () => {
            try{
                setLoading(true);
                let extraParams = '';
                if (operador !== '0') extraParams = `${extraParams}&operador=${operador}`
                if (turno !== '0') extraParams = `${extraParams}&turno=${turno}`
                if (cdi !== '0') extraParams = `${extraParams}&cdi=${cdi}`
                const response = await apiGetIndicators(moment(fecha_inicio).format('YYYY-MM-DD'), moment(fecha_fin).format('YYYY-MM-DD'), extraParams);
                const allIndicators = response.data.content;
                const {cards} = allIndicators
                setTotales(cards)
                let itemsDonut = []
                let itemsChart = []
                let itemsChartBars = []
                let itemsProgress = []
                for (const prop in allIndicators){

                    if (elementsChartDonuts.includes(prop)) {
                        const element = dataCharDonut(allIndicators[prop].operadores, prop)
                        itemsDonut.push(element);
                    }
                    
                    if(elementChart.includes(prop)){
                        const element = dataChart(allIndicators[prop].operadores, prop)
                        itemsChart.push(element)
                    }

                    if(elementChartBar.includes(prop)){
                        const element = dataChartBar(allIndicators[prop].operadores, prop)
                        itemsChartBars.push(element)
                    }
                     
                    if(elementProgress.includes(prop)){
                        const element = dataProgress(allIndicators[prop].operadores, prop)
                        itemsProgress.push(element)
                    }
                }
                setDonuts(itemsDonut)
                setChart(itemsChart)
                setChartBar(itemsChartBars)
                setProgress(itemsProgress)
            } catch(e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                icon: 'error',
                title: e,
                confirmButtonText: 'Aceptar',
                });
            } finally {
                return setLoading(false);
            }
        }
        loadData();
    }, [fecha_inicio, fecha_fin, operador, turno, cdi])
    
    return (
        <Row>
            <GeneralBox
                title="Indicadores"
                size="12"
            >
                <FormParams form={form} handleChange={onChangeField}/>
            </GeneralBox>
            {
            loading
                ? <Load size={80} />
                : 
                <>
                    <Row>
                        <CardTotales2 totales={totales}/>
                    </Row>
                    <Row>
                        <ChartDonuts data={donuts}/>
                        <ChartApex data={chart}/>
                    </Row>
                    <Row>
                        <ChartBarApex data={chartBar}/>
                    </Row>
                    <Row>
                        <BarProgress data={progress}/>    
                    </Row>
                </>
            }
        </Row>
    )
}