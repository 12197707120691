import { Col } from 'react-bootstrap';
import GeneralBox from '../GeneralBox/generalBox.component';
import ApexChart from '../ApexChart/apexChartComponent';
const colors = ['light'];

export const ChartBarApex = ({ data }) => {

    const getLabels = (element) => {
        return (
            <>
                {
                    element.costo ? <p className="intro">{`Costo: ${element.costo}`}</p> : null
                }
                {
                   element.cm2 ? <p className="intro">{`cm²: ${element.cm2}`}</p> : null
                }
            </>
        );
    };

    return (
        data.map(({ items, title, dataKey }, index) => (
            <Col xs='12' md="12" lg="12">
                <GeneralBox styles={{ textAlign: 'center', alignItems: 'center'}}>
                    <h3 className={`my-0 fw-500 text-${colors[index]}`}>
                        {title}
                    </h3>
                {items.length > 0 ? <ApexChart 
                                        data={items} 
                                        dataKey={dataKey}
                                        activeTooltip={true} 
                                        getLabels={getLabels} 
                                        valueLegend={'razon_movimiento'} 
                                        widthTooltip={100} 
                                        layout="horizontal" 
                                        xAxisProps={{ type: "category", dataKey: "razon_movimiento" }} 
                                    /> 
                : <div>No se encontraron datos</div>}
            </GeneralBox>
        </Col>
        ))
    );
};
