import axios from "axios";

const backendRequest = axios.create({
    // Agregar variable del backend
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

export const headersAuth = headers => {
    if (headers) {
        backendRequest.defaults.headers = {
            ...headers,
            id_sistema: process.env.REACT_APP_DB_ID_SYSTEM
        }
    }
}

export default backendRequest;
