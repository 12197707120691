import { useEffect, useState, useContext } from 'react';
// Navegador
import { useHistory, useParams } from 'react-router-dom';
// Context
import AuthContext from '../context/auth/auth.context';
import SelectItemContext from '../context/selectItem/selectItem.context';
// Componentes
import {
  ElementA,
  ElementB,
  ElementC,
  ElementD,
  ElementE,
  ElementF,
  ElementG,
  ElementH,
  ElementI,
  ElementJ,
  ElementK,
  ElementL,
  ElementM,
  ElementN,
  ElementO,
  ElementP,
  ElementQ,
  ElementR,
  ElementS,
  ElementT,
  ElementU,
  ElementV,
  ElementW,
  ElementX,
  ElementY,
  ElementZ,
  ElementAA,
  ElementAB,
  ElementAC,
  ElementAD,
  ElementAE,
  ElementAF,
  ElementAG,
  ElementAH,
  ElementAI,
  ElementAJ,
  ElementAK,
  ElementAL,
  ElementAM,
  ElementAN,
  ElementAO,
  ElementAP,
  ElementAQ,
  ElementAR,
  HomeElementA,
  HomeElementB,
  HomeElementC,
  HomeElementD,
  HomeElementE
} from '../components/dashboardComponents';
// Solcitud al BE general
import {
  apiGetMenu,
  apiGetComponentsPage,
  apiGetReadTable,
  apiGetManualPage,
  apiGetModaslPage,
} from '../common/api-general';

// ------------------ HOOK PRINCIPAL PARA DASHBOARD --------------------
const useDashboard = (idPage) => {
  const history = useHistory();
  const { extraParam1 } = useParams();
  // Context de autenticacion
  const { getUserAuth, isAuth } = useContext(AuthContext);
  const { onSaveExtraParam, onSaveLastPage } = useContext(SelectItemContext);
  // Componentes globales
  const [globalLoading, setGlobalLoading] = useState(true);
  const [sidebarItems, setSidebarItems] = useState([]);
  // Contenido
  const [pageLoading, setPageLoading] = useState(false);
  const [componentsPage, setComponentsPage] = useState([]);
  const [pageName, setPageName] = useState('');
  const [contentAddButton, setContentAddButton] = useState([]);
  const [contentReportButton, setContentReportButton] = useState([]);
  const [contentManualButton, setContentManualButton] = useState([]);
  const [saverIdPage, setSaverIdPage] = useState("");

  useEffect(() => {
    if (idPage) {
      setSaverIdPage(Number(idPage));
      // Guardar pagina
      onSaveLastPage(idPage);
    }
    // eslint-disable-next-line
  }, [idPage])

  // Cargar contenido de pagina  
  useEffect(() => {
    getUserAuth();
    const loadPage = async () => {
      // Verificar si hay parametros extra
      if (extraParam1) {
        onSaveExtraParam(1, extraParam1);
      }
      // Cargar contenido de la pagina
      try {
        setPageLoading(true);
        const outComponents = [];
        // Pagina de inicio
        if (Number(idPage) === Number(process.env.REACT_APP_PAGE_HOME)) {
          outComponents.push(<HomeElementA key="HE1" />);
          
          outComponents.push(<HomeElementB key="HE3" />);
          outComponents.push(<HomeElementC key="HE4" />);
          outComponents.push(<HomeElementD key='HE5' />);
        }

        // Componentes de una pagina
        const components = await apiGetComponentsPage(idPage);
        const pageInfo = await apiGetReadTable('paginas', 'id_pagina', idPage);
        if (components.data.type === 'success'
          && pageInfo.data.type === 'success') {
          // Dibujar componentes de acuerdo al contenido de pagina
          components.data.content.forEach((element, index) => {
            const { idComponent, acciones = [] } = element;
            switch (idComponent) {
              case 2:
                // Vista almacenes
                outComponents.push(<ElementA key={index} permissions={acciones} />);
                break;
              case 3:
                // Tabla de productos
                outComponents.push(<ElementB key={index} permissions={acciones} />);
                break;
              case 4:
                outComponents.push(<ElementC key={index} permissions={acciones} />);
                // Tabla de Familias
                break;
              case 5:
                // Tabla de Tipos de Producto
                outComponents.push(<ElementD key={index} permissions={acciones} />);
                break;
              case 6:
                // Tabla de Clasificaciones
                outComponents.push(<ElementE key={index} permissions={acciones} />);
                break;
              case 7:
                // DataTable de Proveedores
                outComponents.push(<ElementF key={index} permissions={acciones} />);
                break;
              case 8:
                // Tabla de Productos Cajas
                outComponents.push(<ElementG key={index} permissions={acciones} />);
                break;
              case 9:
                // Tabla de Productos Codigos
                outComponents.push(<ElementH key={index} permissions={acciones} />);
                break;
              case 10:
                // Tabla de Productos Caracatersiticas
                outComponents.push(<ElementI key={index} permissions={acciones} />);
                break;
              case 11:
                // Tabla de Productos Medidas
                outComponents.push(<ElementJ key={index} permissions={acciones} />);
                break;
              case 12:
                // Galeria de Productos Fotos
                outComponents.push(<ElementK key={index} permissions={acciones} />);
                break;
              case 13:
                // Tabla de Productos Documentos
                outComponents.push(<ElementL key={index} permissions={acciones} />);
                break;
              case 14:
                // Tabla de Proveedores simple
                outComponents.push(<ElementM key={index} permissions={acciones} />);
                break;
              case 15:
                // Tabla de Proveedores Costos
                outComponents.push(<ElementAJ key={index} permissions={acciones} />);
                break;
              case 16:
                // Tabla de Listas Precio
                outComponents.push(<ElementN key={index} permissions={acciones} />);
                break;
              case 17:
                // Tabla de Productos de una Lista Precio
                outComponents.push(<ElementO key={index} permissions={acciones} />);
                break;
              case 18:
                // Tabla de Razones Movimiento
                outComponents.push(<ElementP key={index} permissions={acciones} />);
                break;
              case 19:
                // Tabla de Facturas (Prods. Costo)
                outComponents.push(<ElementQ key={index} permissions={acciones} />);
                break;
              case 20:
                // Tabla de Productos de una FacturaProds. Costo)
                outComponents.push(<ElementR key={index} permissions={acciones} />);
                break;
              case 21:
                // Tabla de Productos - Razones Movimiento
                outComponents.push(<ElementS key={index} permissions={acciones} />);
                break;
              case 22:
                // Tabla de Puestos
                outComponents.push(<ElementT key={index} permissions={acciones} />);
                break;
              case 23:
                // Tabla de Puestos
                outComponents.push(<ElementU key={index} permissions={acciones} />);
                break;
              case 24:
                // Tabla de Documentos pendientes (Por recibir)
                outComponents.push(<ElementV key={index} permissions={acciones} />);
                break;
              case 25:
                // Tabla de Productos pendientes (Por recibir)
                outComponents.push(<ElementW key={index} permissions={acciones} />);
                break;
              case 26:
                // Tabla de Productos entrantes
                outComponents.push(<ElementX key={index} permissions={acciones} />);
                break;
              case 27:
                // Tabla Detalles de salidas
                outComponents.push(<ElementY key={index} permissions={acciones} />);
                break;
              case 28:
                // Tabla de registro salidas
                outComponents.push(<ElementZ key={index} permissions={acciones} />);
                break;
              case 29:
                // Tabla de Documentos Salidas
                outComponents.push(<ElementAE key={index} permissions={acciones} />);
                break;
              case 30:
                // Tabla de registro de inventario fisico
                outComponents.push(<ElementAA key={index} permissions={acciones} />);
                break;
              case 31:
                // Tabla de registro de detalle inventario fisico
                outComponents.push(<ElementAB key={index} permissions={acciones} />);
                break;
              case 32:
                // Tabla de usuarios 
                outComponents.push(<ElementAC key={index} permissions={acciones} />);
                break;
              case 33:
                // Tabla de pedaceria 
                outComponents.push(<ElementAD key={index} permissions={acciones} />);
                break;
              case 34:
                // Catalogo de equivalancias 
                outComponents.push(<ElementAF key={index} permissions={acciones} />);
                break;
              case 35:
                // Componente pedimentos / detalles 
                outComponents.push(<ElementAG key={index} permissions={acciones} />);
                break;
              case 36:
                // Tabla de clientes
                outComponents.push(<ElementAH key={index} permissions={acciones} />);
                break;
              case 37:
                // Tabla Detalles Medidas Salidas
                outComponents.push(<ElementAI key={index} permissions={acciones} />);
                break;
              case 38:
                // Tabla Pedaceria
                outComponents.push(<ElementAK key={index} permissions={acciones} />);
                break;
              case 39:
                // Tabla Detalles Pedaceria
                outComponents.push(<ElementAL key={index} permissions={acciones} />);
                break;
              case 40:
                // Tabla Detalles Rack
                outComponents.push(<ElementAM key={index} permissions={acciones} />);
                break;
              case 41:
                // Tabla Detalles Nivel
                outComponents.push(<ElementAN key={index} permissions={acciones} />);
                break;
              case 42:
                // Tabla Pedimentos ERP
                outComponents.push(<ElementAO key={index} permissions={acciones} />);
                break;
              case 43:
                // Tabla Sql
                  outComponents.push(<ElementAP key={index} permissions={acciones} />);
                break;
              case 44:
                // Tabla EndPoints
                  outComponents.push(<ElementAQ key={index} permissions={acciones} />);
                break;
              case 45:
                // Indicadores
                  outComponents.push(<HomeElementE key={index} permissions={acciones} />);
                break;
              case 46:
                // Tabla prepedidos
                  outComponents.push(<ElementAR key={index} permissions={acciones}/>)
                break;
              default:
                outComponents.push(<div key={index} />);
                break;
            }
          });
          // ---------------------------------------------
          // Enviar componentes obtenidos
          setComponentsPage([...outComponents]);
          // Enviar nombre de la pagina
          setPageName(pageInfo.data.content.nombre_pagina);
        } else
          console.log(`Error llamada API de pagina`);
      } catch (e) {
        console.log(`Ocurrio un error al cargar la pagina:\n${e}`);
      } finally {
        setPageLoading(false);
      }
    }
    loadPage();
    return () => {
      setPageName('');
      setComponentsPage([]);
      setContentReportButton([]);
    }
    // eslint-disable-next-line
  }, [idPage, extraParam1, setPageName, setComponentsPage]);
  // Cargar Menus
  useEffect(() => {
    // Solicitar el menu tipo 'sidebar'
    const getContentFromAPI = async () => {
      setGlobalLoading(true);
      try {
        // Solicitar informacion del sidebar
        const reqSideBar = await apiGetMenu(1);
        if (reqSideBar.data.type === 'success') {
          setSidebarItems([...reqSideBar.data.content]);
        } else
          throw new Error(`Error llamada API de dashboard`);
        // Solicitar informacion del manual
        const reqManuals = await apiGetManualPage(idPage);
        if (reqManuals.data.type === 'success') {
          setContentManualButton([{ link: reqManuals.data.content }]);
        } else {
          setContentManualButton([]);
        }
      } catch (e) {
        console.log(`Ocurrio un error al cargar el dashboard:\n${e}`);
      } finally {
        setGlobalLoading(false);
      }
    }
    // Cargar contenido de boton header
    const loadModals = async () => {
      try {
        
        const reqModalAdd = await apiGetModaslPage(idPage, 1);
        const reqModalReport = await apiGetModaslPage(idPage, 2);
        // Modal de agregar
        if (reqModalAdd.data.type === "success") {
          //console.log("pagina actual: ",idPage)
          //console.log("saverIdPage:",saverIdPage)
          //console.log(reqModalAdd.data.content)
          setContentAddButton([...(
            reqModalAdd.data.content.map(e => ({
              idModal: e.id_modal,
              name: e.nombre_modal,
            })))
          ]);
        } else {
          setContentAddButton([]);
        }
        // Modal de Reportes
        if (reqModalReport.data.type === "success") {
          setContentReportButton([...(
            reqModalReport.data.content.map(e => ({
              idModal: e.id_modal,
              name: e.nombre_modal,
            })))
          ]);
        } else {
          setContentReportButton([]);
        }
      } catch (e) {
        console.log(`Ocurrio un error al cargar el dashboard:\n${e}`);
      } finally {
        setGlobalLoading(false);
      }
    }
    // Llamar funciones
    loadModals();
    getContentFromAPI();
    return () => { };
  }, [setSidebarItems, setGlobalLoading, setContentAddButton, saverIdPage, idPage]);
  // Volver al menu principal
  useEffect(() => {
    if (!isAuth) {
      history.push('/');
    }
  }, [isAuth, history])
  return {
    globalLoading,
    sidebarItems,
    pageLoading,
    pageName,
    componentsPage,
    contentAddButton,
    contentReportButton,
    contentManualButton,
    isAuth,
  }
}

export default useDashboard;
