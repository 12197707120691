import { useState, useEffect} from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
// Componentes Generales
import Modals from '../../modal.component';
import { Item } from './Item';
import {useForms} from '../../../../hooks/useForms';
import ToastComponent, { ToastModalContainer } from '../../../Toast/toast.component';
import { launchSwal } from '../../../../utilities/funcs';
import { apiGetStores, apiGetAllTypeMov, apiChangeLevel } from '../../../../common/api-invent';

const Traspasos = ({ show, setShow }) => {

    const {form, onChangeField, onDeleteRow, onAddRow, setForm, resetForm} = useForms({
        store: '0',
        type_movement: '0',
        product: '0',
        rack_origin: '0',
        level_origin: '0',
        caja: '0',
        rack: '0',
        level: '0'
    }) 
      // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });
  
    const [stores, setStores] = useState([]);
    const [typesMovement, setTypesMovement] = useState([]);

    const getStores = async () => {
        try {
            const response = await apiGetStores();
            setStores(response.data.content);
        }catch(e){
            launchSwal('error', 'Error al cargar datos');
        } 
    }

    const getTypesMovement = async () => {
        try {
            const response = await apiGetAllTypeMov();
            setTypesMovement(response.data.content);
        }catch(e){
            launchSwal('error', 'Error al cargar datos');
        } 
    }

    useEffect(() => {
        getStores();
        getTypesMovement();
    }, [])

    // Guardar y cerrar
    const onSave = async () => {
        if(isFormValid()){
            const data = createData()
            try{
                await apiChangeLevel(data);
                launchSwal('success', 'Se han cambiado con exito!')
                onHideModal();
            }catch(e) {
                console.log(e)
                launchSwal('error', 'Error la guardar');
            }
        }else{
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere llenar todos los campos',
                show: true
            });
        }
    }

    const onHideModal = () => {
        resetForm();
        setShow(false)
    }

    const isFormValid = () => {
        const found = form.filter(form => {
            for (const key in form){
                if(form[key] === '0')
                return true;
            }
            return false
        });
        if(found.length > 0) return false
        return true
    }

    const createData = () => {
        const data = form.map(el => {
            return {
                id_existencia: parseInt(el.caja),
                id_almacen_ubicacion: parseInt(el.level)
            }
        })
        return data
    }

    return(
        <>
            <ToastModalContainer
            show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            {!show ? null : 
            
            <Modals
                size="xl"
                show={show}
                hideModal={onHideModal}
                modalTitle="Redistribución"
                buttonTitle="Guardar"
                handleClick={onSave}
            >
            
                <Form>
                    {      
                         form.map((data, index) => {
                            return(
                                <Item
                                    key={index}
                                    rowData = {data}
                                    onChangeField = {onChangeField}
                                    index = {index}
                                    stores = {stores}
                                    typesMovement = {typesMovement}
                                    onDeleteRow = {onDeleteRow}
                                    setForm = {setForm}
                                    form = {form}
                                />
                            )
                        })
                    }
                    <Row>
                        <Col>
                            <Button
                                variant="info"
                                className='waves-effect waves-light'
                                onClick={() => {onAddRow({
                                    store: '0',
                                    type_movement: '0',
                                    product: '0',
                                    rack_origin: '0',
                                    level_origin: '0',
                                    caja: '0',
                                    rack: '0',
                                    level: '0'
                                })}}
                            >
                                <i className='si si-plus' /> Agregar Movimiento
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modals>
            }
        </>
 
    )
}

export default Traspasos;