import { useState, useEffect } from "react";
import moment from "moment";
import {
    Row,
    Col,
    Carousel,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { Dropzone, FileItem } from "@dropzone-ui/react";
// Componentes Generales
import Modals from "../modal.component";
import ToastComponent, { ToastModalContainer } from "../../Toast/toast.component";
import Load from "../../Load/load.component";
// Utilidades
import { formatCurrency } from "../../../utilities/funcs";
// Conexion con api
import {
    apiPostNewPetitionFile,
    apiGetBillsByPetition,
    apiPostNewPetitionDetailDocs,
    apiPostNewPetitionOutgoings,
    apiGetNewPetitionOutgoings,
    apiPostNewPetitionOutBills,
    apiGetNewPetitionOutBulls,
    apiPostNewPetitionCalc,
    apiGetNewPetitionCalc,
    apiPatchNewPetitionConfirm,
} from "../../../common/api-invent";

// (Slide 2) Auxiliar para componente de facturas de proveedor 
const HelperBillsInfo = ({ proveedor, factura, odd }) => {
    const objNotEmpty = factura !== null;

    let referencia = null, total = null, fecha_carga = null, fecha_documento = null;
    if (factura !== null) {
        ({ referencia, total, fecha_carga, fecha_documento } = factura);
    }

    return (
        <Row className="mb-10 p-10" style={{ backgroundColor: odd ? '#0c1a32' : 'transparent' }}>
            <Col xs md="3">Proveedor: {proveedor?.nombre ?? 'S/P'}</Col>
            <Col xs md="2">Total: {objNotEmpty ? formatCurrency(total) : 'S/F'}</Col>
            <Col xs md="2">Referencia: {objNotEmpty ? referencia : 'S/F'} </Col>
            <Col xs md="4">Fecha Documento: {objNotEmpty ? moment(fecha_documento).format("YYYY-MM-DD") : 'S/F'}</Col>
            <Col xs md="3">Fecha Carga: {objNotEmpty ? moment(fecha_carga).format("YYYY-MM-DD") : 'S/F'}</Col>
        </Row>
    )
}
// (Slide 4) Auxiliar para componente de gastos adicionales 
const HelperoExtraOutgoingInfo = ({ subtotal, moneda_codigo_sat, fecha_documento, file, odd }) => {
    return (
        <Row className="mb-10 p-10" style={{ backgroundColor: odd ? '#0c1a32' : 'transparent' }}>
            <Col xs md="4">Archivo: {file?.name}</Col>
            <Col xs md="3">Subtotal: {formatCurrency(subtotal)}</Col>
            <Col xs md="2">Moneda: {moneda_codigo_sat} </Col>
            <Col xs md="3">Fecha Doc: {moment(fecha_documento).format("YYYY-MM-DD")}</Col>
        </Row>
    );
}
// (Slide 4) Auxiliar para componente de cuenta gastos 
const HelperOutgoingInfo = ({ total_gasto_comprobable, referencia, fecha_carga, file }) => {
    return (
        <Row className="mb-10 p-10">
            <Col xs md="4">Archivo: {file?.name}</Col>
            <Col xs md="3">Total Comprobable: {formatCurrency(total_gasto_comprobable)}</Col>
            <Col xs md="2">Referencia: {referencia} </Col>
            <Col xs md="3">Fecha Carga: {moment(fecha_carga).format("YYYY-MM-DD")}</Col>
        </Row>
    );
}
// Slide 5
const HelperOutgoingDetail = ({
    proveedor,
    nombre_prod_proveedor,
    lote,
    precio_caja,
    precio_producto_unitario,
    precio_producto_total,
    gasto_adicional_mxn,
    gasto_caja_mxn,
    gasto_comprobable_mxn,
    gasto_producto_unitario_mxn,
    gasto_igi_mxn,
    gasto_dta_mxn,
    gasto_prv_mxn,
    odd
}) => {

    return (
        <>
            <hr />
            <Col
                xs="12"
                className="p-10"
                style={{ backgroundColor: odd ? '#0c1a32' : 'transparent' }}
            >
                <Row className="mb-10">
                    <Col xs md="3">Proveedor: {proveedor}</Col>
                    <Col xs md="6">Producto: {nombre_prod_proveedor}</Col>
                    <Col xs md="2">Lote: {lote}</Col>
                    <Col xs md="3">Precio Embalaje: {formatCurrency(precio_caja)}</Col>
                    <Col xs md="3">Precio Unitario: {formatCurrency(precio_producto_unitario)}</Col>
                    <Col xs md="3">Precio Total: {formatCurrency(precio_producto_total)}</Col>
                </Row>
                <Row className="mb-20">
                    <Col xs md="3">Gasto IGI: {formatCurrency(gasto_igi_mxn)}</Col>
                    <Col xs md="3">Gasto PRV: {formatCurrency(gasto_prv_mxn)}</Col>
                    <Col xs md="3">Gasto DTA: {formatCurrency(gasto_dta_mxn)}</Col>
                    <Col xs md="4">Gasto Adicional: {formatCurrency(gasto_adicional_mxn)}</Col>
                    <Col xs md="5">Gasto Comprobable: {formatCurrency(gasto_comprobable_mxn)}</Col>
                    <Col xs md="3" />
                    <Col xs md="4">Gasto Embalaje: {formatCurrency(gasto_caja_mxn)}</Col>
                    <Col xs md="5">Gasto Producto Unitario: {formatCurrency(gasto_producto_unitario_mxn)}</Col>
                </Row>
            </Col>
        </>
    )
}

/* =============== COMPONENTE PRINCIPAL =============== */
const AddDocsPetitions = ({ show, setShow }) => {
    // Manejar carga de datos
    const [loading, setLoading] = useState(false);
    const [isSendingReq, setIsSendingReq] = useState(false);
    const [globalData, setGlobalData] = useState({
        idPetition: '0',
    })

    // Hook de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });
    // Hook de formulario
    const [slide1, setSlide1] = useState({
        files: []
    });
    const [slide2, setSlide2] = useState([]);
    const [slide3, setSlide3] = useState({
        files: [],
        info: [],
    });
    const [slide4, setSlide4] = useState({
        files: [],
        info: [],
    });
    const [slide5, setSlide5] = useState([]);
    // Hook de Carrusel
    const [indexCarousel, setIndexCarousel] = useState(0);
    const [lastIndexValidate, setLastIndexValidate] = useState(0);

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // 
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setLoading(false);
        }
    }, [setLoading, show]);


    // Navegacion a slide seleccionada
    const handleSelectSlide = (index) => {
        if (lastIndexValidate >= index) {
            setIndexCarousel(index);
        }
    }

    // Navegación a la siguiente pagina
    const handleNextPage = (e) => {
        if (lastIndexValidate < indexCarousel) {
            setIndexCarousel(indexCarousel + 1)
        } else if (indexCarousel === 0) {
            sendDataSlide1();
        } else if (indexCarousel === 1) {
            sendDataSlide2();
        } else if (indexCarousel === 2) {
            sendDataSlide3();
        } else if (indexCarousel === 3) {
            sendDataSlide4();
        } else if (indexCarousel === 4) {
            sendDataSlide5();
        } else {
            confirmPetition();
        }
    }
    // ---------------------- SLIDE 1 ------------------------
    // Validar Slide 1
    const isValidSlide1 = () => {
        if (slide1.files.length === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere archivo de texto',
                show: true
            });
            return false;
        }
        if (!slide1.files[0]?.valid) {
            setAlert({
                title: 'Formulario erroneo',
                message: 'Tipo de archivo no valido',
                show: true
            });
            return false;
        }
        return true;
    }
    // Enviar información de Slide 1
    const sendDataSlide1 = async () => {
        if (isValidSlide1()) {
            try {
                setIsSendingReq(true);
                const sendData = new FormData();
                // Peticion para agregar archivo
                const sendFiles = slide1.files.map(({ file }) => (file));
                sendData.append('archivo', sendFiles[0]);
                sendData.append('id_pedimento', globalData.idPetition);
                const reqNewPetition = await apiPostNewPetitionFile(sendData);
                if (reqNewPetition.data.type === "success") {
                    setGlobalData(d => ({ ...d, idPetition: reqNewPetition.data.content.id_pedimento }));
                } else {
                    throw new Error("Error al agregar pedimento");
                }
                // Obtener facturas de pedimento
                const reqBillsPetition = await apiGetBillsByPetition(reqNewPetition.data.content.id_pedimento);
                if (reqNewPetition.data.type === "success") {
                    const { data: { content: reqBillsContent } } = reqBillsPetition;
                    setSlide2(reqBillsContent.map(e => ({
                        ...e,
                    })));
                    setIndexCarousel(1)
                    setLastIndexValidate(1)
                } else {
                    throw new Error("Error al agregar pedimento");
                }

            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar pedimento',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setIsSendingReq(false);
            }
        }

    }

    // ---------------------- SLIDE 2 ------------------------
    // Validar Slide 2
    const isValidSlide2 = () => {
        if (globalData.idPetition === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requieren facturas',
                show: true
            });
            return false;
        }
        return true;
    }
    // Enviar información de Slide 2
    const sendDataSlide2 = async () => {
        if (isValidSlide2()) {
            try {
                setIsSendingReq(true);
                const sendData = { id_pedimento: globalData.idPetition };
                const reqNewPetitionDetDocs = await apiPostNewPetitionDetailDocs(sendData);
                if (reqNewPetitionDetDocs.data.type === "success") {
                    setIndexCarousel(2)
                    setLastIndexValidate(2)
                } else {
                    throw new Error("Error al agregar pedimento");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar pedimento',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setIsSendingReq(false);
            }
        }
    }
    // ---------------------- SLIDE 3 ------------------------
    // Validar Slide 3
    const isValidSlide3 = () => {
        if (slide3.files.length === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requieren gastos adicionales',
                show: true
            });
            return false;
        }
        if (slide3.files.reduce((prev, curr) => (prev && curr.valid), true).valid) {
            setAlert({
                title: 'Formulario erroneo',
                message: 'Tipo de archivo no valido',
                show: true
            });
            return false;
        }
        return true;
    }
    // Enviar información de Slide 3
    const sendDataSlide3 = async () => {
        if (isValidSlide3()) {
            try {
                setIsSendingReq(true);
                // Enviar archivos
                const sendData = new FormData();
                slide3.files.forEach(({ file }) => {
                    sendData.append("archivos", file);
                });
                const reqNewPetitionOutogoings = await apiPostNewPetitionOutgoings(globalData.idPetition, sendData);
                if (reqNewPetitionOutogoings.data.type === "success") {
                    // Obtener informacion de gastos
                    const reqGetPetitionOutgoings = await apiGetNewPetitionOutgoings(globalData.idPetition);
                    setSlide3(d => ({
                        ...d,
                        info: [...reqGetPetitionOutgoings.data.content]
                    }));
                    setIndexCarousel(3)
                    setLastIndexValidate(3)
                } else {
                    throw new Error("Error al agregar pedimento");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar pedimento',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setIsSendingReq(false);
            }
        }
    }

    // ---------------------- SLIDE 4 ------------------------
    // Validar Slide 4
    const isValidSlide4 = () => {

        if (slide4.files.length === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere archivo de gastos',
                show: true
            });
            return false;
        }
        if (!slide4.files[0]?.valid) {
            setAlert({
                title: 'Formulario erroneo',
                message: 'Tipo de archivo no valido',
                show: true
            });
            return false;
        }
        return true;
    }
    // Enviar información de Slide 4
    const sendDataSlide4 = async () => {
        if (isValidSlide4()) {
            try {
                setIsSendingReq(true);
                // Peticion para agregar archivo
                const sendFiles = slide4.files.map(({ file }) => (file));
                const sendData = new FormData();
                sendData.append('archivo', sendFiles[0]);
                const reqNewPetitionOutBills = await apiPostNewPetitionOutBills(globalData.idPetition, sendData);
                if (reqNewPetitionOutBills.data.type === "success") {
                    // Obtener informacion 
                    const reqGetPetitionOutBulls = await apiGetNewPetitionOutBulls(globalData.idPetition);
                    setSlide4(d => ({
                        ...d,
                        info: { ...reqGetPetitionOutBulls.data.content }
                    }));
                    setIndexCarousel(4)
                    setLastIndexValidate(4)
                } else {
                    throw new Error("Error al agregar pedimento");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar pedimento',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setIsSendingReq(false);
            }
        }
    }
    // ---------------------- SLIDE 5 ------------------------
    const sendDataSlide5 = async () => {
        try {
            setIsSendingReq(true);
            // Peticion para generar calculos
            const reqGenerateCalc = await apiPostNewPetitionCalc(globalData.idPetition);
            if (reqGenerateCalc.data.type === "success") {
                // Obtener informacion de calculo
                const reqGetCalc = await apiGetNewPetitionCalc(globalData.idPetition);
                setSlide5([...reqGetCalc.data.content]);
                setIndexCarousel(5)
                setLastIndexValidate(5)
            } else {
                throw new Error("Error al agregar pedimento");
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error al agregar pedimento',
                text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                confirmButtonText: 'Aceptar',
            });
        } finally {
            setIsSendingReq(false);
        }
    }

    // ------------------ CONFIRMAR PEDIMENTO -------------------
    const confirmPetition = async () => {
        try {
            setIsSendingReq(true);
            // Peticion para generar calculos
            const reqPetitionConfirm = await apiPatchNewPetitionConfirm(globalData.idPetition);
            if (reqPetitionConfirm.data.type === "success") {
                // Limpiar datos
                setSlide1({
                    files: []
                });
                setSlide2([]);
                setSlide3({
                    files: [],
                    info: [],
                });
                setSlide4({
                    files: [],
                    info: [],
                });
                // Mostrar alerta de resultado
                await Swal.fire({
                    icon: 'success',
                    title: 'Pedimento aplicado exitosamente',
                    confirmButtonText: 'Aceptar',
                });
                setShow(false);
                // Mover carrusel
                setIndexCarousel(0)
                setLastIndexValidate(0)
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error al agregar pedimento',
                text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                confirmButtonText: 'Aceptar',
            });
        } finally {
            setIsSendingReq(false);
        }
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Agregar Pedimento"
                buttonTitle={indexCarousel !== 5 ? 'Siguiente' : 'Confirmar Pedimento'}
                handleClick={handleNextPage}
                size="lg"
                isButtonLoading={isSendingReq}
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Carousel
                            controls={false}
                            activeIndex={indexCarousel}
                            onSelect={handleSelectSlide}
                            className="mt-20 pb-40 big-indicators"
                            interval={null}
                        >
                            <Carousel.Item>
                                <Row>
                                    <Col xs="12" className="text-center mb-10">
                                        <p className="badge badge-pill badge-primary text-center pe-15" style={{ fontSize: "1.5rem" }}>1</p>
                                    </Col>
                                    <Col xs="12">
                                        <div className="form-group">
                                            <label htmlFor="archivo" className="form-label">
                                                <h4>Agrega tu archivo de texto (*.txt)</h4>
                                            </label>
                                            <Dropzone
                                                accept="text/plain"
                                                onChange={(files) => {
                                                    setSlide1(f => ({
                                                        ...f,
                                                        files: files
                                                    }));
                                                }}
                                                value={slide1.files}
                                                footer={false}
                                                localization="ES-es"
                                                maxFiles={1}
                                                maxHeight="200px"
                                            >
                                                {slide1.files.map((file, index) => (
                                                    <FileItem
                                                        {...file}
                                                        localization="ES-es"
                                                        key={index}
                                                        preview
                                                    />
                                                ))}
                                            </Dropzone>
                                        </div>
                                    </Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item className="pb-40">
                                <Row>
                                    <Col xs="12" className="text-center mb-10">
                                        <p className="badge badge-pill badge-primary text-center pe-15" style={{ fontSize: "1.5rem" }}>2</p>
                                    </Col>
                                    <Col xs="12" className="">
                                        <h4>Verifica las facturas Seleccionadas</h4>
                                        <hr />
                                        {
                                            slide2.map((e, i) => (
                                                <HelperBillsInfo
                                                    {...e}
                                                    key={i}
                                                    odd={i % 2 === 0}
                                                />
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    <Col xs="12" className="text-center mb-10">
                                        <p className="badge badge-pill badge-primary text-center pe-15" style={{ fontSize: "1.5rem" }}>
                                            3
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <div className="form-group">
                                            <label htmlFor="archivo" className="form-label">
                                                <h4>Gastos Adicionales (*.xml)</h4>
                                            </label>
                                            <Dropzone
                                                accept="text/xml"
                                                onChange={(files) => {
                                                    setSlide3(f => ({
                                                        ...f,
                                                        files: files
                                                    }));
                                                }}
                                                value={slide3.files}
                                                footer={false}
                                                localization="ES-es"
                                                maxHeight="200px"

                                            >
                                                {slide3.files.map((file, index) => (
                                                    <FileItem
                                                        {...file}
                                                        localization="ES-es"
                                                        key={index}
                                                        preview
                                                    />
                                                ))}
                                            </Dropzone>
                                        </div>
                                    </Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    <Col xs="12" className="text-center mb-10">
                                        <p className="badge badge-pill badge-primary text-center pe-15" style={{ fontSize: "1.5rem" }}>
                                            4
                                        </p>
                                    </Col>
                                    <Col xs="12">
                                        <div className="form-group">
                                            <label htmlFor="archivo" className="form-label">
                                                <h4>Cuenta de gastos (*.pdf)</h4>
                                            </label>
                                            <Dropzone
                                                accept="application/pdf"
                                                onChange={(files) => {
                                                    setSlide4(f => ({
                                                        ...f,
                                                        files: files
                                                    }));
                                                }}
                                                value={slide4.files}
                                                footer={false}
                                                maxFiles={1}
                                                localization="ES-es"
                                                maxHeight="200px"
                                            >
                                                {slide4.files.map((file, index) => (
                                                    <FileItem
                                                        {...file}
                                                        localization="ES-es"
                                                        key={index}
                                                        preview
                                                    />
                                                ))}
                                            </Dropzone>
                                        </div>
                                    </Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item className="mb-20">
                                <Row>
                                    <Col xs="12" className="text-center mb-10">
                                        <p className="badge badge-pill badge-primary text-center pe-15" style={{ fontSize: "1.5rem" }}>
                                            5
                                        </p>
                                        <h2>VERIFICA LOS DOCUMENTOS</h2>
                                    </Col>
                                    <Col xs="12">
                                        <hr />
                                        <h3 className="mb-15">Facturas</h3>
                                        {
                                            slide2.map((e, i) => (
                                                <HelperBillsInfo
                                                    {...e}
                                                    key={i}
                                                    odd={i % 2 === 0}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs="12">
                                        <hr />
                                        <h3 className="mb-15">Gastos Adicionales</h3>
                                        {
                                            slide3.files.map((e, i) => (
                                                <HelperoExtraOutgoingInfo
                                                    {...slide3.info[i]}
                                                    {...e}
                                                    key={i}
                                                    odd={i % 2 === 0}
                                                />
                                            ))
                                        }
                                    </Col>
                                    <Col xs="12">
                                        <hr />
                                        <h3 className="mb-15">Cuenta de Gastos</h3>
                                        <HelperOutgoingInfo
                                            {...slide4.info}
                                            {...slide4.files[0]}
                                        />
                                    </Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Row>
                                    <Col xs="12" className="text-center mb-10">
                                        <p className="badge badge-pill badge-primary text-center pe-15" style={{ fontSize: "1.5rem" }}>
                                            6
                                        </p>
                                        <h2>VERIFICA LOS GASTOS</h2>
                                    </Col>
                                    <Col xs="12">
                                        {
                                            slide5.map((e, i) => (
                                                <HelperOutgoingDetail
                                                    {...e}
                                                    key={i}
                                                    odd={i % 2 === 0}
                                                />
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </Carousel.Item>
                        </Carousel>
                }
            </Modals>
        </>
    )
}

export default AddDocsPetitions;