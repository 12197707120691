import { selectItem_types } from './selectItem.context';

const SelectItemReducer = (state, action) => {
    switch (action.type) {
        // Ultima pagina visitada
        case selectItem_types.NAVIGATE_PAGE:
            localStorage.setItem('last_visited_page', action.payload.id);
            return {
                ...state,
                lastVisitedPage: Number(action.payload.id)
            }
        // Guardar parametros extra de la URL
        case selectItem_types.ADD_EXTRA_PARAM:
            const extraParams = JSON.parse(localStorage.getItem('extra_url_params')) ?? {};
            extraParams[action.payload.number] = Number(action.payload.value);
            localStorage.setItem('extra_url_params', JSON.stringify(extraParams));
            return {
                ...state,
                extraParams, 
            }
        // Remover parametro extra de url
        case selectItem_types.REMOVE_EXTRA_PARAM:
            const auxParams = {...state.extraParams};
            delete auxParams[action.payload.number];
            localStorage.setItem('extra_url_params', JSON.stringify(auxParams));
            return {
                ...state,
                extraParams: auxParams, 
            }
        // Seleccionar Producto
        case selectItem_types.SELECT_PRODUCT:
            return {
                ...state,
                selectedProduct: Number(action.payload.id)
            }
        // Seleccionar Almacen
        case selectItem_types.SELECT_STORE:
            return {
                ...state,
                selectedStore: Number(action.payload.id)
            }
        // Seleccionar Rack
        case selectItem_types.SELECT_RACK:
            return {
                ...state,
                selectedRack: Number(action.payload.id)
            }
        // Seleccionar Nivel
        case selectItem_types.SELECT_LEVEL:
            return {
                ...state,
                selectedLevel: Number(action.payload.id)
            }
        // Seleccionar Proveedor
        case selectItem_types.SELECT_PROVIDER:
            return {
                ...state,
                selectedProvider: Number(action.payload.id)
            }
        // Seleccionar Proveedor
        case selectItem_types.SELECT_PROVIDERCOSTS:
            return {
                ...state,
                selectedProviderCosts: Number(action.payload.id)
            }
        // Seleccionar Lista Precio
        case selectItem_types.SELECT_LISTPRICES:
            return {
                ...state,
                selectedListPrices: Number(action.payload.id)
            }
        // Seleccionar Factura de Productos
        case selectItem_types.SELECT_BILLPRODS:
            return {
                ...state,
                selectedBillProds: Number(action.payload.id)
            }
        // Seleccionar Factura Pendiente (por recibir)
        case selectItem_types.SELECT_PROCESSSINGBILL:
            return {
                ...state,
                selectedProcessingBill: Number(action.payload.id)
            }
        // Seleccionar Factura Pendiente (por recibir)
        case selectItem_types.SELECT_PHYSICALINVENT:
            return {
                ...state,
                selectedPhysicalInvent: Number(action.payload.id)
            }
        // Seleccionar Salida
        case selectItem_types.SELECT_EXIT:
            return {
                ...state,
                selectedExit: Number(action.payload.id)
            }
        // Seleccionar Detalles de Salida
        case selectItem_types.SELECT_EXITPLATE:
            return {
                ...state,
                selectedExitPlate: Number(action.payload.id)
            }
        // Seleccionar Medidas de Salida
        case selectItem_types.SELECT_EXITDETAILS:
            return {
                ...state,
                selectedExitDetails: Number(action.payload.id)
            }
        // Seleccionar Pedaceria
        case selectItem_types.SELECT_SCRAP:
            return {
                ...state,
                selectedScrap: Number(action.payload.id)
            }
        // Seleccionar Detalles de Pedaceria
        case selectItem_types.SELECT_SCRAPDETAILS:
            return {
                ...state,
                selectedScrapDetails: Number(action.payload.id)
            }
        default:
            return (state);
    }
}
export default SelectItemReducer;