import { createContext } from "react";

// Definir types
export const auth_types = Object.freeze({
    REGISTER_SUCCESS:   Symbol("REGISTER_SUCCESS"),
    REGISTER_ERROR:     Symbol("REGISTER_ERROR"),
    LOGIN_SUCCESS:      Symbol("LOGIN_SUCCESS"),
    LOGIN_ERROR:        Symbol("LOGIN_ERROR"),
    LOGOUT:             Symbol("LOGOUT"),
    GET_USER:           Symbol("GET_USER"),
});

// Crear contexto
const AuthContext = createContext();
export default AuthContext;