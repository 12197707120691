import { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import Swal from "sweetalert2";
// Componentes
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
    apiPatchDependency,
    apiGetDependcyId
} from '../../../common/api-invent';
import { useTranslation } from "react-i18next";
const EditDependencyModal = ({ show, setShow, mainTitle, nameDependency, id, idReloadComponent }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const {t}=useTranslation();
    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });
    // Hook para formulario
    const [data, setData] = useState({
        nombre: '',
        descripcion: '',
    });

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                const reqDep = await apiGetDependcyId(id, nameDependency);
                setData({ ...reqDep.data.content });
            } catch (e) {
                console.error(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData();
        // Funcion limpieza
        return () => {
            setLoading(false);
        }
    }, [setData, setLoading, id, nameDependency, show]);
    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre',
                show: true
            });
            return false;
        }
        return true;
    }

    // Funciones por el usuario
    const handleSubmit = async event => {
        event.preventDefault();

        if (isFormValid()) {
            try {
                const response = await apiPatchDependency(id, data, nameDependency);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu información ha sido actualizada exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recargar componente
                    onReloadComponent(idReloadComponent)
                } else {
                    throw new Error("Error al actualizar dependencia");
                }
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data?.msg ?? 'Error al actualizar dependencia',
                        text: 'Intentelo nuevamente',
                        confirmButtonText: 'Aceptar',
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al conectar con los servicios',
                        confirmButtonText: 'Aceptar',
                    });
                }
            }
        }
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={mainTitle}
                buttonTitle="Guardar"
                handleClick={handleSubmit}
                size="md"
            >
                {loading
                    ? <Load
                        size={100}
                        marginBottom="0px"
                        marginTop="0px"
                    />
                    : <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md="4" xs="12">
                                <div className="form-group">
                                    <label htmlFor="nombre" className="form-label">
                                        {t('nombre')} : *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="nombre"
                                        name="nombre"
                                        value={data.nombre}
                                        onChange={handleFormChange}
                                        placeholder={`Tipo ${nameDependency} 1`}
                                    />
                                </div>
                            </Col>
                            <Col md="8" xs="12">
                                <div className="form-group">
                                    <label htmlFor="descripcion" className="form-label">
                                    {t('descripcion')} :
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="descripcion"
                                        name="descripcion"
                                        value={data.descripcion}
                                        onChange={handleFormChange}
                                        placeholder={t('descripcion')+` ${nameDependency} 1`}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                }
            </Modals>
        </>
    );
}
// Documentacion Proptypes
EditDependencyModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    mainTitle: PropTypes.string.isRequired,
    nameDependency: PropTypes.string.isRequired,
    idReloadComponent: PropTypes.number.isRequired,
}
export default EditDependencyModal;