 export const SmallDetailBox = ({
    title,
    text,
    color,
    icon,
    fondo = '#0c1a32',
    styles
  }) => {

    const classColor = color ? `text-${color}` : ''
    return (
      <div className="box" style={{backgroundColor: `${fondo}`, height: '90%'}}>
        <div className="box-body">
          <div className="d-flex justify-content-between">
            <h2 style={styles} className={`my-0 fw-600 ${classColor}`}>
              {title}
            </h2>
            {
              icon ? 
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className={`w-40 h-40 bg-${color} rounded-circle text-center fs-24 l-h-40`}
                >
                  <i
                    style={{ width: "20px" }}
                    className={icon}
                  />
                </div> : null
            }
          </div>
          <div>
            {text}
          </div>
        </div>
      </div >
    )
  }