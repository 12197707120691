import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from "../modal.component";
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
import HorizontalTabs from '../../HorizontalTabs/horizontalTabs.component';
import CustomSelect from '../../Select/select.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Api
import {
    apiGetAllStatus,
    apiGetDependcy,
    apiGetAllProviders,
    apiGetAllCountries,
    apiPostNewProduct,
    apiGetAllProductsERP
} from '../../../common/api-invent';
import { useTranslation } from 'react-i18next';
const AddProductModal = ({ show, setShow }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const {t} = useTranslation();
    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [families, setFamilies] = useState([]);
    const [types, setTypes] = useState([]);
    const [clasifs, setClasifs] = useState([]);
    const [status, setStatus] = useState([]);
    const [providers, setProviders] = useState([]);
    const [countries, setCountries] = useState([]);
    const [erps, setErps] = useState([]);

    const [product, setProduct] = useState({
        nombre: '',
        descripcion: '',
        id_proveedor: '0',
        nombre_prod_proveedor: '',
        codigo_proveedor: '',
        codigo_qr: '',
        cantidad_minima: '',
        cantidad_maxima: '',
        id_estatus: '0',
        id_familia: '0',
        id_tipo_producto: '0',
        id_clasificacion: '0',
        nivel_riesgo: '',
        // Fiscales
        clave_producto_sat: '',
        clave_unidad_sat: '',
        cuenta_contable: '',
        // Otros
        id_pais: '0',
        observacion: '',
        // ERP
        erp_producto: '',
    });

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });
    // Cargar datos
    useEffect(() => {
        const loadProduct = async () => {
            setLoading(true);
            try {
                // Informacion de Clasificacion
                const reqCla = await apiGetDependcy('clasificacion');
                setClasifs([...reqCla.data.content]);
                // Informacion de Tipos de producto
                const reqTyp = await apiGetDependcy('tipo_producto');
                setTypes([...reqTyp.data.content]);
                // Informacion de familias
                const reqFam = await apiGetDependcy('familia');
                setFamilies([...reqFam.data.content]);
                // Informacion de status
                const reqSta = await apiGetAllStatus();
                setStatus([...reqSta.data.content]);
                // Informacion de proveedores
                const reqPro = await apiGetAllProviders(1);
                setProviders([...reqPro.data.content]);
                // Informacion de paises
                const reqCou = await apiGetAllCountries();
                setCountries([...reqCou.data.content]);
                // Informacion de ERPS
                const reqErps = await apiGetAllProductsERP();
                setErps([...reqErps.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadProduct();
        return () => { }
    }, [setLoading, show]);

    // Escribir nuevos datos
    const handleFormChange = (e) => {
        setProduct({
            ...product,
            [e.target.name]: e.target.value
        });
    }

    // Funcion para valida formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (product.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre del producto',
                show: true,
            });
            return false;
        }
        if (product.id_estatus === "0") {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere estatus',
                show: true,
            });
            return false;
        }
        if (product.id_proveedor === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere proveedor',
                show: true,
            });
            return false;
        }
        if (product.nombre_prod_proveedor === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre del proveedor',
                show: true,
            });
            return false;
        }
        if (product.codigo_proveedor === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere codigo del proveedor',
                show: true,
            });
            return false;
        }
        if (product.codigo_qr === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere codigo QR',
                show: true,
            });
            return false;
        }
        if (product.cantidad_minima === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere cantidad mínima',
                show: true,
            });
            return false;
        }
        if (product.cantidad_maxima === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere cantidad máxima',
                show: true,
            });
            return false;
        }
        if (product.nivel_riesgo === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nivel de riesgo',
                show: true,
            });
            return false;
        }
        if (product.id_familia === "0") {
            setAlert({
                title: 'Formulario incomplento',
                message: 'Se requiere familia',
                show: true,
            });
            return false;
        }
        if (product.id_tipo_producto === "0") {
            setAlert({
                title: 'Formulario incomplento',
                message: 'Se requiere tipo de producto',
                show: true,
            });
            return false;
        }
        if (product.id_clasificacion === "0") {
            setAlert({
                title: 'Formulario incomplento',
                message: 'Se requiere clasificación',
                show: true,
            });
            return false;
        }
        return true;
    }

    // Envio de formulario
    const _handleSubmit = async (closeModal = true) => {
        if (isFormValid()) {
            try {
                const response = await apiPostNewProduct(product);
                if (response.data.type === "success") {
                    await Swal.fire({
                        icon: 'success',
                        title: "El producto ha sido guardado",
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(8);
                    // Limpiar datos
                    setProduct({
                        nombre: '',
                        descripcion: '',
                        id_proveedor: '0',
                        nombre_prod_proveedor: '',
                        codigo_proveedor: '',
                        codigo_qr: '',
                        cantidad_minima: '',
                        cantidad_maxima: '',
                        id_estatus: '0',
                        id_familia: '0',
                        id_tipo_producto: '0',
                        id_clasificacion: '0',
                        nivel_riesgo: '',
                        // Fiscales
                        clave_producto_sat: '',
                        clave_unidad_sat: '',
                        cuenta_contable: '',
                        // Otros
                        id_pais: '0',
                        observacion: '',
                        // ERP
                        erp_producto: '',
                    });
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar producto");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar producto',
                    text: error?.response?.data?.msg ?? 'Revise los campos e intentelo de nuevo',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(true);
    }

    // Guardar y limpiar
    const handleSave = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(false);
    }

    // Componente
    return (
        <>
            <ToastModalContainer
                show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={t('agregar')+" "+t('producto')}
                buttonTitle={t('agregar_y_cerrar')}
                handleClick={handleSaveClose}
                auxButtonTitle={t('agregar_y_guardar')}
                handleAuxButton={handleSave}
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="50px"
                            marginTop="50px"
                        />
                        : <Form>
                            <HorizontalTabs
                                tabsTitles={[
                                    { content: 'General' },
                                    { content: 'Fiscales' },
                                    { content: 'Otros' },
                                    { content: 'ERP' },
                                ]}
                                tabsContent={[
                                    // General
                                    <>
                                        <Row>
                                            <Col xs="12" md="4">
                                                <div className="form-group">
                                                    <label htmlFor="nombre" className="form-label">
                                                        {t('nombre')}: *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="nombre"
                                                        name="nombre"
                                                        value={product.nombre}
                                                        onChange={handleFormChange}
                                                        placeholder="Producto 1"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="6" md="3">
                                                <div className="form-group">
                                                    <label htmlFor="id_estatus" className="form-label">
                                                        {t('estatus')}: *
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        name="id_estatus"
                                                        id="id_estatus"
                                                        value={product.id_estatus}
                                                        onChange={handleFormChange}
                                                    >
                                                        <option value="0">{t('seleccione')+" "+t('estatus')} </option>
                                                        {
                                                            status.map(({ glb_id_estatus, glb_nombre }, index) => (
                                                                <option value={glb_id_estatus} key={index}>
                                                                    {glb_nombre}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col xs="6" md="5">
                                                <div className="form-group">
                                                    <label htmlFor="descripcion" className="form-label">
                                                        {t('descripcion')}:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="descripcion"
                                                        name="descripcion"
                                                        value={product.descripcion}
                                                        onChange={handleFormChange}
                                                        placeholder="Producto para venta"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col xs="6" md="4">
                                                <div className="form-group">
                                                    <label htmlFor="id_proveedor" className="form-label">
                                                        {t('proveedor')}: *
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        name="id_proveedor"
                                                        id="id_proveedor"
                                                        value={product.id_proveedor}
                                                        onChange={handleFormChange}
                                                    >
                                                        <option value="0">{t('seleccione')+" "+t('proveedor')}</option>
                                                        {
                                                            providers.map(({ id_proveedor, nombre }, index) => (
                                                                <option value={id_proveedor} key={index}>
                                                                    {nombre}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col xs="6" md="4">
                                                <div className="form-group">
                                                    <label htmlFor="nombre_prod_proveedor" className="form-label">
                                                        {t('nombre')+" "+t('producto')+" "+t('proveedor')}: *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="nombre_prod_proveedor"
                                                        name="nombre_prod_proveedor"
                                                        value={product.nombre_prod_proveedor}
                                                        onChange={handleFormChange}
                                                        placeholder="Producto Venta 1"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="6" md="4">
                                                <div className="form-group">
                                                    <label htmlFor="codigo_proveedor" className="form-label">
                                                        {t('codigo')} {t('proveedor')}: *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="codigo_proveedor"
                                                        name="codigo_proveedor"
                                                        value={product.codigo_proveedor}
                                                        onChange={handleFormChange}
                                                        placeholder="PRD1-00001-A"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="6" md="3">
                                                <div className="form-group">
                                                    <label htmlFor="codigo_qr" className="form-label">
                                                        {t('codigo')} QR: *
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="codigo_qr"
                                                        name="codigo_qr"
                                                        value={product.codigo_qr}
                                                        onChange={handleFormChange}
                                                        placeholder="7215000001"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="6" md="3">
                                                <div className="form-group">
                                                    <label htmlFor="cantidad_minima" className="form-label">
                                                        {t('cantidad')} {t('minima')}: *
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="cantidad_minima"
                                                        name="cantidad_minima"
                                                        value={product.cantidad_minima}
                                                        onChange={handleFormChange}
                                                        placeholder="10"
                                                        min="1"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="6" md="3">
                                                <div className="form-group">
                                                    <label htmlFor="cantidad_maxima" className="form-label">
                                                        {t('cantidad')} {t('maxima')}: *
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="cantidad_maxima"
                                                        name="cantidad_maxima"
                                                        value={product.cantidad_maxima}
                                                        onChange={handleFormChange}
                                                        placeholder="10000"
                                                        min="1"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="6" md="3">
                                                <div className="form-group">
                                                    <label htmlFor="nivel_riesgo" className="form-label">
                                                        {t('nivel')} {t('riesgo')}: *
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="nivel_riesgo"
                                                        name="nivel_riesgo"
                                                        value={product.nivel_riesgo}
                                                        onChange={handleFormChange}
                                                        placeholder="1 - 5"
                                                        min="1"
                                                        max="5"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col xs="6" md="4">
                                                <div className="form-group">
                                                    <label htmlFor="id_familia" className="form-label">Familia: *</label>
                                                    <select
                                                        className="form-select"
                                                        name="id_familia"
                                                        id="id_familia"
                                                        value={product.id_familia}
                                                        onChange={handleFormChange}
                                                    >
                                                        <option value="0">{t('seleccione')}  {t('familia')}</option>
                                                        {
                                                            families.map(({ glb_id_familia, glb_nombre }, index) => (
                                                                <option value={glb_id_familia} key={index}>
                                                                    {glb_nombre}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col xs="6" md="4">
                                                <div className="form-group">
                                                    <label htmlFor="id_tipo_producto" className="form-label">
                                                        {t('tipo_producto')}: *
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        name="id_tipo_producto"
                                                        id="id_tipo_producto"
                                                        value={product.id_tipo_producto}
                                                        onChange={handleFormChange}
                                                    >
                                                        <option value="0">{t('seleccione')} {t('tipo_producto')}</option>
                                                        {
                                                            types.map(({ glb_id_tipo_producto, glb_nombre }, index) => (
                                                                <option value={glb_id_tipo_producto} key={index}>
                                                                    {glb_nombre}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col xs="6" md="4">
                                                <div className="form-group">
                                                    <label htmlFor="id_clasificacion" className="form-label">
                                                        {t('clasificacion')}: *
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        name="id_clasificacion"
                                                        id="id_clasificacion"
                                                        value={product.id_clasificacion}
                                                        onChange={handleFormChange}
                                                    >
                                                        <option value="0">{t('seleccione')} {t('clasificacion')}</option>
                                                        {
                                                            clasifs.map(({ glb_id_clasificacion, glb_nombre }, index) => (
                                                                <option value={glb_id_clasificacion} key={index}>
                                                                    {glb_nombre}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>,
                                    // Fiscales
                                    <>
                                        <Row>
                                            <Col xs="12" md="6">
                                                <div className="form-group">
                                                    <label htmlFor="clave_producto_sat" className="form-label">
                                                        {t('clave')} {t('producto')} SAT:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="clave_producto_sat"
                                                        name="clave_producto_sat"
                                                        value={product.clave_producto_sat}
                                                        onChange={handleFormChange}
                                                        placeholder="XXXXXX"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6">
                                                <div className="form-group">
                                                    <label htmlFor="clave_unidad_sat" className="form-label">
                                                        {t('clave')} Unidad SAT:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="clave_unidad_sat"
                                                        name="clave_unidad_sat"
                                                        value={product.clave_unidad_sat}
                                                        onChange={handleFormChange}
                                                        placeholder="H87"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6">
                                                <div className="form-group">
                                                    <label htmlFor="cuenta_contable" className="form-label">
                                                        {t('cuenta')} {t('contable')}:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="cuenta_contable"
                                                        name="cuenta_contable"
                                                        value={product.cuenta_contable}
                                                        onChange={handleFormChange}
                                                        placeholder="XXX.XX.XX"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>,
                                    // Otros
                                    <>
                                        <Row>
                                            <Col xs="12" md="6">
                                                <div className="form-group">
                                                    <label htmlFor="id_pais" className="form-label">
                                                        {t('pais')} {t('origen')}:
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        name="id_pais"
                                                        id="id_pais"
                                                        value={product.id_pais}
                                                        onChange={handleFormChange}
                                                    >
                                                        <option value="0">{t('seleccione')} {t('pais')}</option>
                                                        {
                                                            countries.map(({ glb_id_pais, glb_nombre }, index) => (
                                                                <option value={glb_id_pais} key={index}>
                                                                    {glb_nombre}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6">
                                                <div className="form-group">
                                                    <label htmlFor="observacion" className="form-label">
                                                        {t('observacion')}:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="observacion"
                                                        name="observacion"
                                                        value={product.observacion}
                                                        onChange={handleFormChange}
                                                        placeholder="Quis quae placeat laborum a."
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>,
                                    // ERP
                                    <>
                                        <Row>
                                            <Col xs="12">
                                                <div className="form-group">
                                                    <label htmlFor="erp_producto">
                                                        ERP:
                                                    </label>
                                                    <CustomSelect
                                                        name="erp_producto"
                                                        id="erp_producto"
                                                        value={product.erp_producto}
                                                        onChange={handleFormChange}
                                                        options={erps.map(erp => ({ value: erp.nombre, label: erp.nombre}))}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                ]}
                            />
                        </Form>
                }
            </Modals>
        </>
    )
}

export default AddProductModal;