import PropTypes from 'prop-types';
// Componentes Bootstrap
import { Col } from 'react-bootstrap';

const GeneralBox = ({ title, children, size, styles }) => {
    return (
        <Col md={size}>
            <div className="box" style={styles}>
                {title && (
                    <div className="box-header">
                        <h3 className="box-title">
                            {title}
                        </h3>
                    </div>
                )}
                <div className="box-body">
                    {children}
                </div>
            </div>
        </Col>
    )
}

// Documentacio PropTypes
GeneralBox.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element.isRequired,
    size: PropTypes.string
}
GeneralBox.defaultProps = {
    size: '12'
}

export default GeneralBox;