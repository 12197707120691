import { createContext } from "react";

// Definir tipos
export const selectItem_types = Object.freeze({
    NAVIGATE_PAGE:             Symbol("NAVIGATE_PAGE"),
    ADD_EXTRA_PARAM:           Symbol("ADD_EXTRA_PARAM"),
    REMOVE_EXTRA_PARAM:        Symbol("REMOVE_EXTRA_PARAM"),
    SELECT_PRODUCT:            Symbol("SELECT_PRODUCT"),
    SELECT_STORE:              Symbol("SELECT_STORE"),
    SELECT_RACK:               Symbol("SELECT_RACK"),
    SELECT_LEVEL:              Symbol("SELECT_LEVEL"),
    SELECT_PROVIDER:           Symbol("SELECT_PROVIDER"),
    SELECT_PROVIDERCOSTS:      Symbol("SELECT_PROVIDERCOSTS"),
    SELECT_LISTPRICES:         Symbol("SELECT_LISTPRICES"),
    SELECT_BILLPRODS:          Symbol("SELECT_BILLPRODS"),
    SELECT_PROCESSSINGBILL:    Symbol("SELECT_PROCESSSINGBILL"),
    SELECT_PHYSICALINVENT:     Symbol("SELECT_PHYSICALINVENT"),
    SELECT_SELECT_EXIT:        Symbol("SELECT_SELECT_EXIT"),
    SELECT_EXITPLATE:          Symbol("SELECT_EXITPLATE"),
    SELECT_EXITDETAILS:        Symbol("SELECT_EXITDETAILS"),
    SELECT_SCRAP:              Symbol("SELECT_SCRAP"),
    SELECT_SCRAPDETAILS:       Symbol("SELECT_SCRAPDETAILS"),
});

// Crear contexto
const SelectItemContext = createContext();
export default SelectItemContext;