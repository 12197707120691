import { useState, useContext, useEffect } from "react";
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from "sweetalert2";
import { Dropzone, FileItem } from "@dropzone-ui/react";
// Componentes Generales
import Modals from '../modal.component';
import { OverlayTooltip } from '../../common.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import SelectItemContext from '../../../context/selectItem/selectItem.context';
import ReloadContext from "../../../context/reload/reload.context";
import { useTranslation } from "react-i18next";
// Conexion con api
import {
    apiGetAllProviders,
    apiPostDocumentListProducts
} from '../../../common/api-invent';

const AddListPricesDoc = ({ show, setShow }) => {
    const { selectedProvider } = useContext(SelectItemContext);
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 17;
    const {t} = useTranslation();
    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [providers, setProviders] = useState([]);

    // Hook de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });
    // Hook de formulario
    const [data, setData] = useState({
        id_proveedor: '0',
        fecha_recepcion: '',
        fecha_validez: '',
        archivo: []
    });

    // Cambiar id global de proveedor seleccionado
    useEffect(() => {
        setData(data => ({ ...data, id_proveedor: selectedProvider }));
    }, [selectedProvider]);

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Informacion de proveedores
                const reqPro = await apiGetAllProviders(1);
                setProviders([...reqPro.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setProviders([]);
            setLoading(false);
        }
    }, [setProviders, setLoading, show]);


    // Moficacion de campos
    const onInputChange = e => {
        setData({
            ...data,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }
    const updateFiles = (files) => {
        setData({
            ...data,
            archivo: files
        })
    };

    const isFormValid = () => {
        if (data.id_proveedor === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere proveedor',
                show: true
            });
            return false;
        }
        if (data.fecha_recepcion === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere fecha de recepción',
                show: true
            });
            return false;
        }
        if (data.fecha_validez === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere fecha de validez',
                show: true
            });
            return false;
        }
        if (data.archivo.length === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requieren documento',
                show: true
            });
            return false;
        }
        return true;
    }
    // Boton de agregar en formulario
    const handleSubmit = async e => {
        if (isFormValid()) {
            try {
                const sendData = new FormData();
                // Agregar archivo
                const sendFiles = data.archivo.map(({ file }) => (file));
                sendData.append('archivo', sendFiles[0]);
                sendData.append('fecha_recepcion', data.fecha_recepcion);
                sendData.append('fecha_validez', data.fecha_validez);
                const response = await apiPostDocumentListProducts(data.id_proveedor, sendData);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu documento ha sido agregado exitosamente",
                        icon: "success",
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Limpiar datos
                    setData({
                        id_proveedor: selectedProvider,
                        fecha_recepcion: '',
                        fecha_validez: '',
                        archivo: []
                    });
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al agregar documento");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nuevo documento',
                    text: error.response.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={t('agregar')+" "+t('lista')+" "+t('documento')}
                buttonTitle={t('agregar')}
                handleClick={handleSubmit}
                size="md"
            >{
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_proveedor" className="form-label">
                                            {t('proveedor')}: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_proveedor"
                                            id="id_proveedor"
                                            value={data.id_proveedor}
                                            onChange={onInputChange}
                                        >
                                            <option value="0">{t('seleccione')+" "+t('proveedor')}</option>
                                            {
                                                providers.map(({ id_proveedor, nombre }, index) => (
                                                    <option value={id_proveedor} key={index}>
                                                        {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="3" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="formato" className="form-label">
                                            Ej. {t('formato')}:   
                                        </label>
                                    </div>
                                </Col>
                                <Col xs="3" md="2" style={{ paddingTop: "30px" }}>
                                    <OverlayTooltip
                                        placement="top"
                                        tooltip={t('descargar')}
                                    >
                                        <a href={`${process.env.REACT_APP_BACKEND_URL}uploads/public/apoyos/Lista_Precio.xlsx`} className="btn btn-info" target="_blank" rel="noreferrer" download={true}>
                                        <i className="si si-cloud-download" />
                                        </a>
                                    </OverlayTooltip>
                                </Col>
                                <hr />
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="fecha_recepcion" className="form-label">
                                            {t('fecha')} Recepción: *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="fecha_recepcion"
                                            name="fecha_recepcion"
                                            value={data.fecha_recepcion}
                                            onChange={onInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="fecha_validez" className="form-label">
                                            {t('fecha')} Validez:
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="fecha_validez"
                                            name="fecha_validez"
                                            value={data.fecha_validez}
                                            onChange={onInputChange}
                                        />
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="form-group">
                                        <label htmlFor="archivo" className="form-label">
                                            <b>{t('documento')}:</b> (Solo {t('formato')} <b>CSV</b>)*
                                        </label>
                                        <Dropzone
                                            accept=".csv"
                                            onChange={updateFiles}
                                            value={data.archivo}
                                            footer={false}
                                            localization="ES-es"
                                            maxFiles={1}
                                        >
                                            {data.archivo.map((file, index) => (
                                                <FileItem
                                                    {...file}
                                                    localization="ES-es"
                                                    key={index}
                                                    preview
                                                />
                                            ))}
                                        </Dropzone>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    )
}

export default AddListPricesDoc