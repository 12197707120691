import { useReducer } from 'react';
// Objetos para autenticar
import AuthContext, { auth_types } from './auth.context';
import AuthReducer from './auth.reducer';
// Conexion con API
import { apiPostLogIn, apiGetUserToken } from '../../common/api-general';
// Configuracion
import { headersAuth } from '../../common/requests';
// Utilidades
import { hashText } from '../../utilities/funcs';

const AuthState = ({ children }) => {
    const initialState = {
        token: localStorage.getItem('tokenUser'),
        isAuth: false,
        user: null,
        errorMessage: '',
        isUserLoading: true,
    }
    const [state, dispatch] = useReducer(AuthReducer, initialState);
    //----------------------------------------------------
    // Hacer login
    const loginUser = async ({ user, password }) => {
        try {
            // Hashear contraseña antes de enviarse al BE
            const hashPassword = hashText(password);
            // Peticion al BE para login
            const request = await apiPostLogIn(user, hashPassword);

            dispatch({
                type: auth_types.LOGIN_SUCCESS,
                payload: {
                    ...request.data.content
                }
            });
            getUserAuth();
        } catch (error) {
            dispatch({
                type: auth_types.LOGIN_ERROR,
                payload: {
                    msg: error.response?.status === 500
                        ? 'Intentalo más tarde'
                        : error.response?.data.msg
                }
            })
        }
    }
    // Logout
    const logOutUser = async () => {
        dispatch({
            type: auth_types.LOGOUT,
        })
    }
    // Usuario autenticado
    const getUserAuth = async () => {
        const token = localStorage.getItem('tokenUser');
        const idCompanyUser = localStorage.getItem('idCompanyUser');
        if (token && idCompanyUser) {
            headersAuth({
                Authorization: `Bearer ${localStorage.getItem('tokenUser')}`,
                id_empresa: idCompanyUser
            });
        }
        try {
            const user = await apiGetUserToken();
            dispatch({
                type: auth_types.GET_USER,
                payload: user.data?.content
            })
        } catch (e) {
            console.log(e);
            dispatch({
                type: auth_types.LOGIN_ERROR,
            })
        }
    }
    const silenceLogin = async () => {
        const token = localStorage.getItem('tokenUser');
        const idCompanyUser = localStorage.getItem('idCompanyUser');
        if (token && idCompanyUser) {
            headersAuth({
                Authorization: `Bearer ${localStorage.getItem('tokenUser')}`,
                id_empresa: idCompanyUser
            });
            try {
                const user = await apiGetUserToken();
                dispatch({
                    type: auth_types.GET_USER,
                    payload: user.data?.content
                });
            } catch (e) { 
                dispatch({
                    type: auth_types.LOGOUT,
                });
            }
        } else {
            dispatch({
                type: auth_types.LOGOUT,
            });
        }
    }

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                isAuth: state.isAuth,
                user: state.user,
                errorMessage: state.errorMessage,
                isUserLoading: state.isUserLoading,
                logOutUser,
                loginUser,
                getUserAuth,
                silenceLogin
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
export default AuthState;