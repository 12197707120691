import { useState } from "react";

export const useForm = (data) => {

    const [form, setForm] = useState(data)

    const onChangeField = (e) => {
        const field = e.target.name;
        setForm({...form, 
            [field]: e.target.value
        })
    }

    return{
        form,
        onChangeField,
        setForm,
    }
}