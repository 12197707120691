export const fields = {
    store: {
        cleanFields:['product', 'rack_origin', 'level_origin', 'caja', 'rack', 'level']
    },
    type_movement:  {
        cleanFields:['product', 'rack_origin', 'level_origin', 'caja']
    },
    product: {
        cleanFields:['rack_origin', 'level_origin', 'caja']
    },
    rack_origin: {
        cleanFields:['level_origin', 'caja']
    },
    level_origin:{
        cleanFields:['caja']
    },
    caja: {
        cleanFields:[]
    },
    rack: {
        cleanFields:['level']
    },
    level: {
        cleanFields:[]
    },
}