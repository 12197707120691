import { useState, useEffect, useContext } from "react";
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from "sweetalert2";
import { Switch, useCheckboxState } from 'pretty-checkbox-react';
import 'pretty-checkbox';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
    apiGetProviderId,
    apiPatchProvider,
    apiGetTypeProviders
} from '../../../common/api-invent';

const EditProviderModal = ({ show, setShow, id }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComponent = 4;
    // Switches de opciones
    const s1 = useCheckboxState();
    const s2 = useCheckboxState();
    // Manejar carga de datos
    const [loading, setLoading] = useState(true);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        nombre: '',
        nombre_razon_social: '',
        version_cfdi: '',
        rfc: '',
        id_tipo_proveedor: '0',
        cfdi: 0,
        pdf_invoice: 0,
    });
    const arrVersCfdi = ['3.3', '4.0'];
    const [providers, setProviders] = useState([]);

    const { state: s1State, setState: s1SetState } = s1;
    const { state: s2State, setState: s2SetState } = s2;
    // Cargar informacion
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const reqPro = await apiGetTypeProviders();
                setProviders([...reqPro.data.content]);
                const req = await apiGetProviderId(id);
                s1SetState(Boolean(Number(req.data.content.cfdi)));
                s2SetState(Boolean(Number(req.data.content.pdf_invoice)));
                setData({
                    ...req.data.content,
                    version_cfdi: req.data.content.version_cfdi ?? ''
                });
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData();
        return () => { }
    }, [id, setData, show, s1SetState, s2SetState]);
    // Manejo de estados de switch
    useEffect(() => {
        setData(d => ({
            ...d,
            cfdi: Number(s1State),
            pdf_invoice: Number(s2State),
            version_cfdi: ''
        }));
    }, [s1State, s2State]);

    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre',
                show: true
            });
            return false;
        }
        if (data.nombre_razon_social === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere razón social',
                show: true
            });
            return false;
        }
        if (!!data.cfdi && data.version_cfdi === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere versión de cfdi',
                show: true
            });
            return false;
        }
        return true;
    }

    // Boton de accion
    const handleSubmit = async event => {
        event.preventDefault();

        if (isFormValid()) {
            try {
                const response = await apiPatchProvider(id, data);
                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "El proveedor ha sido guardado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Carga de componente
                    onReloadComponent(idReloadComponent);
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al editar proveedor");
                }
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data?.msg ?? 'Error al editar proveedor',
                        text: 'Intentelo nuevamente',
                        confirmButtonText: 'Aceptar',
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al conectar con sistema',
                        confirmButtonText: 'Aceptar',
                    });
                }
            }
        }
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Editar Proveedor"
                buttonTitle="Guardar"
                handleClick={handleSubmit}
                size="md"
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md="4" xs="6">
                                    <div className="form-group">
                                        <label htmlFor="nombre" className="form-label">
                                            Nombre: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            value={data.nombre}
                                            onChange={handleFormChange}
                                            placeholder="Proveedor 1"
                                        />
                                    </div>
                                </Col>
                                <Col md="8" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="nombre" className="form-label">
                                            Razón Social: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre_razon_social"
                                            name="nombre_razon_social"
                                            value={data.nombre_razon_social}
                                            onChange={handleFormChange}
                                            placeholder="Proveedor S.A de C.V"
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="nombre" className="form-label">
                                            RFC:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="rfc"
                                            name="rfc"
                                            value={data.rfc}
                                            onChange={handleFormChange}
                                            placeholder="XXXX000000XXX"
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_tipo_proveedor" className="form-label">
                                            Tipo de Proveedor: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_tipo_proveedor"
                                            id="id_tipo_proveedor"
                                            value={data.id_tipo_proveedor}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un tipo de proveedor</option>
                                            {
                                                providers.map(({ id_tipo_proveedor, nombre }, index) => (
                                                    <option value={id_tipo_proveedor} key={index}>
                                                        {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col className="mt-30" md="4">
                                    <Switch
                                        color="success"
                                        shape="fill"
                                        bigger
                                        className="form-label"
                                        disabled={s2.state}
                                        {...s1}
                                    >
                                        ¿Usa CFDI?
                                    </Switch>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="version_cfdi" className="form-label">
                                            Versión CFDI:
                                        </label>
                                        <select
                                            className="form-select"
                                            name="version_cfdi"
                                            id="version_cfdi"
                                            value={data.version_cfdi}
                                            onChange={handleFormChange}
                                            disabled={!s1.state}
                                        >
                                            <option value="">Seleccione una versión</option>
                                            {
                                                arrVersCfdi.map((element, index) => (
                                                    <option value={element} key={index}>
                                                        {element}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col className="mt-30" md="4">
                                    <Switch
                                        color="success"
                                        shape="fill"
                                        bigger
                                        className="form-label"
                                        disabled={s1.state}
                                        {...s2}
                                    >
                                        ¿Factura extranjera? (PDF)
                                    </Switch>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default EditProviderModal;