import { useState, useContext, useEffect } from "react";
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from "sweetalert2";
import { Dropzone, FileItem } from "@dropzone-ui/react";
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import SelectItemContext from '../../../context/selectItem/selectItem.context';
import ReloadContext from "../../../context/reload/reload.context";
import { useTranslation } from "react-i18next";
// Conexion con api
import {
    apiGetAllProducts,
    apiPostPictureProduct
} from '../../../common/api-invent';

const AddProdPicture = ({ show, setShow }) => {
    const { selectedProduct } = useContext(SelectItemContext);
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 13;
    const {t} = useTranslation();
    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);

    // Hook de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });
    // Hook de formulario
    const [data, setData] = useState({
        id_producto: '0',
        nombre: '',
        descripcion: '',
        archivo: []
    });

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const reqProducts = await apiGetAllProducts();
                setProducts([...reqProducts.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setProducts([]);
            setLoading(false);
        }
    }, [setProducts, setLoading, show]);

    // Cambiar id de producto seleccionado
    useEffect(() => {
        setData(data => ({ ...data, id_producto: selectedProduct }));
    }, [selectedProduct]);

    // Moficacion de campos
    const onInputChange = e => {
        setData({
            ...data,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }
    const updateFiles = (files) => {
        setData({
            ...data,
            archivo: files
        })
    };

    const isFormValid = () => {
        if (data.id_producto === '0' || data.id_producto === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere producto',
                show: true
            });
            return false;
        }
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre',
                show: true
            });
            return false;
        }
        if (data.archivo.length === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requieren archivos',
                show: true
            });
            return false;
        }
        return true;
    }
    // Boton de agregar en formulario
    const _handleSubmit = async (closeModal = true) => {
        if (isFormValid()) {
            try {
                const sendData = new FormData();
                sendData.append('nombre', data.nombre);
                sendData.append('descripcion', data.descripcion);
                const sendFiles = data.archivo.map(({ file }) => (file));
                sendData.append('archivo', sendFiles[0]);
                const response = await apiPostPictureProduct(data.id_producto, sendData);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu fotografía ha sido agregada exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Limpiar datos
                    setData({
                        id_producto: selectedProduct,
                        nombre: '',
                        descripcion: '',
                        archivo: []
                    });
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar fotografía");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nueva fotografía',
                    text: error.response.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(true);
    }

    // Guardar y limpiar
    const handleSave = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(false);
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="md"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={t('agregar')+" "+t('fotografia')}
                buttonTitle={t('agregar_y_cerrar')}
                handleClick={handleSaveClose}
                auxButtonTitle={t('agregar_y_guardar')}
                handleAuxButton={handleSave}
            >{
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_producto" className="form-label">
                                            {t('producto')}: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_producto"
                                            id="id_producto"
                                            value={data.id_producto}
                                            onChange={onInputChange}
                                        >
                                            <option value="0">{t('seleccione')+" "+t('producto')}</option>
                                            {
                                                products.map(({ pr_id_producto, pr_nombre }, index) => (
                                                    <option value={pr_id_producto} key={index}>
                                                        {pr_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="nombre" className="form-label">
                                            {t('nombre')}: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            value={data.nombre}
                                            onChange={onInputChange}
                                            placeholder="Foto Frente Producto"
                                        />
                                    </div>
                                </Col>
                                <Col md="12" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="descripcion" className="form-label">
                                            {t('descripcion')}:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="descripcion"
                                            name="descripcion"
                                            value={data.descripcion}
                                            onChange={onInputChange}
                                            placeholder="Foto Frente Producto"
                                        />
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="form-group">
                                        <label htmlFor="archivo" className="form-label">
                                            {t('fotografia')}: *
                                        </label>
                                        <Dropzone
                                            accept="image/jpeg,image/png"
                                            onChange={updateFiles}
                                            value={data.archivo}
                                            footer={false}
                                            localization="ES-es"
                                            maxFiles={1}
                                        >
                                            {data.archivo.map((file, index) => (
                                                <FileItem
                                                    {...file}
                                                    localization="ES-es"
                                                    key={index}
                                                    preview
                                                />
                                            ))}
                                        </Dropzone>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    )
}

export default AddProdPicture