import { useState, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
import { apiPostNewRole } from '../../../common/api-general';
import { useTranslation } from 'react-i18next';
// Conexion Api

const AddRol = ({ show, setShow }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 21;
    const {t} = useTranslation();
    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        rol: '',
    });

    // Llena datos con informacion del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.rol === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre del puesto',
                show: true
            });
            return false;
        }
        return true;
    }

    // Insertar nuevo puesto
    const _handleSubmit = async (closeModal = true) => {
        if (isFormValid()) {
            try {
                const response = await apiPostNewRole(data);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu rol agregado correctamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Limpiar datos
                    setData({
                        rol: '',
                    });
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar puesto");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nuevo puesto',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(true);
    }

    // Guardar y limpiar
    const handleSave = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(false);
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="md"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={t('agregar')+" Rol"}
                buttonTitle={t('agregar_y_cerrar')}
                handleClick={handleSaveClose}
                auxButtonTitle={t('agregar_y_guardar')}
                handleAuxButton={handleSave}
            >
                <Form>
                    <Row>
                        <Col md="6" xs="12">
                            <div className="form-group">
                                <label htmlFor="rol" className="form-label">
                                    {t('nombre_del_rol')}: *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="rol"
                                    name="rol"
                                    value={data.rol}
                                    onChange={handleFormChange}
                                    placeholder="Administrador"
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modals>
        </>
    );
}

export default AddRol;