import PropTypes from 'prop-types';
import {
    Modal,
    Button
} from 'react-bootstrap';
import Loader from 'react-loader-spinner'
// Estilos personalizados
import './modal.styled.css';

//Propiedades del Modal
const Modals = ({
    children,
    size,
    show,
    hideModal,
    handleClick,
    modalTitle,
    buttonTitle,
    handleAuxButton,
    auxButtonTitle,
    isButtonLoading,
}) => {
    return (
        //Componente Modal
        <Modal
            centered={true}
            size={size}
            show={show}
            onHide={hideModal}
            className="theme-primary"
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-primary mb-0">
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <Button
                    variant="danger"
                    onClick={() => { hideModal() }}
                >
                    Cancelar
                </Button>
                <div
                    className="d-flex"
                    style={{ gap: "20px" }}
                >
                    {auxButtonTitle !== ''
                        ? <Button
                            variant="warning"
                            onClick={handleAuxButton}
                        >
                            {auxButtonTitle}
                        </Button>
                        : <></>
                    }
                    {
                        !handleClick 
                        ? null 
                        : <Button
                            variant="primary"
                            onClick={handleClick}
                            disabled={isButtonLoading}
                        >
                            {
                                isButtonLoading
                                    ? <Loader
                                        type="TailSpin"
                                        color="#fff"
                                        secondaryColor="#fff"
                                        height="15px"
                                        width="40px"
                                        radius={5}
                                    />
                                    : buttonTitle
                            }
                        </Button>
                    }
               
                </div>
            </Modal.Footer>
        </Modal >
    );
}

Modals.propTypes = {
    size: PropTypes.string,
    show: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    auxButtonTitle: PropTypes.string.isRequired,
    handleAuxButton: PropTypes.func.isRequired,
    isButtonLoading: PropTypes.bool.isRequired,
}
Modals.defaultProps = {
    size: 'lg',
    auxButtonTitle: '',
    handleAuxButton: () => { },
    isButtonLoading: false
}

export default Modals;