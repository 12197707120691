import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import SelectItemContext from '../../../context/selectItem/selectItem.context';
import ReloadContext from "../../../context/reload/reload.context";
import { useTranslation } from 'react-i18next';
// Conexion Api
import {
    apiGetAllProducts,
    apiGetStores,
    apiGetAllRacks,
    apiGetLevelsWithRack,
    apiPostStorageRuleProduct,
} from '../../../common/api-invent';

const AddProdStorageRule = ({ show, setShow }) => {
    const { selectedProduct } = useContext(SelectItemContext);
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 20;
    const {t} = useTranslation();
    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [stores, setStores] = useState([]);
    const [racks, setRacks] = useState([]);
    const [levels, setLevels] = useState([]);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        id_producto: '0',
        id_store: '0',
        id_rack: '0',
        id_almacen_ubicacion: '0',
        razon_ubicacion: '',
        fecha: moment().format("YYYY-MM-DD"),
        prioridad: '0',
    });
    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Solicitar productos
                const reqProducts = await apiGetAllProducts();
                setProducts([...reqProducts.data.content]);
                // Solicitar almacenes
                const reqStores = await apiGetStores();
                setStores([...reqStores.data.content])
                setLoading(false);
            } catch (error) {
                console.log(`Ocurrio un error:\n${error}`)
                Swal.fire({
                    icon: 'error',
                    title: error.response?.data?.msg ?? 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setProducts([]);
            setLoading(false);
        }
    }, [setProducts, setStores, setLoading, show]);

    // Cargar racks de un almacen
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                setData(data => ({
                    ...data,
                    id_rack: '0',
                }));
                // Solicitar racks
                const reqRacks = await apiGetAllRacks(data.id_store);
                setRacks([...reqRacks.data.content])
                setLoading(false);
            } catch (error) {
                console.log(`Ocurrio un error:\n${error}`)
                Swal.fire({
                    icon: 'error',
                    title: error.response?.data?.msg ?? 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        loadData();
    }, [data.id_store, setRacks]);

    // Cargar Niveles de un rack
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                setData(data => ({
                    ...data,
                    id_almacen_ubicacion: '0',
                }));
                // Solicitar racks
                const reqLevels = await apiGetLevelsWithRack(data.id_rack);
                setLevels([...reqLevels.data.content]);
                setLoading(false);
            } catch (error) {
                console.log(`Ocurrio un error:\n${error}`)
                Swal.fire({
                    icon: 'error',
                    title: error.response?.data?.msg ?? 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        loadData();
    }, [data.id_rack, setLevels]);

    // Cambiar id de producto seleccionado
    useEffect(() => {
        setData(data => ({ ...data, id_producto: selectedProduct }));
    }, [selectedProduct]);

    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.id_producto === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere producto',
                show: true
            });
            return false;
        }
        if (data.id_store === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere almacén',
                show: true
            });
            return false;
        }
        if (data.id_rack === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere rack',
                show: true
            });
            return false;
        }
        if (data.id_almacen_ubicacion === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nivel',
                show: true
            });
            return false;
        }
        if (data.razon_ubicacion === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere razón',
                show: true
            });
            return false;
        }
        if (data.prioridad === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere prioridad',
                show: true
            });
            return false;
        }
        return true;
    }

    // Insertar nueva caja
    const _handleSubmit = async (closeModal = true) => {
        if (isFormValid()) {
            try {
                const response = await apiPostStorageRuleProduct(data);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu regla ha sido agregado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Limpiar datos
                    setData({
                        id_producto: selectedProduct,
                        id_store: '0',
                        id_rack: '0',
                        id_almacen_ubicacion: '0',
                        razon_ubicacion: '',
                        fecha: moment().format("YYYY-MM-DD"),
                        prioridad: '0',
                    });
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar regla");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nueva regla',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(true);
    }

    // Guardar y limpiar
    const handleSave = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(false);
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="md"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={t('agregar')+" "+t('regla')+" "+t('almacenamiento')}
                buttonTitle={t('agregar_y_cerrar')}
                handleClick={handleSaveClose}
                auxButtonTitle={t('agregar_y_guardar')}
                handleAuxButton={handleSave}
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_producto" className="form-label">
                                            {t('producto')}: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_producto"
                                            id="id_producto"
                                            value={data.id_producto}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">{t('seleccione')+" "+t('producto')}</option>
                                            {
                                                products.map(({ pr_id_producto, pr_nombre }, index) => (
                                                    <option value={pr_id_producto} key={index}>
                                                        {pr_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_store" className="form-label">
                                            {t('almacen')}: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_store"
                                            id="id_store"
                                            value={data.id_store}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">{t('seleccione')+" "+t('almacen')}</option>
                                            {
                                                stores.map(({ glb_id_almacen, glb_nombre }) => (
                                                    <option value={glb_id_almacen} key={glb_id_almacen}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_rack" className="form-label">
                                            Rack: *
                                        </label>
                                        <select
                                            disabled={data.id_store === '0'}
                                            className="form-select"
                                            name="id_rack"
                                            id="id_rack"
                                            value={data.id_rack}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">{t('seleccione')}  rack</option>
                                            {
                                                racks.map(({ id_rack, codigo_rack, nombre }) => (
                                                    <option value={id_rack} key={id_rack}>
                                                        {codigo_rack} - {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_almacen_ubicacion" className="form-label">
                                            {t('nivel')}: *
                                        </label>
                                        <select
                                            disabled={data.id_rack === '0'}
                                            className="form-select"
                                            name="id_almacen_ubicacion"
                                            id="id_almacen_ubicacion"
                                            value={data.id_almacen_ubicacion}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">{t('seleccione')+" "+t('nivel')}</option>
                                            {
                                                levels.map(({ id_almacen_ubicacion, codigo_ubicacion, nombre }) => (
                                                    <option value={id_almacen_ubicacion} key={id_almacen_ubicacion}>
                                                        {codigo_ubicacion} - {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="8" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="razon_ubicacion" className="form-label">
                                            {t('razon')+" "+t('ubicacion')}: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="razon_ubicacion"
                                            id="razon_ubicacion"
                                            value={data.razon_ubicacion}
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                </Col>
                                <Col md="4" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="prioridad" className="form-label">
                                            {t('prioridad')}: *
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="prioridad"
                                            id="prioridad"
                                            value={data.prioridad}
                                            onChange={handleFormChange}
                                            min="1"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default AddProdStorageRule;