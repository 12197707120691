// Dependencias
import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// Animacion al expandir
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
// Contexto
import SelectItemContext from '../../context/selectItem/selectItem.context';
// Estilos
import './mainSidebar.styled.css';
// Utilidades
import { BaseDashBoard } from '../../utilities/constants'

//--------------------------------------------------
const MainSideBarItem = ({ listItems, idPage, icon, name, idPageActive, isCollapsed }) => {
    const history = useHistory();
    const { onSaveLastPage } = useContext(SelectItemContext);

    const [showItems, setShowItems] = useState(false);
    const [isSlideDown, setIsSlideDown] = useState(false);
    const [chidIsActive, setChildIsActive] = useState(false);
    useEffect(() => {
        setIsSlideDown(!isCollapsed);
        listItems.forEach(item => {
            if (item.idPage === idPageActive) {
                setChildIsActive(true);
                setShowItems(true)
            }
        });
    }, [setIsSlideDown, setChildIsActive, setShowItems, listItems, idPageActive, isCollapsed,]);

    // Click en boton para navegar
    const onNavigate = idPage => {
        onSaveLastPage(idPage);
        history.push(BaseDashBoard + idPage);
    }

    // Clases activas
    const classActive = (idPageActive === idPage || chidIsActive) ? 'active ' : '';
    const classChildren = (listItems.length > 0) ? 'treeview ' : '';
    // Verificar hijos
    if (listItems.length === 0) {
        // Elemento de navegacion simple
        return (
            <li className={classActive + classChildren}>
                <a href="#!" onClick={(e) => {
                    e.preventDefault();
                    onNavigate(idPage)
                }}>
                    <i className={icon} />
                    <span>{name}</span>
                </a>
            </li >
        )
    } else {
        // Elemento con hijos
        return (
            <li className={classActive + classChildren + (showItems ? 'menu-open' : '')}>
                <button onClick={() => { setShowItems(!showItems) }}>
                    <i className={icon} />
                    <span>{name}</span>
                    <span className="pull-right-container">
                        <i className="si si-arrow-right pull-right" />
                    </span>
                </button>
                {
                    isSlideDown
                        ? <SlideDown
                            as="ul"
                            className="treeview-menu menu-slidedown"
                            closed={!showItems}
                        >
                            {
                                listItems.map((item, index) => (
                                    <MainSideBarItem
                                        key={index}
                                        listItems={item.children ?? []}
                                        idPageActive={idPageActive}
                                        idPage={item.idPage}
                                        icon={item.icon}
                                        name={item.name}
                                    />
                                ))
                            }
                        </SlideDown>
                        : <ul className="treeview-menu">
                            {
                                listItems.map((item, index) => (
                                    <MainSideBarItem
                                        key={index}
                                        listItems={item.children ?? []}
                                        idPageActive={idPageActive}
                                        idPage={item.idPage}
                                        icon={item.icon}
                                        name={item.name}
                                    />
                                ))
                            }
                        </ul>
                }
            </li >
        )
    }
}

// Documentacion con proptypes
MainSideBarItem.propTypes = {
    listItems: PropTypes.array.isRequired,
    idPage: PropTypes.number,
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    idPageActive: PropTypes.number.isRequired,
    isCollapsed: PropTypes.bool,
}

//--------------------------------------------------
const MainSidebar = ({ listItems, idPageActive, isCollapsed }) => {
    return (
        <aside className="main-sidebar">
            <section className="sidebar position-relative">
                <div className="multinav">
                    <div className="multinav-scroll ps" >
                        <ul className="sidebar-menu tree" >
                            {
                                listItems.map((item, index) => (
                                    <MainSideBarItem
                                        key={index}
                                        isCollapsed={isCollapsed}
                                        idPageActive={idPageActive}
                                        listItems={item.children}
                                        idPage={item.idPage}
                                        icon={item.icon}
                                        name={item.name}
                                    />
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </aside>
    );
}

// Documentacion con proptypes
MainSidebar.propTypes = {
    listItems: PropTypes.array.isRequired,
    idPageActive: PropTypes.number.isRequired,
    isCollapsed: PropTypes.bool,
}
MainSidebar.defaulProps = {
    isCollapsed: false,
}

export default MainSidebar;