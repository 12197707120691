import { useState, useContext, useEffect} from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import {
    Button
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import { apiGenerarEndpoint,  apiGetTiposDatos} from '../../../common/api-invent';
import { decrypt_script, encrypt_script } from '../../../utilities/encrypt-checker';
import { useTranslation } from 'react-i18next';
const AddEndPoint = ({ show, setShow }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 37;
    // Carga de data    
    const [tiposDato, setTiposDato] = useState([]);
    const [numCampos, setnumCampos] = useState(0);
    const [arreglo, setArreglo] = useState([]);
    const { t } = useTranslation();
    //const [parametros, setParametros] = useState([]);
    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });
    let parametrosData = []
    // Hook para formulario
    const [data, setData] = useState({
        nombre: '',
        script: '',
        password: '',
        parametros: [],
        descripcion: '',
    });

    
    // Cargar tipos de datos
    useEffect(() => {
        const loadData = async () => {
            try {
                // Informacion de proveedores
                const reqPro = await apiGetTiposDatos();
                setTiposDato([...reqPro.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setTiposDato([]);
        }
    }, [setTiposDato, show]);

    //Libreria
    const [code, setCode] = useState("");

    // Llena datos con informacion del formulario
    const handleFormChange = e => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        const regex = new RegExp("^[a-zA-Z0-9 ]+$");
        if (e.target.name === "nombre") {
            if (regex.test(e.target.value) === false) {
                setAlert({
                    title: 'No se permiten caracteres especiales en el campo Nombre',
                    message: 'Ingrese sólo valores alfanuméricos',
                    show: true
                });
                return false;
            }
        }
        
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

     //Mostrar Parámetros
     const showParameters = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        
        //Obtener los nombres de los parametros
        data.script=code;
        if (data.script === '') {
            setAlert({
                title: 'Script vacio o sin parámetros',
                message: 'Se requiere script',
                show: true
            });
            setnumCampos(0);
            setArreglo([]);
            return false;
        }
        let PrinActi = /\[.*?\]/g;
        let PrinAct2 = data.script.match(PrinActi);
        PrinAct2 = (PrinAct2 == null) ? [] : data.script.match(PrinActi);
        if (PrinAct2.length >= 0) {

            PrinAct2.map((value, index) =>{
                return PrinAct2[index] = PrinAct2[index].replace('[', '').replace(']', '');
            })
        }
        setnumCampos(PrinAct2.length)
        PrinAct2 = PrinAct2.filter((valor, indice) => {
            return PrinAct2.indexOf(valor) === indice;
          }
        );
        setArreglo(PrinAct2);
     }

     const getParams = (index, e, item) => {
        //Variables de bloque
        let elemento=document.getElementById(item+"Select"+index)
        let label=document.getElementById(item+"Label"+index)
        let select=document.getElementById(item+"SelectValue")
        //Funcion de apareceer el campo
        elemento.classList.remove("select-display");
        label.classList.remove("select-display");
        //Cambiar tipo de dato
        switch (select.value) {
            case '1':
                elemento.type='date'
                break;
            case '2':
                elemento.type='text'
                break;
            case '3':
                elemento.type='number'
                break;
            case '4':
                elemento.type='number'
                break;
            default:
                break;
        }

        //Asignacion de parametros
        let parametrosData2 = {
            "parametro":e.currentTarget.name,
            "id_tipo": e.currentTarget.value,
            //"valor": elemento.value
        } 
        parametrosData[index] = parametrosData2;
        data.parametros=JSON.parse(JSON.stringify(parametrosData));
        
     }

     
     const setInputValue = async (e, index, item) => {
        let parametrosData2 = {
            "parametro":item,
            //"id_tipo": e.currentTarget.value,
            "valor": e.target.value
        }
        parametrosData[index] = parametrosData2;
        data.parametros=JSON.parse(JSON.stringify(parametrosData));
     }


    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre del endpoint',
                show: true
            });
            return false;
        }
        if(data.id_rol === '')
        {
            setAlert({
                title: 'Error',
                message: 'Debe seleccionar un rol',
                show: true,
            });
            return false;
        }
        if (data.descripcion === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere descripcion del endpoint',
                show: true
            });
            return false;
        }
        if (data.password === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere la contraseña del endpoint',
                show: true
            });
            return false;
        }
        if (data.script === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere script para generar el endpoint',
                show: true
            });
            return false;
        }
        
        return true;
    }

    // Insertar nuevo query
    const _handleSubmit = async (closeModal = true) => {
        data.script=code;
        if (isFormValid()) {
            try {
                //Codear a B64 el script
                data.script = await encrypt_script(data.script);
                data.nombre=data.nombre.replaceAll(" ","_");
                const response = await apiGenerarEndpoint(data);
                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "EndPoint añadido correctamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Limpiar datos
                    setData({
                        nombre: '',
                        script: '',
                        roles: [],
                        password: '',
                        parametros: [],
                    });
                    setArreglo([]);
                    setCode("");
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar el script");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nuevo script',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
                const scripted = await decrypt_script(JSON.stringify(data.script));
                setData({...data,
                    script: scripted
                }) 
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(true);
    }

    // Guardar y limpiar
    const handleSave = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(false);
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="xl"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={t('agregar')+"  EndPoint"}
                buttonTitle={t('agregar_y_cerrar')}
                handleClick={handleSaveClose}
                auxButtonTitle={t('agregar_y_guardar')}
                handleAuxButton={handleSave}
            >
                <Form>
                    <Row>
                        <Col md="12" xs="12">
                            <div className="form-group">
                                <label htmlFor="nombre" className="form-label">
                                    {t('nombre')}: *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    pattern="[a-z]{1,15}"
                                    id="nombre"
                                    name="nombre"
                                    value={data.nombre}
                                    onChange={handleFormChange}
                                    placeholder={t('nombre')+" Endpoint"}
                                />
                            </div>
                        </Col>
                        <Col md="12" xs="12">
                            <div className="form-group">
                                <label htmlFor="descripcion" className="form-label">
                                    {t('descripcion')}: *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="descripcion"
                                    name="descripcion"
                                    value={data.descripcion}
                                    onChange={handleFormChange}
                                    placeholder={t('descripcion') +" Endpoint"}
                                />
                            </div>
                        </Col>
                        <Col md="12" xs="12">
                            <div className="form-group">
                                <label htmlFor="password" className="form-label">
                                    {t('contrasena')}: *
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    value={data.password}
                                    onChange={handleFormChange}
                                    placeholder="*******"
                                />
                            </div>
                        </Col>
                        <Col className="mt-30" md="12" xs="12">
                            <CodeEditor
                                value={code}
                                id="script"
                                name="script"
                                language="sql"
                                data-color-mode="dark"
                                placeholder={t('inserte_sql')}
                                onChange={(evn) => setCode(evn.target.value)}
                                padding={15}
                                style={{
                                    fontSize: 15,
                                    backgroundColor: "#f5f5f5",
                                }}
                                />
                        </Col>
                        <Col className="mt-30 mb-20" md="12" xs="12">
                            <Button
                                variant="success"
                                onClick={showParameters}
                            >
                                {t('obtener_parametros')}
                            </Button>
                        </Col>
                        {
                            numCampos > 0 && arreglo.length > 0
                            ?
                            arreglo.map((item, index) => 
                                
                                <div key={index} className='row mt-5'>
                                     <Col md="4" xs="4">
                                     <label htmlFor="nombre" className="form-label">
                                        {t('parametro')}: 
                                    </label>
                                        <div className="form-group mt-5">
                                            <input
                                                name={item}
                                                id={item}
                                                type="text"
                                                className="form-control"
                                                placeholder={item}
                                                value={item}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    
                                    <Col md="4" xs="4">
                                    <label htmlFor="nombre" className="form-label">
                                        {t('tipo_dato')}: 
                                    </label>
                                    <div className="form-group mt-5">
                                            <select name={item} key={index} className="form-select" id={item+"SelectValue"} onChange={e => getParams(index, e, item)}>
                                                <option value="0">{t('seleccione')} {t('tipo_dato')}</option>
                                                {
                                                    tiposDato.map((item,index)=>
                                                        <option value={item.id_tipo}>{item.tipo}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                    <Col md="4" xs="4">
                                    <label htmlFor="nombre" id={item+"Label"+index} className="form-label  select-display">
                                        Valor del dato: 
                                    </label>
                                    <div className="form-group mt-5">
                                            <input type="text" id={item+"Select"+index} className='form-control select-display' onChange={e => setInputValue(e, index, item)}/>
                                        </div>
                                    </Col>
                                </div>

                            )
                            : <></>
                        }
                    </Row>
                </Form>
            </Modals>
        </>
    );
}

export default AddEndPoint;