import { useState, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
    apiPostNewClient,
} from '../../../common/api-invent';
import { useTranslation } from 'react-i18next';
const AddClient = ({ show, setShow  }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 32;
    const { t } = useTranslation();
    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        nombre: '',
        rfc: '',
    });


    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre del puesto',
                show: true
            });
            return false;
        }
        if (data.rfc === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere RFC',
                show: true
            });
            return false;
        }
        return true;
    }

    // Peticion Agregar Cliente
    const handleSubmit = async () => {
        if (isFormValid()) {
            try {
                const response = await apiPostNewClient(data);
                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "El cliente ha sido agregado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al agregar cliente");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar cliente',
                    text: 'Intentelo nuevamente más tarde',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Agregar Cliente"
                buttonTitle="Guardar"
                handleClick={handleSubmit}
                size="md"
            >
                <Form>
                    <Row>
                        <Col md="6" xs="12">
                            <div className="form-group">
                                <label htmlFor="nombre" className="form-label">
                                    {t('nombre')}: *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nombre"
                                    name="nombre"
                                    value={data.nombre}
                                    onChange={handleFormChange}
                                    placeholder="Juan Perez"
                                />
                            </div>
                        </Col>
                        <Col md="6" xs="12">
                            <div className="form-group">
                                <label htmlFor="rfc" className="form-label">
                                    RFC: *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="rfc"
                                    name="rfc"
                                    value={data.rfc}
                                    onChange={handleFormChange}
                                    placeholder="AAAAXXXXXXZZZZ"
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modals>
        </>
    );
}

export default AddClient;