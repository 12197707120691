import { useEffect, useState, useContext, useMemo, useRef, useCallback } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
// Bootstrap
import { Row, Col, Button } from 'react-bootstrap';
// Context
import SelectItemContext from '../context/selectItem/selectItem.context';
import ReloadContext from '../context/reload/reload.context';
// Componentes generales
import Load from './Load/load.component';
import GeneralBox from './GeneralBox/generalBox.component';
import HorizontalTabs from './HorizontalTabs/horizontalTabs.component';
import ViewBoxes from './ViewBoxes/viewBoxes.component';
import TableSimple from './TableSimple/tableSimple.component';
import TableData from './TableData/tableData.component';
import { SmallDetailBox } from './SmallDetailBox/SmallDetailBox';

import ImageSlider from './ImageSlider/imageSlider.component';
import { OverlayTooltip } from './common.component';
// Modales
import EditProductModal from './Modals/Edit/editProduct.modal';
import EditRackModal from './Modals/Edit/editRack.modal';
import EditStoreModal from './Modals/Edit/editStore.modal';
import EditLevelModal from './Modals/Edit/editLevel.modal';
import EditDependencyModal from './Modals/Edit/editDependency.modal';
import EditProviderModal from './Modals/Edit/editProvider.modal';
import EditProdBox from './Modals/Edit/editProdBox.modal';
import EditProdCode from './Modals/Edit/editProdCode.modal';
import EditProdFeat from './Modals/Edit/editProdFeat.modal';
import EditProdProperties from './Modals/Edit/editProdProperties.modal';
import EditProdPicture from './Modals/Edit/editProdPicture.modal';
import EditProdDocument from './Modals/Edit/editProdDocument.modal';
import EditRol from './Modals/Edit/editRol.modal';
import EditStaff from './Modals/Edit/editStaff.modal';
import EditUser from './Modals/Edit/editUser.modal';
import EditClient from './Modals/Edit/editClient.modal';
import EditExitModal from './Modals/Edit/editExit.modal';
import EditReportSQL from './Modals/Edit/editSql.modal';
import EditReportSQLData from './Modals/Edit/editSqlData.modal';
import EditEndPointModal from './Modals/Edit/editEndpoint.modal';
import PrepedidosModal from './Modals/extra/prepedidos.modal'

import ProcessingEntranceProductsModal from './Modals/extra/processingEntranceProducts.modal';
import AddEntranceProductModal from './Modals/extra/addEntranceProduct.modal';

import {Indicators} from './Indicators/Indicators'
//import EditMeasureExitModal from './Modals/extra/editMeasureExit.modal';

// Solcitud al BE Inventarios
import {
  apiGetRacksWithExistance,
  apiGetStores,
  apiGetLevelsWithRack,
  apiGetQRRack,
  apiDeleteLeveRack,
  apiGetAllProducts,
  apiDeleteProduct,
  apiGetExecute,
  apiGetDependcy,
  apiDeleteDependcyId,
  apiGetAllProviders,
  apiDeleteProvider,
  apiGetDetailsProduct,
  apiDeleteDetailProduct,
  apiGetPropiertiesProduct,
  apiDeletePropiertyProduct,
  apiGetPicturesProduct,
  apiDeletePictureProduct,
  apiGetGetDocsProduct,
  apiDeleteDocumentProduct,
  apiGetListPricesProv,
  apiGetAllProductsByListPrice,
  apiGetAllMovsReasons,
  apiGetBillsByProv,
  apiGetAllProdsByBill,
  apiGetStorageRulesProduct,
  apiDeleteStorageRuleProduct,
  apiGetAllStaff,
  apiDeleteStaffId,
  apiGetPendingDocs,
  apiGetProcessingProds,
  apiGetAllProcessingProds,
  apiGetAllEntries,
  apiGetAllPhysicalInvent,
  apiGetDetailPhysicalInvent,
  apiGetAllScrapByStore,
  apiGetAllScrapProductsByStore,
  apiPatchScrap,
  apiGetAllExitsPerPage,
  apiGetDocumentExit,
  apiGetAllMeasureEq,
  apiGetAllPetitions,
  apiGetPetitionById,
  apiGetAllClients,
  apiDeleteClient,
  apiGetAllProductsStocked,
  apiGetExitDetailsById,
  apiGetExitDetailsMeasures,
  apiGetProductsAct,
  apiGetInvoice,
  apiGetProductsStock,
  apiGetTickets,
  apiGetExit,
  apiGetBoxesAllRacks,
  apiGetExistBoxes,
  apiGetAllScrap,
  apiGetMedScrap,
  apiPuntoReorden,
  apiGetAllTypeMov,
  apiDeleteExit,
  apiGetAllPetitionsERP,
  apiGetPetitionERPById,
  apiGetDetailsByLevel,
  apiGetScripts,
  apiDeleteScript,
  apiGetEndPoints,
  apiDeleteEndPoint,
  apiGetAllPrepedidos, 
  apiDeletePrepedido,
  apiPutPrepedido
} from '../common/api-invent'
import {
  apiGetAllUsers,
  apiDeleteRole,
  apiGetRoles
} from '../common/api-general'
// Utilidades
import { formatCurrency } from '../utilities/funcs';
// Colores
import { ColorPrimary, ColorDanger, ColorWarning } from '../utilities/pallete.const';

// ------------------- GENERACION DE COMPONENTES ---------------------

// Informacion de almacenes
export const ElementA = ({ permissions }) => {
  const { reloadComp1, reloadComp3, onReloadComponent } = useContext(ReloadContext);

  const {
    onSelectStore,
    onSelectRack,
    selectedStore,
    selectedRack: globalSelectedRack,
  } = useContext(SelectItemContext)

  const [info, setInfo] = useState({});
  const [showEditStore, setShowEditStore] = useState(false);
  const [selectEditStore, setSelectEditStore] = useState(0)
  const [showEditRack, setShowEditRack] = useState(false);
  const [selectEditRack, setSelectEditRack] = useState(0);
  const [selectRack, setSelectRack] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  // Cambio en seleccionar tab
  useEffect(() => {
    const index = Object.keys(info).findIndex((key) => (info[key].id === selectedStore));
    setActiveTab(index);
  }, [selectedStore, info, setActiveTab]);

  // Escuchar cambio global en el rack
  useEffect(() => {
    setSelectRack(globalSelectedRack);
    const DivRack = document.querySelector("#rack");
    if(DivRack){
      DivRack.scrollIntoView({ block: 'start', behavior: "smooth"});
    }
  }, [globalSelectedRack, setSelectRack]);

  // Cargar informacion
  useEffect(() => {
    const getInfo = async () => {
      // -----------------------------------------------------
      // Componente de Tabs
      const reqStores = await apiGetStores();
      const tabsInfo = {};
      await Promise.all(reqStores.data.content.map(async (store, jndex) => {
        // Solicitar racks de cada almacen
        const reqRack = await apiGetRacksWithExistance(store.glb_id_almacen);
        const rackInfo = reqRack.data.content;
        const auxFormatRacks = Object.keys(rackInfo).map(keyRow => {
          const auxObjCol = {}
          Object.keys(rackInfo[keyRow]).forEach(keyCol => {
            auxObjCol[keyCol] = {
              id: rackInfo[keyRow][keyCol].id_rack,
              quantity: rackInfo[keyRow][keyCol].cantidad,
              name: rackInfo[keyRow][keyCol].nombre,
            }
          })
          return auxObjCol;
        });
        // Solicitar codigo qr
        const getQrCodes = async idRack => {
          try {
            const reqQr = await apiGetQRRack(idRack);
            window.open(reqQr.data.content.url, '_blank');
          } catch (e) {
            console.log(`Error al generar qr:\n${e}`)
            Swal.fire({
              icon: 'error',
              title: 'Error al generar QR',
              text: e.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
              confirmButtonText: 'Aceptar',
            });
          }
        }
        // Abrir modal de edicion
        const openEditRack = idRack => {
          setSelectEditRack(idRack);
          setShowEditRack(true);
        }
        // Generar componente de cuadricula de racks
        const SubComp1 = <ViewBoxes
          title="Vista de Racks"
          arrayBoxes={[...auxFormatRacks ?? []]}
          onBoxClick={(id) => {
            setSelectRack(id);
            // eslint-disable-next-line
            onSelectRack(id);
          }}
          onDetailClick={openEditRack}
          onClickQr={getQrCodes}
          detailText="Editar"
        />
        // Componente de edicion de almacen
        const handleEditStore = () => {
          setShowEditStore(true);
          setSelectEditStore(store.glb_id_almacen)
        }
        const SubComp3 =
          <Row>
            <Col xs className="mb-20 px-20">
              <Button
                variant="info"
                onClick={handleEditStore}
              >
                <i className="si si-pencil" /> Editar Almacen
              </Button>
            </Col>
          </Row>
        // -------------------------------------------------------
        // Guardar objeto con titulo y contenido
        tabsInfo[jndex] = {
          id: store.glb_id_almacen,
          // Titulo del tab
          title: store.glb_nombre,
          // Contenido
          content:
            <>
              {SubComp3}
              {SubComp1}
            </>
        };
      }));
      // Guardar informacion de tabs
      setInfo({ ...tabsInfo });
      // Seleccionar primer almacen
    };
    getInfo();
    return () => {
      setInfo({});
      setShowEditStore(false);
      setShowEditRack(false);
    }
    // eslint-disable-next-line 
  }, [
    setInfo,
    setSelectRack,
    selectRack,
    setShowEditStore,
    setSelectEditRack,
    setShowEditRack,
    setSelectEditStore,
    onReloadComponent,
    reloadComp1,
    reloadComp3,
  ]);

  // Componente con modales
  return (
    <>
      <GeneralBox>
        <HorizontalTabs
          activeTab={activeTab}
          onInitilize={onSelectStore}
          tabsTitles={Object.keys(info).map(key => ({
            content: info[key].title,
            id: info[key].id
          }))}
          tabsContent={Object.keys(info).map(key => info[key].content)}
          onChangeTab={(idStore) => {
            onSelectStore(idStore);
            setSelectRack(0);
          }}
        />
      </GeneralBox>
      <EditRackModal
        show={showEditRack}
        setShow={setShowEditRack}
        id={selectEditRack}
      />
      <EditStoreModal
        show={showEditStore}
        setShow={setShowEditStore}
        id={selectEditStore}
      />
    </>
  );
}

// Informacion de racks
export const ElementAM = ({ permissions }) => {
  const { reloadComp1, onReloadComponent } = useContext(ReloadContext);
  const { onSelectLevel, selectedRack } = useContext(SelectItemContext);
  const [seleccionadoTemp, setSeleccionadoTemp] = useState();
  let seleccionado;
  let elemento;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t}=useTranslation();
  // Cargar informacion
  useEffect(() => {
    const loadData = async () => {
      setData([]);
      setLoading(true);
      try {
        setData([]);
        const reqLevels = await apiGetLevelsWithRack(selectedRack);
        const formatData = reqLevels.data.content.map((e) => ({
          ...e,
          id: Number(e.id_almacen_ubicacion),
          codigo: e.codigo_ubicacion ? e.codigo_ubicacion : 'S/CODIGO',
          existencias: e.cantidad ? e.cantidad : '0',
          golden: e.golden ? e.golden : '0'
        }));
        setData([...formatData]);
        setLoading(false);
      }
      catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if(selectedRack !== 0) {
      loadData();
    }
    // eslint-disable-next-line 
  }, [selectedRack, setLoading, reloadComp1]);

  // Funcion de eliminar nivel
  const handleDeleteLevel = async id => {
    try {
      await apiDeleteLeveRack(id);
      await Swal.fire({
        icon: 'success',
        title: 'El nivel ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      });
      onReloadComponent(1);
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar el nivel',
        text: 'Revise las dependencias e intentelo de nuevo despues',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  // Funcion para ver detalle de los productos
  const handleShowDetailLevel = id => {
    try {
      onSelectLevel(id);
      seleccionado = id;
      setSeleccionadoTemp(seleccionado);
      if (seleccionadoTemp) {
        elemento = document.getElementById(seleccionadoTemp)
        elemento.classList.remove('seleccionado')
        elemento.classList.add('btn-warning')
      }
      elemento = document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
    } catch (e) {

      elemento = document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
    }



  }
  
  // Titulos de las columnas
  const columns = [
    {
      text: 'Nivel',
      key: 'nivel',
      sortable: true,
    },
    {
      text: t('nombre'),
      key: 'nombre',
      sortable: true,
    },
    {
      text: t('codigo'),
      key: 'codigo',
      sortable: true,
    },
    {
      text: t('descripcion'),
      key: 'descripcion',
      sortable: true,
    },
    {
      text: 'Existencias',
      key: 'existencias',
      sortable: true,
    },
    {
      text: 'Golden',
      key: 'golden',
      sortable: true,
    }
  ];

  // Componente con modales
  return (
    <>
      {
        selectedRack !== 0 && data.length > 0
          ?
          <GeneralBox title='Detalle del Rack'>
            {
              loading
                ? <Load size={80} />
                : <TableData
                  columns={columns}
                  data={data}
                  actions={permissions}
                  deleteTitle='¿Esta seguro de eliminar al nivel?'
                  deleteText='La operación es irreversible'
                  deleteFunction={handleDeleteLevel}
                  editComponent={EditLevelModal}
                  customIcon="si si-eye"
                  customTextOverlay="Ver Productos"
                  onCustomAction={handleShowDetailLevel}
                />
            }
          </GeneralBox>
          : <></>
      }
    </>
  )
}

// Informacion de niveles
export const ElementAN = ({ permissions }) => {
  const { reloadComp3 } = useContext(ReloadContext);
  const { selectedLevel } = useContext(SelectItemContext)

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Escuchar cambio
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetDetailsByLevel(selectedLevel);
        const formatData = req.data.content.map((e) => ({
          ...e,
          id: Number(e.id_caja),
          cantidad: Number(e.cantidad_actual)
        }));
       setData([...formatData]);
       setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedLevel !== 0) {
      loadData();
    }
  }, [selectedLevel, setLoading, reloadComp3]);

  const columns = [
    {
      text: 'Producto',
      key: 'producto',
      sortable: true,
    },
    {
      text: 'Cod. Proveedor',
      key: 'codigo_proveedor',
      sortable: true,
    },
    {
      text: 'Caja',
      key: 'codigo_caja',
      sortable: true,
    },
    {
      text: 'Lote',
      key: 'lote',
      sortable: true,
    },
    {
      text: 'Tipo',
      key: 'tipo',
      sortable: true,
    },
    {
      text: 'Cantidad Actual',
      key: 'cantidad',
      sortable: true,
    }
  ];
 
  return (
    <>
      {
        selectedLevel !== 0 && data.length > 0
          ?
          <GeneralBox title="Detalle del Nivel">
            {
              loading
                ? <Load size={80} />
                : <TableData
                  isCountable={true}
                  columns={columns}
                  data={data}
                  actions={permissions}
                />
            }
          </GeneralBox>
          : <></>
      }
    </>
  );
}

// DataTable de productos
export const ElementB = ({ permissions }) => {
  const { reloadComp8: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const {t} = useTranslation();
  const [productsData, setProductsData] = useState([]);
  const [seleccionadoTemp, setSeleccionadoTemp] = useState();
  let seleccionado;
  let elemento;
  const { onSelectProduct } = useContext(SelectItemContext);
  // Titulos de las columnas
  const columns = [
    {
      text: t('nombre'),
      key: 'pr_nombre',
      sortable: true,
    },
    {
      text: 'Nombre Prod. Prov.',
      key: 'pr_nombre_prod_proveedor',
      sortable: true,
    },
    {
      text: 'Proveedor',
      key: 'proveedor',
      sortable: true,
    },
    {
      text: 'Codigo Prov.',
      key: 'codigo_proveedor',
      sortable: true,
    },
    {
      text: 'C. Mínima',
      key: 'pr_cantidad_minima',
      sortable: true,
      width: '150px'
    },
    {
      text: 'C. Máxima',
      key: 'pr_cantidad_maxima',
      sortable: true,
      width: '150px'
    },
    {
      text: 'Nivel de Riesgo',
      key: 'pr_nivel_riesgo',
      sortable: true,
      width: '150px'
    },
    {
      text: 'ERP',
      key: 'erp',
      sortable: true,
    },
  ];
  // Conexion con el BE para traer datos
  useEffect(() => {
    const getProducts = async () => {
      setProductsData([]);
      const req = await apiGetAllProducts();
      setProductsData([...req.data?.content.map(row => (
        {
          ...row,
          id: row.pr_id_producto,
          erp:
            row.erp_producto === null
              ? 'S/ERP'
              : row.erp_producto,
        }
      ))]);
    }
    getProducts();
    return (() => { setProductsData([]) })
  }, [setProductsData, reloadComp]);

  // Funcion de eliminar producto
  const handleDelete = async id => {
    try {
      await apiDeleteProduct(id);
      await Swal.fire({
        icon: 'success',
        title: 'El producto ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(8);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar el producto',
        text: error.response?.data?.msg ?? 'Revise las dependencias e intentelo de nuevo despues',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  // Funcion para ver detalle del producto
  const handleShowDetailProduct = id => {
    try{
        onSelectProduct(id);
        seleccionado=id;
        setSeleccionadoTemp(seleccionado);
        if (seleccionadoTemp) {
          elemento=document.getElementById(seleccionadoTemp)
          elemento.classList.remove('seleccionado')
          elemento.classList.add('btn-warning')
        }
        elemento=document.getElementById(seleccionado)
        elemento.classList.remove('btn-warning')
        elemento.classList.add('seleccionado')
    }catch(e){
        
        elemento=document.getElementById(seleccionado)
        elemento.classList.remove('btn-warning')
        elemento.classList.add('seleccionado')
    }
    
    
    
  }

  return (
    <GeneralBox title={t('todos_los_productos')}>
      <TableData
        mainTitle=''
        columns={columns}
        data={productsData}
        actions={permissions}
        deleteTitle='¿Esta seguro de eliminar al producto?'
        deleteText='La operación es irreversible'
        isCountable
        deleteFunction={handleDelete}
        editComponent={EditProductModal}
        onCustomAction={handleShowDetailProduct}
      />
    </GeneralBox>
  )
}

// Tabla de Familias
export const ElementC = ({ permissions }) => {
  const { reloadComp5, onReloadComponent } = useContext(ReloadContext);
  const idReloadComponent = 5;
  const {t} = useTranslation();
  const NAME_DEPENDENCY = 'familia';
  const [data, setData] = useState([]);
  // Titulos de las columnas
  const columns = [
    {
      text: t('nombre'),
      key: "glb_nombre",
      sortable: true,
    },
  ];
  // Conexion con el BE para traer datos
  useEffect(() => {
    const getData = async () => {
      const req = await apiGetDependcy(NAME_DEPENDENCY);
      setData([...req.data?.content.map(row => ({ id: row.glb_id_familia, ...row }))]);
    }
    getData();
    return (() => { setData([]) })
  }, [setData, reloadComp5]);

  // Funcion de eleminar producto
  const handleDelete = async id => {
    try {
      await apiDeleteDependcyId(id, NAME_DEPENDENCY);
      await Swal.fire({
        icon: 'success',
        title: 'La familia ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComponent);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar la familia',
        text: error.response?.data?.msg ?? 'Revise las dependencias e intentelo de nuevo despues',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  return (
    <GeneralBox size='6' title={t('familias')}>
      <TableData
        columns={columns}
        data={data}
        actions={permissions}
        deleteTitle='¿Esta seguro de eliminar la familia?'
        deleteText='La operación es irreversible'
        isCountable
        deleteFunction={handleDelete}
        editComponent={EditDependencyModal}
        editProps={{ mainTitle: t('editar')+" "+t('familia'), nameDependency: NAME_DEPENDENCY, idReloadComponent, }}
      />
    </GeneralBox>
  )
}

// Tabla de Tipos de Producto
export const ElementD = ({ permissions }) => {
  const { reloadComp6: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComponent = 6;
  const {t} = useTranslation();
  const NAME_DEPENDENCY = 'tipo_producto';
  const [data, setData] = useState([]);
  // Titulos de las columnas
  const columns = [
    {
      text: t('nombre'),
      key: 'glb_nombre',
      sortable: true,
    },
  ];
  // Conexion con el BE para traer datos
  useEffect(() => {
    const getData = async () => {
      const req = await apiGetDependcy(NAME_DEPENDENCY);
      setData([...req.data?.content.map(row => ({ id: row.glb_id_tipo_producto, ...row }))]);
    }
    getData();
    return (() => { setData([]) })
  }, [setData, reloadComp]);
  // Funcion de eleminar producto
  const handleDelete = async id => {
    try {
      await apiDeleteDependcyId(id, NAME_DEPENDENCY);
      await Swal.fire({
        icon: 'success',
        title: 'El tipo de producto ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      });
      // Recargar componente
      onReloadComponent(idReloadComponent);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar el tipo de producto',
        text: error.response?.data?.msg ?? 'Revise las dependencias e intentelo de nuevo despues',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  return (
    <GeneralBox size='6' title={t('tipos_de_producto')}>
      <TableData
        mainTitle=''
        columns={columns}
        data={data}
        actions={permissions}
        deleteTitle='¿Esta seguro de eliminar el tipo de producto?'
        deleteText='La operación es irreversible'
        isCountable
        deleteFunction={handleDelete}
        editComponent={EditDependencyModal}
        editProps={{ mainTitle: "Editar Tipo de Producto", nameDependency: NAME_DEPENDENCY, idReloadComponent }}
      />
    </GeneralBox>
  )
}

// Tabla de Clasificaciones
export const ElementE = ({ permissions }) => {
  const { reloadComp7: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComponent = 7;
  const {t} = useTranslation();
  const NAME_DEPENDENCY = 'clasificacion';
  const [data, setData] = useState([]);
  // Titulos de las columnas
  const columns = [
    {
      text: t('nombre'),
      key: 'glb_nombre',
      sortable: true,
    },
  ];
  // Conexion con el BE para traer datos
  useEffect(() => {
    const getData = async () => {
      const req = await apiGetDependcy(NAME_DEPENDENCY);
      setData([...req.data?.content.map(row => ({ id: row.glb_id_clasificacion, ...row }))]);
    }
    getData();
    return (() => { setData([]) })
  }, [setData, reloadComp]);

  // Funcion de eleminar producto
  const handleDelete = async id => {
    try {
      await apiDeleteDependcyId(id, NAME_DEPENDENCY);
      await Swal.fire({
        icon: 'success',
        title: 'La clasificación ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComponent);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar la clasificación',
        text: error.response?.data?.msg ?? 'Revise las dependencias e intentelo de nuevo despues',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  return (
    <GeneralBox size='6' title={t('clasificacion')}>
      <TableData
        mainTitle=''
        columns={columns}
        data={data}
        actions={permissions}
        deleteTitle='¿Esta seguro de eliminar la clasificación?'
        deleteText='La operación es irreversible'
        isCountable
        deleteFunction={handleDelete}
        editComponent={EditDependencyModal}
        editProps={{ mainTitle: "Editar Clasificación", nameDependency: NAME_DEPENDENCY, idReloadComponent }}
      />
    </GeneralBox>
  )
}

// Tabla de Toda Informacion proveedores
export const ElementF = ({ permissions }) => {
  const { reloadComp4: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  // Titulos de las columnas
  const columns = [
    {
      text: 'Nombre',
      key: 'nombre',
      sortable: true,
    },
    {
      text: 'Razón Social',
      key: 'nombre_razon_social',
      sortable: true,
    },
    {
      text: 'CFDI',
      key: 'cfdi',
      sortable: true,
    },
    {
      text: 'Factura Extranjera',
      key: 'pdf_invoice',
      sortable: true,
    },
  ];
  // Conexion con el BE para traer datos
  useEffect(() => {
    const getData = async () => {
      const req = await apiGetAllProviders();
      setData([...req.data?.content.map(row => ({
        ...row,
        id: row.id_proveedor,
        cfdi: row.cfdi === '1' ? 'Si' : 'No',
        pdf_invoice: row.pdf_invoice === '1' ? 'Si' : 'No',
      }))]);
    }
    getData();
    return (() => { setData([]) })
  }, [setData, reloadComp]);
  // Funcion de eleminar producto
  const handleDelete = async id => {
    try {
      await apiDeleteProvider(id);
      await Swal.fire({
        icon: 'success',
        title: 'El proveedor ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      });
      // Refrescar componente
      onReloadComponent(4);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar el proveedor',
        text: error.response?.data?.msg ?? 'Revise las dependencias e intentelo de nuevo despues',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  return (
    <GeneralBox title={t('todos_los_proveedores')}>
      <TableData
        mainTitle=''
        columns={columns}
        data={data}
        actions={permissions}
        deleteTitle='¿Esta seguro de eliminar al proveedor?'
        deleteText='La operación es irreversible'
        isCountable
        deleteFunction={handleDelete}
        editComponent={EditProviderModal}
      />
    </GeneralBox>
  )
}

// Tabla de Productos Cajas
export const ElementG = ({ permissions }) => {
  const { reloadComp9: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComp = 9;
  const {t} = useTranslation();
  const EntityName = 'cajas';
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedProduct } = useContext(SelectItemContext);
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetDetailsProduct(selectedProduct, EntityName);
        const formatData = req.data.content.map(el => {
          const mDate = moment(el.glb_fecha_inicio);
          const formatDate = mDate.format("YYYY-MM-DD");
          return {
            id: Number(el.glb_id_producto_caja),
            content: [
              el.glb_cantidad,
              formatDate,
            ]
          };
        });
        setData([...formatData]);
        const DivEmbalaje = document.querySelector("#embalaje");
        if(DivEmbalaje){
          DivEmbalaje.scrollIntoView({ block: 'start', behavior: "smooth"});
        }
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedProduct !== 0) {
      loadData();
    }
  }, [selectedProduct, setLoading, reloadComp])
  // Funcion para eliminar tupla
  const onDeleteFunction = async id => {
    try {
      await apiDeleteDetailProduct(id, EntityName);
      await Swal.fire({
        icon: 'success',
        title: 'El embalaje ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComp);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar embalaje',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  // Tabla con datos
  return (
    <>
      {
        selectedProduct !== 0 && data.length > 0
          ? <GeneralBox title={t('embalaje')} size="6">
            <div id='embalaje'>
              {
                loading
                  ? <Load size={80} />
                  : <TableSimple
                    headTitles={["Cantidad", "Fecha Inicio",]}
                    body={data}
                    actions={permissions}
                    deleteTitle="¿Esta seguro de eliminar el embalaje?"
                    deleteText="La operación es irreversible"
                    deleteFunction={onDeleteFunction}
                    editComponent={EditProdBox}
                  />
              }
          </div>
          </GeneralBox>
          : <></>
      }
    </>
  )
}
// Tabla de Productos Codigos
export const ElementH = ({ permissions }) => {
  const { reloadComp10: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComp = 10;

  const EntityName = 'codigos';
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedProduct } = useContext(SelectItemContext);
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetDetailsProduct(selectedProduct, EntityName);
        const formatData = req.data.content.map(el => {

          const mDate = moment(el.glb_fecha_inicio_codigo);
          const formatDate = mDate.format("YYYY-MM-DD");
          return {
            id: Number(el.glb_id_codigo_barra),
            content: [
              el.glb_codigo_barras_proveedor,
              formatDate,
            ]
          };
        });
        setData([...formatData]);
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedProduct !== 0) {
      loadData();
    }
  }, [selectedProduct, setLoading, reloadComp])
  // Funcion para eliminar tupla
  const onDeleteFunction = async id => {
    try {
      await apiDeleteDetailProduct(id, EntityName);
      await Swal.fire({
        icon: 'success',
        title: 'El código ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComp);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar codigo',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  // Tabla con datos
  return (
    <>
      {
        selectedProduct !== 0 && data.length > 0
          ? <GeneralBox title="Códigos" size="6">
            {
              loading
                ? <Load size={80} />
                : <TableSimple
                  headTitles={["Código", "Fecha Inicio",]}
                  body={data}
                  actions={permissions}
                  deleteTitle="¿Esta seguro de eliminar el código?"
                  deleteText="La operación es irreversible"
                  deleteFunction={onDeleteFunction}
                  editComponent={EditProdCode}
                />
            }
          </GeneralBox>
          : <></>
      }
    </>
  )
}
// Tabla de Productos Caracteristicas
export const ElementI = ({ permissions }) => {
  const { reloadComp11: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComp = 11;

  const EntityName = 'caracteristicas';
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedProduct } = useContext(SelectItemContext);
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetDetailsProduct(selectedProduct, EntityName);
        const formatData = req.data.content.map(el => {
          return {
            id: Number(el.glb_id_producto_caracteristica),
            content: [
              el.glb_nombre,
              el.glb_valor,
            ]
          };
        });
        setData([...formatData]);
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedProduct !== 0) {
      loadData();
    }
  }, [selectedProduct, setLoading, reloadComp])
  // Funcion para eliminar tupla
  const onDeleteFunction = async id => {
    try {
      await apiDeleteDetailProduct(id, EntityName);
      await Swal.fire({
        icon: 'success',
        title: 'La característica ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recarga de componente
      onReloadComponent(idReloadComp);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar la característica',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  // Tabla con datos
  return (
    <>
      {
        selectedProduct !== 0 && data.length > 0
          ? <GeneralBox title="Características" size="6">
            {
              loading
                ? <Load size={80} />
                : <TableSimple
                  headTitles={["Característica", "Valor",]}
                  body={data}
                  actions={permissions}
                  deleteTitle="¿Esta seguro de eliminar la característica?"
                  deleteText="La operación es irreversible"
                  deleteFunction={onDeleteFunction}
                  editComponent={EditProdFeat}
                />
            }
          </GeneralBox>
          : <></>
      }
    </>
  )
}
// Tabla de Productos Propiedades
export const ElementJ = ({ permissions }) => {
  const { reloadComp12: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComp = 12;
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedProduct } = useContext(SelectItemContext);
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetPropiertiesProduct(selectedProduct);
        const formatData = req.data.content.map(el => ({
          id: Number(el.id_producto_medida),
          content: [
            el.nombre,
            el.valor,
            el.unidad,
          ]
        }));
        setData([...formatData]);
        const DivPropiedades = document.querySelector("#propiedades");
        if(DivPropiedades){
          DivPropiedades.scrollIntoView({ block: 'start', behavior: "smooth"});
        }
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedProduct !== 0) {
      loadData();
    }
  }, [selectedProduct, setLoading, reloadComp])
  // Funcion para eliminar tupla
  const onDeleteFunction = async id => {
    try {
      await apiDeletePropiertyProduct(id);
      await Swal.fire({
        icon: 'success',
        title: 'La propiedad ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComp);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar propiedad',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  // Tabla con datos
  return (
    <>
      {
        selectedProduct !== 0 && data.length > 0
          ? <GeneralBox title={t('propiedades')} size="6">
            <div id="propiedades">
              {
                loading
                  ? <Load size={80} />
                  : <TableSimple
                    headTitles={["Propiedad", "Valor", "Unidad"]}
                    body={data}
                    actions={permissions}
                    deleteTitle="¿Esta seguro de eliminar la propiedad?"
                    deleteText="La operación es irreversible"
                    deleteFunction={onDeleteFunction}
                    editComponent={EditProdProperties}
                  />
              }
            </div>
          </GeneralBox>
          : <></>
      }
    </>
  )
}
// Galeria Productos Fotos
export const ElementK = ({ permissions }) => {
  const { reloadComp13: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComp = 13;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // Cambio de producto
  const { selectedProduct } = useContext(SelectItemContext);
  // Cargar fotos de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetPicturesProduct(selectedProduct);
        const formatData = req.data.content.map((e) => ({
          path: e.ruta,
          legend: e.nombre,
          id: e.id_foto
        }));
        setData([...formatData]);
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedProduct !== 0) {
      loadData();
    }
  }, [selectedProduct, setLoading, reloadComp]);
  // Funcion para eliminar tupla
  const onDeleteFunction = async id => {
    try {
      await apiDeletePictureProduct(id);
      await Swal.fire({
        icon: 'success',
        title: 'La fotografía ha sido eliminada exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComp);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar fotografía',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  return (
    <>
      {
        selectedProduct !== 0 && data.length > 0
          ? <GeneralBox size="6" title="Fotografías">
            {
              loading
                ? <Load size={80} />
                : <ImageSlider
                  items={data}
                  deleteTitle="¿Esta seguro que desea eliminar la fotografía?"
                  deleteText="La operación es irreversible"
                  deleteFunction={onDeleteFunction}
                  editComponent={EditProdPicture}
                />
            }
          </GeneralBox>
          : <></>
      }
    </>
  )
}
// Tabla de Productos Documentos
export const ElementL = ({ permissions }) => {
  const { reloadComp14: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComp = 14;
  const { selectedProduct } = useContext(SelectItemContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetGetDocsProduct(selectedProduct);
        const formatData = req.data.content.map(el => {
          return {
            id: Number(el.id_producto_ficha),
            content: [
              el.nombre,
              <OverlayTooltip
                placement="top"
                tooltip="Ver"
              >
                <a
                  className="btn btn-warning"
                  href={el.archivo}
                  target="__blank"
                >
                  <i className="si si-eye" />
                </a>
              </OverlayTooltip>
            ]
          };
        });
        setData([...formatData]);
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedProduct !== 0) {
      loadData();
    }
  }, [selectedProduct, setLoading, reloadComp])
  // Funcion para eliminar tupla
  const onDeleteFunction = async id => {
    try {
      await apiDeleteDocumentProduct(id);
      await Swal.fire({
        icon: 'success',
        title: 'El archivo ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recarga de componente
      onReloadComponent(idReloadComp);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar la archivo',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  // Tabla con datos
  return (
    <>
      {
        selectedProduct !== 0 && data.length > 0
          ? <GeneralBox title="Documentos" size="6">
            {
              loading
                ? <Load size={80} />
                : <TableSimple
                  headTitles={["Nombre", "Archivo",]}
                  body={data}
                  actions={permissions}
                  deleteTitle="¿Esta seguro de eliminar el documento?"
                  deleteText="La operación es irreversible"
                  deleteFunction={onDeleteFunction}
                  editComponent={EditProdDocument}
                />
            }
          </GeneralBox>
          : <></>
      }
    </>
  )
}

// Tabla Proveedores Nombres (Simple)
export const ElementM = ({ permissions }) => {
  const {
    onSelectProvider
  } = useContext(SelectItemContext);
  const [seleccionadoTemp, setSeleccionadoTemp] = useState();
  let seleccionado;
  let elemento;
  const [data, setData] = useState([]);
  const {t} = useTranslation();
  // Titulos de las columnas
  const columns = [
    {
      text: 'Nombre',
      key: 'nombre',
      sortable: true,
    },
  ];
  // Conexion con el BE para traer datos
  useEffect(() => {
    const getData = async () => {
      setData([]);
      const req = await apiGetAllProviders(1);
      setData([...req.data?.content.map(row => ({ id: row.id_proveedor, ...row }))]);
    }
    getData();
    return (() => { setData([]) })
  }, [setData]);

  const onSeeDetails = (id) => {
    try{
      onSelectProvider(id);
      seleccionado=id;
      setSeleccionadoTemp(seleccionado);
      if (seleccionadoTemp) {
        elemento=document.getElementById(seleccionadoTemp)
        elemento.classList.remove('seleccionado')
        elemento.classList.add('btn-warning')
      }
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }catch(e){
      
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }
  }

  return (
    <GeneralBox
      size="4"
      title={t('todos_los_proveedores')}
    >
      <TableData
        mainTitle=''
        columns={columns}
        data={data}
        isCountable={true}
        actions={permissions}
        customTextOverlay="Ver Detalle"
        customIcon="si si-eye"
        onCustomAction={onSeeDetails}
      />
    </GeneralBox>
  )
}

// Tabla de Listas precios de proveedor
export const ElementN = ({ permissions }) => {
  const { reloadComp17: reloadComp } = useContext(ReloadContext);
  const { onSelectListPrice, selectedProvider } = useContext(SelectItemContext);
  const [seleccionadoTemp, setSeleccionadoTemp] = useState();
  let seleccionado;
  let elemento;
  const { t }=useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        setData([]);
        const req = await apiGetListPricesProv(selectedProvider);
        const formatData = req.data.content.map(e => {
          const date1 = moment(e.fecha_recepcion);
          const date1_format = date1.format("YYYY-MM-DD");
          const date2 = moment(e.fecha_validez);
          const date2_format = date2.format("YYYY-MM-DD");
          // Objeto
          return {
            id: Number(e.id_lista_precio),
            date1_format,
            date2_format,
            documento: e.archivo
              ? (
                <OverlayTooltip
                  placement="top"
                  tooltip="Descargar"
                >
                  <a href={e.archivo} className="btn btn-info" target="_blank" rel="noreferrer" download={true}>
                    <i className="si si-cloud-download" />
                  </a>
                </OverlayTooltip>
              ) : 'No'
          }
        });
        setData([...formatData]);
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedProvider !== 0) {
      loadData();
    }
  }, [selectedProvider, setLoading, reloadComp]);

  const columns = [
    {
      text: 'Fe. Recepción',
      key: 'date1_format',
      sortable: true,
    },
    {
      text: 'Fe. Validez',
      key: 'date2_format',
      sortable: true,
    },
    {
      text: 'Doc.',
      key: 'documento',
      sortable: true,
    },
  ];

  // Ver Productos asociados
  const onSeeDetails = id => {
    try{
      onSelectListPrice(id);
      seleccionado=id;
      setSeleccionadoTemp(seleccionado);
      if (seleccionadoTemp) {
        elemento=document.getElementById(seleccionadoTemp)
        elemento.classList.remove('seleccionado')
        elemento.classList.add('btn-warning')
      }
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }catch(e){
      
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }
  }

  return (
    <>
      {
        selectedProvider !== 0 && data.length > 0
          ?
          <GeneralBox
            size="8"
            title={t('listas_precio')}
          >
            {
              loading
                ? <Load size={80} />
                : <TableData
                  isCountable={true}
                  columns={columns}
                  data={data}
                  actions={permissions}
                  customTextOverlay="Ver Productos"
                  customIcon="si si-eye"
                  onCustomAction={onSeeDetails}
                />
            }
          </GeneralBox>
          : <></>
      }
    </>
  )
}

// Tabla de Productos de una Lista Precio
export const ElementO = () => {
  const { selectedListPrices } = useContext(SelectItemContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetAllProductsByListPrice(selectedListPrices);
        const formatData = req.data.content.map(e => ({
          ...e,
          id: Number(e.id_producto_lista_precios),
          precio: formatCurrency(e.precio_x_caja)
        }));
        setData([...formatData]);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        setLoading(false);
      }
    }
    if (selectedListPrices !== 0) {
      loadData();
    }
  }, [selectedListPrices]);

  const columns = [
    {
      text: 'Producto',
      key: 'producto',
      sortable: true,
    },
    {
      text: 'Precio de Embalaje',
      key: 'precio',
      sortable: true,
    },
    {
      text: 'Divisa',
      key: 'moneda_codigo_sat',
      sortable: true,
    },
  ];

  return (
    <>
      {
        selectedListPrices !== 0 && data.length > 0
          ? <GeneralBox
            size="6"
            title="Productos de Lista"
          >
            {
              loading
                ? <Load size={80} />
                : <TableData
                  isCountable={true}
                  columns={columns}
                  data={data}
                />
            }
          </GeneralBox>
          : <></>
      }
    </>
  )
}

// Tabla de Catalogos de Inventarios
export const ElementP = ({ permissions }) => {
  const { reloadComp18: reloadComp } = useContext(ReloadContext);
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  // Titulos de las columnas
  const columns = [
    {
      key: "glb_nombre",
      text: 'Nombre',
      sortable: true,
    },
    {
      key: "req_doc",
      text: '¿Requiere Documento?',
      sortable: true,
    },
  ];
  // Conexion con el BE para traer datos
  useEffect(() => {
    const getData = async () => {
      const req = await apiGetAllMovsReasons();
      setData([...req.data?.content.map(row => (
        {
          ...row,
          id: row.glb_id_razon_movimiento,
          req_doc: (row.glb_requiere_documento === '1') ? 'Si' : 'No',
        }
      ))]);
    }
    getData();
    return (() => { setData([]) })
  }, [setData, reloadComp]);

  return (
    <GeneralBox size="8" title={t('razones')}>
      <TableData
        isCountable
        columns={columns}
        data={data}
      />
    </GeneralBox>
  )
}

// Tabla Proveedores Documentos
export const ElementAJ = ({ permissions }) => {
  const {
    onSelectProviderCosts
  } = useContext(SelectItemContext);
  const [seleccionadoTemp, setSeleccionadoTemp] = useState();
  let seleccionado;
  let elemento;
  const [data, setData] = useState([]);
  const {t} = useTranslation();
  // Titulos de las columnas
  const columns = [
    {
      text: 'Nombre',
      key: 'nombre',
      sortable: true,
    },
  ];
  // Conexion con el BE para traer datos
  useEffect(() => {
    const getData = async () => {
      const req = await apiGetAllProviders(1);
      setData([...req.data?.content.map(row => ({ id: row.id_proveedor, ...row }))]);
    }
    getData();
    return (() => { setData([]) })
  }, [setData]);

  const onSeeDetails = (id) => {
    try{
      onSelectProviderCosts(id);
      seleccionado=id;
      setSeleccionadoTemp(seleccionado);
      if (seleccionadoTemp) {
        elemento=document.getElementById(seleccionadoTemp)
        elemento.classList.remove('seleccionado')
        elemento.classList.add('btn-warning')
      }
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }catch(e){
      
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }
  }

  return (
    <GeneralBox
      size="4"
      title={t('todos_los_proveedores')}
    >
      <TableData
        mainTitle=''
        columns={columns}
        data={data}
        isCountable={true}
        actions={permissions}
        customTextOverlay="Ver Detalle"
        customIcon="si si-eye"
        onCustomAction={onSeeDetails}
      />
    </GeneralBox>
  )
}

// Tabla de Facturas (Documentos Costo)
export const ElementQ = ({ permissions }) => {
  const { reloadComp19: reloadComp } = useContext(ReloadContext);
  const { onSelectBillProds, selectedProviderCosts } = useContext(SelectItemContext);
  const [seleccionadoTemp, setSeleccionadoTemp] = useState();
  let seleccionado;
  let elemento;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setData([]);
      try {
        const req = await apiGetBillsByProv(selectedProviderCosts);
        console.log(req.data.content)
        const formatData = req.data.content.map(e => {
          const date1 = moment(e.glb_fecha_carga);
          const date1_format = date1.format("YYYY-MM-DD");
          const date2 = moment(e.glb_fecha_documento);
          const date2_format = date2.format("YYYY-MM-DD");
          // Objeto
          return {
            ...e,
            id: Number(e.glb_id_doc_prod_costo),
            fecha_carga: date1_format,
            fecha_recepcion: date2_format,
            documento: (
              <OverlayTooltip
                placement="top"
                tooltip="Descargar"
              >
                <a href={e.glb_archivo} className="btn btn-info" target="_blank" rel="noreferrer" download={true}>
                  <i className="si si-cloud-download" />
                </a>
              </OverlayTooltip>
            )
          };
        });
        setData([...formatData]);
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedProviderCosts !== 0) {
      loadData();
    }
  }, [selectedProviderCosts, setLoading, reloadComp]);

  const columns = [
    {
      text: 'Referencia',
      key: 'glb_referencia',
      sortable: true,
    },
    {
      text: 'Carga',
      key: 'fecha_carga',
      sortable: true,
    },
    {
      text: 'Recepción',
      key: 'fecha_recepcion',
      sortable: true,
    },
    {
      text: 'Moneda',
      key: 'glb_moneda_codigo_sat',
      sortable: true,
    },
    {
      text: 'Documento',
      key: 'documento',
      sortable: true,
    },
  ];

  // Ver Productos asociados
  const onSeeDetails = id => {
    try{
      onSelectBillProds(id);
      seleccionado=id;
      setSeleccionadoTemp(seleccionado);
      if (seleccionadoTemp) {
        elemento=document.getElementById(seleccionadoTemp)
        elemento.classList.remove('seleccionado')
        elemento.classList.add('btn-warning')
      }
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }catch(e){
      
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }
    
  }

  return (
    <>
      {
        selectedProviderCosts !== 0 && data.length > 0
          ? <GeneralBox
            size="8"
            title="Documentos Costos"
          >
            {
              loading
                ? <Load size={80} />
                : <TableData
                  isCountable={true}
                  columns={columns}
                  data={data}
                  actions={permissions}
                  customTextOverlay="Ver Productos"
                  customIcon="si si-eye"
                  onCustomAction={onSeeDetails}
                />
            }
          </GeneralBox>
          : <></>
      }
    </>
  )
}

// Tabla de Facturas (Productos de Documentos costo)
export const ElementR = ({ permissions }) => {
  const { selectedBillProds } = useContext(SelectItemContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetAllProdsByBill(selectedBillProds);
        const formatData = req.data.content.map(e => ({
          ...e,
          id: Number(e.costo_id_producto_costo),
          precio_unitario: formatCurrency(e.costo_precio_producto_unitario),
          precio_caja: formatCurrency(e.costo_precio_caja),
          precio_total: formatCurrency(e.costo_precio_producto_total),
          observacion: (e.costo_observacion === 'DIF_COSTO_MENOR') ?
            <p className="btn btn-success mb-0">
              <i className="si si-like me-10" />
              Costo Menor
            </p>
            : (e.costo_observacion === 'DIF_COSTO_MAYOR') ?
              <p className="btn btn-danger mb-0">
                <i className="si si-dislike me-10" />
                Costo Mayor
              </p>
              :
              "N/A"
        }));
        setData([...formatData]);
        const DivProductoCosto = document.querySelector("#producto_costo");
        if(DivProductoCosto){
          DivProductoCosto.scrollIntoView({ block: 'start', behavior: "smooth"});
        }
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedBillProds !== 0) {
      loadData();
    }
  }, [selectedBillProds]);

  const columns = [
    {
      text: 'Producto',
      key: 'nombre',
      sortable: true,
    },
    {
      text: 'P. Unitario',
      key: 'precio_unitario',
      sortable: true,
      width: "150px"
    },
    {
      text: 'P. Embalaje',
      key: 'precio_caja',
      sortable: true,
      width: "130px"
    },
    {
      text: '# Embalaje',
      key: 'costo_cantidad_cajas',
      sortable: true,
      width: "120px"
    },
    {
      text: 'Precio Total',
      key: 'precio_total',
      sortable: true,
    },
    {
      text: 'Lote',
      key: 'costo_lote',
      sortable: true,
      width: "100px"
    },
    {
      text: 'Observación',
      key: 'observacion',
      sortable: true,
    },
  ];

  return (
    <>
      {
        selectedBillProds !== 0 && data.length > 0
          ? <GeneralBox
            size="12"
            title="Productos de Documento Costo"
          >
            <div id='producto_costo'>
              {
                loading
                  ? <Load size={80} />
                  : <TableData
                    isCountable={true}
                    columns={columns}
                    data={data}
                  />
              }
            </div>
          </GeneralBox>
          : <></>
      }
    </>
  )
}

// Tabla de Productos - Razones de Almacenamiento
export const ElementS = ({ permissions }) => {
  const {t} = useTranslation();
  const { reloadComp20: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComp = 20;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedProduct } = useContext(SelectItemContext);
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetStorageRulesProduct(selectedProduct);
        const formatData = req.data.content.map(el => ({
          id: Number(el.r_id_prod_regla_almacenar),
          content: [
            el.r_razon_ubicacion,
            el.nivel,
            el.codigo,
            el.r_prioridad,
          ]
        }));
        setData([...formatData]);
        const DivRazones = document.querySelector("#razones");
        if(DivRazones){
          DivRazones.scrollIntoView({ block: 'start', behavior: "smooth"});
        }
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedProduct !== 0) {
      loadData();
    }
  }, [selectedProduct, setLoading, reloadComp])
  // Funcion para eliminar tupla
  const onDeleteFunction = async id => {
    try {
      await apiDeleteStorageRuleProduct(id);
      await Swal.fire({
        icon: 'success',
        title: 'La razon ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComp);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar razon',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  // Tabla con datos
  return (
    <>
      {
        selectedProduct !== 0 && data.length > 0
          ? <GeneralBox title={t('razon')+" "+t('almacenamiento')} size="6">
            <div id='razones'>
              {
                loading
                  ? <Load size={80} />
                  : <TableSimple
                    headTitles={["Razón", "Nivel", "Codigo", "Prioridad"]}
                    body={data}
                    actions={permissions}
                    deleteTitle="¿Esta seguro de eliminar la razón?"
                    deleteText="La operación es irreversible"
                    deleteFunction={onDeleteFunction}
                  // TODO
                  // editComponent={} 
                  />
              }
            </div>
          </GeneralBox>
          : <></>
      }
    </>
  )
}

// Tabla de Puestos
export const ElementT = ({ permissions }) => {
  const { reloadComp21: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComp = 21;
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Titulos de las columnas
  const columns = [
    {
      text: t('nombre'),
      key: 'rol',
      sortable: true,
    },
  ];
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const req = await apiGetRoles();
        const formatData = req.data.content.map(el => ({
          ...el,
          id: Number(el.id_rol),
        }));
        setData([...formatData]);
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    loadData();
    return () => {
      setData([]);
    }
  }, [setLoading, reloadComp])
  // Funcion para eliminar tupla
  const onDeleteFunction = async id => {
    try {
      await apiDeleteRole(id); // modificar por el de borrar Roles
      await Swal.fire({
        icon: 'success',
        title: 'El puesto ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComp);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar puesto',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  // Tabla con datos
  return (
    <GeneralBox title={t('roles')} size="5">
      {
        loading
          ? <Load size={80} />
          : <TableData
            mainTitle=''
            isCountable
            columns={columns}
            data={data}
            actions={permissions}
            deleteTitle='¿Esta seguro de eliminar el puesto?'
            deleteText='La operación es irreversible'
            deleteFunction={onDeleteFunction}
            editComponent={EditRol}
          />
      }
    </GeneralBox>
  )
}
// Tabla de Personal
export const ElementU = ({ permissions }) => {
  const { reloadComp22: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComp = 22;
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // Titulos de las columnas
  const columns = [
    {
      text: t('nombre'),
      key: 'nombre',
      sortable: true,
    },
    {
      text: 'Rol',
      key: 'puesto',
      sortable: true,
    },
    {
      text: t('responsable'),
      key: 'responsable',
      sortable: true,
    },
  ];
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const req = await apiGetAllStaff();
        const formatData = req.data.content.map(el => ({
          ...el,
          id: Number(el.id_personal),
          responsable: el.responsable ?? "N/A"
        }));
        setData([...formatData]);
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    loadData();
    return () => {
      setData([]);
    }
  }, [setLoading, reloadComp])
  // Funcion para eliminar tupla
  const onDeleteFunction = async id => {
    try {
      await apiDeleteStaffId(id);
      await Swal.fire({
        icon: 'success',
        title: 'El personal ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComp);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar personal',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  // Tabla con datos
  return (
    <GeneralBox title={t('personal')} size="7">
      {
        loading
          ? <Load size={80} />
          : <TableData
            mainTitle=''
            isCountable
            columns={columns}
            data={data}
            actions={permissions}
            deleteTitle='¿Esta seguro de eliminar el personal?'
            deleteText='La operación es irreversible'
            deleteFunction={onDeleteFunction}
            editComponent={EditStaff}
          />
      }
    </GeneralBox>
  )
}

// Data Table Facturas por recibir (pendientes)
export const ElementV = ({ permissions }) => {
  const { reloadComp23: reloadComp } = useContext(ReloadContext);
  const { onSelectProcessingBill } = useContext(SelectItemContext);
  const [seleccionadoTemp, setSeleccionadoTemp] = useState();
  const {t} = useTranslation();
  let seleccionado;
  let elemento;
  // Hooks datos a mostrar
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Cargar datos
  useEffect(() => {

    // Llamada a la api
    const loadData = async () => {
      setLoading(true);
      try {
        setData([]);
        const req = await apiGetPendingDocs();
        const formatData = req.data.content.map(e => ({
          ...e,
          id: Number(e.id_doc_prod_costo),
          fecha_carga: moment(e.fecha_carga).format("YYYY-MM-DD"),
          fecha_doc: moment(e.fecha_documento).format("YYYY-MM-DD"),
          total: formatCurrency(e.total)
        }));
        setData([...formatData]);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        setLoading(false);
      }
    }
    loadData()
  }, [reloadComp]);

  const columns = [
    {
      text: 'Proveedor',
      key: 'proveedor',
      sortable: true,
    },
    {
      text: 'Referencia',
      key: 'referencia',
      sortable: true,
    },
    {
      text: 'Moneda',
      key: 'moneda_codigo_sat',
      sortable: true,
    },
    {
      text: 'Total',
      key: 'total',
      sortable: true,
    },
    {
      text: 'F. Doc.',
      key: 'fecha_doc',
      sortable: true,
      width: "130px"
    },
    {
      text: 'F. Carga',
      key: 'fecha_carga',
      sortable: true,
      width: "130px"
    },
  ];
  // Ver Productos asociados
  const onSeeDetails = id => {
    try{
      onSelectProcessingBill(id);
      seleccionado=id;
      setSeleccionadoTemp(seleccionado);
      if (seleccionadoTemp) {
        elemento=document.getElementById(seleccionadoTemp)
        elemento.classList.remove('seleccionado')
        elemento.classList.add('btn-warning')
      }
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }catch(e){
      
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }
  }
  // Des seleccionar factura
  const handleSelectAll = () => {
    onSelectProcessingBill(0);
  }
  return (
    
    <GeneralBox
      size="9"
      title={t('facturas_por_recibir')}
    >
      {
        loading
          ? <Load size={80} />
          : <>
            <Row>
              <Col xs className="mb-20">
                <Button
                  variant="info"
                  onClick={handleSelectAll}
                >
                  <i className="si si-eye me-5" /> {t('ver_todos_los_productos')}
                </Button>
              </Col>
            </Row>
            <TableData
              isCountable={true}
              columns={columns}
              data={data}
              actions={permissions}
              customTextOverlay="Ver Productos"
              customIcon="si si-eye"
              onCustomAction={onSeeDetails}
            />
          </>
      }
    </GeneralBox>
  )
}

// Data Table Productos por recibir (Pendientes)
export const ElementW = ({ permissions }) => {
  const { selectedProcessingBill } = useContext(SelectItemContext);
  const { reloadComp27: reloadComp } = useContext(ReloadContext);

  // Hooks datos a mostrar
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("0");
  const {t} = useTranslation();
  // Cargar datos
  useEffect(() => {
    // Funcion para procesar productos
    const showModalProcess = async (id) => {
      setSelectedProduct(id);
      setShowModal(true);
    }
    // Cargar datos
    const loadData = async () => {
      setLoading(true);
      try {
        setData([]);
        let reqData;
        if (selectedProcessingBill !== 0) {
          // Cargar datos de factura seleccionada
          reqData = await apiGetProcessingProds(selectedProcessingBill);
        }
        else {
          // Cargar todos los productos pendientes
          reqData = await apiGetAllProcessingProds();
        }
        const formatData = reqData.data.content.map((e, index) => ({
          ...e,
          id: index,
          p_caja: formatCurrency(e.precio_caja),
          p_uni: formatCurrency(e.precio_producto_unitario),
          processing: (
            <Button
              onClick={() => { showModalProcess(e.id_producto_costo) }}
              variant="danger"
              className='waves-effect waves-light'
            >
              {t('recibir')}
            </Button>
          )
        }));
        setData([...formatData]);
        const DivProductos = document.querySelector("#productos");
        if(DivProductos){
          DivProductos.scrollIntoView({ block: 'start', behavior: "smooth"});
        }
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    loadData();
  }, [selectedProcessingBill, reloadComp, t])

  const columns = [
    {
      text: 'Producto',
      key: 'producto',
      sortable: true,
    },
    {
      text: 'Referencia',
      key: 'referencia',
      sortable: true,
    },
    {
      text: 'Lote',
      key: 'lote',
      sortable: true,
      width: "130px"
    },
    {
      text: '# Embalaje',
      key: 'cantidad_cajas',
      sortable: true,
    },
    {
      text: '# Placas',
      key: 'cantidad_placas',
      sortable: true,
    },
    {
      text: 'P. Embalaje',
      key: 'p_caja',
      sortable: true,
    },
    {
      text: 'P. Unitario',
      key: 'p_uni',
      sortable: true,
    },
    {
      text: '¿Recibido?',
      key: 'processing',
      sortable: true,
    },
  ];
  return (
    <>
      <GeneralBox
        size="12"
        title={t('productos_por_recibir')}
      >
        <div id='productos'>
          {
            loading
              ? <Load size={80} />
              : <TableData
                isCountable={true}
                columns={columns}
                data={data}
              />
          }
        </div>
      </GeneralBox>
      <ProcessingEntranceProductsModal
        setShow={setShowModal}
        show={showModal}
        idProd={selectedProduct}
      />
    </>
  )
}

// Tabla de Productos entrantes
export const ElementX = ({ permissions }) => {
  const { reloadComp24: reloadComp } = useContext(ReloadContext);

  const [dataIncoming, setDataIncoming] = useState([]);
  const [dataStocked, setDataStocked] = useState([]);
  const [loadingIncoming, setLoadingIncoming] = useState(true);
  const [loadingStocked, setLoadingStocked] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedIdEntrance, setSelectedIdEntrance] = useState("0");
  const {t} = useTranslation();
  // Escuchar cambio de producto
  useEffect(() => {
    // Funcion para agregar productos
    const showModalEntrance = (id) => {
      setSelectedIdEntrance(id);
      setShowModal(true);
    }
    // Cargar datos
    const loadIncoming = async () => {
      try {
        setLoadingIncoming(true);
        const req = await apiGetAllEntries();
        const formatDataIncoming = req.data.content.map(e => ({
          ...e,
          id: Number(e.id_entrada),
          fe_registro: moment(e.fecha_registro).format("YYYY-MM-DD"),
          add_entrance:
            <Button
              onClick={() => { showModalEntrance(e.id_entrada) }}
              variant="danger"
              className='waves-effect waves-light'
            >
              {t('almacenado')}
            </Button>
        }));
        setDataIncoming([...formatDataIncoming]);
        setLoadingIncoming(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    const loadStocked = async () => {
      try {
        setLoadingStocked(true);
        const req = await apiGetAllProductsStocked();
        const formatDataStocked = req.data.content.map(e => ({
          ...e,
          id: Number(e.id_entrada),
          orden_compra:
            e.numero_orden_compra !== null
              ? e.numero_orden_compra
              : 'S/O',
          cantidad: Number(e.cantidad_actual),
          fecha: moment(e.fecha_movimiento).format('YYYY-MM-DD')
        }));
        setDataStocked([...formatDataStocked]);
        setLoadingStocked(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    loadIncoming();
    loadStocked();
    return () => {
      setDataIncoming([]);
      setDataStocked([]);
    };
  }, [reloadComp, t ]);

  // Titulos de las columnas
  const columnsIncoming = [
    {
      text: 'Fecha Registro',
      key: 'fe_registro',
      sortable: true,
    },
    {
      text: 'Producto',
      key: 'producto',
      sortable: true,
    },
    {
      text: 'Codigo Embalaje',
      key: 'codigo_caja',
      sortable: true,
    },
    {
      text: 'Lote',
      key: 'lote',
      sortable: true,
    },
    {
      text: 'Cantidad',
      key: 'cantidad',
      sortable: true,
    },
    {
      text: 'Recibio',
      key: 'personal',
      sortable: true,
    },
    {
      text: 'Almacenar',
      key: 'add_entrance',
      sortable: true,
    },
  ];
  // Titulos de las columnas
  const columnsStocked = [
    {
      text: 'Producto',
      key: 'producto',
      sortable: true,
    },
    {
      text: 'Codigo Embalaje',
      key: 'codigo_caja',
      sortable: true,
    },
    {
      text: 'Ubicación',
      key: 'almacen_ubicacion',
      sortable: true,
    },
    {
      text: 'Orden Compra',
      key: 'orden_compra',
      sortable: true,
    },
    {
      text: 'Cantidad',
      key: 'cantidad',
      sortable: true,
    },
    {
      text: 'Personal',
      key: 'personal',
      sortable: true,
    },
    {
      text: 'Fecha',
      key: 'fecha',
      sortable: true,
    },
    {
      text: 'Razón',
      key: 'razon_movimiento',
      sortable: true,
    },
  ];

  // Tabla con datos
  return (
    <>
      <GeneralBox title={t('productos_entrantes')} size="12">
        <>
          {
            loadingIncoming
              ? <Load size={80} />
              : <TableData
                isCountable
                columns={columnsIncoming}
                data={dataIncoming}
              />
          }
          <AddEntranceProductModal
            show={showModal}
            setShow={setShowModal}
            id={selectedIdEntrance}
          />
        </>
      </GeneralBox>
      <GeneralBox title={t('productos_almacenados')} size="12">
        {
          loadingStocked
            ? <Load size={80} />
            : <TableData
              isCountable
              columns={columnsStocked}
              data={dataStocked}
            />
        }
      </GeneralBox>
    </>
  )
}

// Contenedor para salidas (Distribucion de almacen)
/*export const ElementY = ({ permissions }) => {
  const { reloadComp25: reloadComponent } = useContext(ReloadContext);

  const {
    onSelectStore,
    selectedStore,
    onSelectRack,
    selectedRack: globalSelectedRack,
    onSelectLevel,
  } = useContext(SelectItemContext)

  const [info, setInfo] = useState({});
  const [selectRack, setSelectRack] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  // Escuchar cambios en contenedor de almacenes
  useEffect(() => {
    const index = Object.keys(info).findIndex((key) => (info[key].id === selectedStore));
    setActiveTab(index);
  }, [selectedStore, info, setActiveTab]);

  // Escuchar cambio global en el rack
  useEffect(() => {
    setSelectRack(globalSelectedRack);
  }, [globalSelectedRack, setSelectRack]);

  // Cargar informacion
  useEffect(() => {
    const getInfo = async () => {
      // -----------------------------------------------------
      // Generar componente de tabla de rack
      let SubComp2 = <></>;
      if (selectRack !== 0) {
        // Soliciutd de informacion de niveles
        const reqRack = await apiGetRackId(selectRack);
        const reqLevels = await apiGetLevelsWithRack(selectRack);
        // Orden de columnas
        const auxOrder = ["nivel", "nombre", "codigo_ubicacion", "descripcion", "cantidad"];
        // Generar cuerpo de la tabla
        const tbBody = reqLevels.data.content.map(row => ({
          id: Number(row.id_almacen_ubicacion),
          content: auxOrder.map((item) => (row[item]))
        }));
        // Componente de tabla
        SubComp2 = <TableSimple
          mainTitle={`Detalle del Rack: ${reqRack.data.content.codigo_rack}`}
          headTitles={["Nivel", "Nombre", "Código", "Descripción", "Existencias"]}
          body={tbBody}
          actions={permissions}
          customIcon="si si-action-redo"
          customTextOverlay="Salida"
          onCustomAction={(id) => {
            onSelectLevel(id);
          }}
        />
      }
      // -----------------------------------------------------
      // Componente de Tabs
      const reqStores = await apiGetStores();
      const tabsInfo = {};
      await Promise.all(reqStores.data.content.map(async (store, jndex) => {
        // Solicitar racks de cada almacen
        const reqRack = await apiGetRacksWithExistance(store.glb_id_almacen);
        const rackInfo = reqRack.data.content;
        const auxFormatRacks = Object.keys(rackInfo).map(keyRow => {
          const auxObjCol = {}
          Object.keys(rackInfo[keyRow]).forEach(keyCol => {
            auxObjCol[keyCol] = {
              id: rackInfo[keyRow][keyCol].id_rack,
              quantity: rackInfo[keyRow][keyCol].cantidad,
              name: rackInfo[keyRow][keyCol].nombre,
            }
          })
          return auxObjCol;
        });
        // Solicitar codigo qr
        const getQrCodes = async idRack => {
          try {
            const reqQr = await apiGetQRRack(idRack);
            window.open(reqQr.data.content.url, '_blank');
          } catch (e) {
            console.log(`Error al generar qr:\n${e}`)
            Swal.fire({
              icon: 'error',
              title: 'Error al generar QR',
              text: e.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
              confirmButtonText: 'Aceptar',
            });
          }
        }
        // Generar componente de cuadricula de racks
        const SubComp1 = <ViewBoxes
          title="Vista de Racks"
          arrayBoxes={[...auxFormatRacks ?? []]}
          detail2Text="Niveles"
          onBoxClick={(id) => {
            setSelectRack(id);
            // eslint-disable-next-line
            onSelectRack(id);
          }}
          onClickQr={getQrCodes}
        />
        // -------------------------------------------------------
        // Guardar objeto con titulo y contenido
        tabsInfo[jndex] = {
          id: store.glb_id_almacen,
          // Titulo del tab
          title: store.glb_nombre,
          // Contenido
          content:
            <>
              {SubComp1}
              {SubComp2}
            </>
        };
      }));
      // Guardar informacion de tabs
      setInfo({ ...tabsInfo });
      // Seleccionar primer almacen
    };
    getInfo();
    return () => {
      setInfo({});
    }
    // eslint-disable-next-line 
  }, [
    setInfo,
    setSelectRack,
    selectRack,
    reloadComponent,
  ]);

  // Componente con modales
  return (
    <>
      <GeneralBox>
        <HorizontalTabs
          activeTab={activeTab}
          onInitilize={onSelectStore}
          tabsTitles={Object.keys(info).map(key => ({
            content: info[key].title,
            id: info[key].id
          }))}
          tabsContent={Object.keys(info).map(key => info[key].content)}
          onChangeTab={(idStore) => {
            onSelectStore(idStore);
            setSelectRack(0);
          }}
        />
      </GeneralBox>
    </>
  );
}*/

// Contenedor registro de salida de productos
export const ElementZ = ({ permissions }) => {
  const { reloadComp26: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComp = 26;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  // Escuchar cambio de producto
  useEffect(() => {
    // Cargar datos
    const loadData = async () => {
      try {
        setData([]);
        setLoading(true);
        // NEXT FEAT: Setear pagina
        const req = await apiGetAllExitsPerPage();
        setData([...req.data.content.map(e => ({
          ...e,
          id: Number(e.id_salida),
          fecha: moment(e.fecha_movimiento).format("YYYY-MM-DD"),
          editado_por: e.editado_por ? e.editado_por : 'Sin editar'
        }))]);   
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        return setLoading(false);
      }
    }
    loadData();
  }, [ reloadComp ]);

  const onDeleteFunction = async id => {
    try {
      await apiDeleteExit(id);
      await Swal.fire({
        icon: 'success',
        title: 'La salida ha sido borrada exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComp)
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar la salida',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  const columns = [
    {
      text: 'Salida #',
      key: 'id_salida',
      sortable: true,
    },
    {
      text: 'Tipo de Movimiento',
      key: 'tipo_salida',
      sortable: true,
    },
    {
      text: 'Producto',
      key: 'nombre',
      sortable: true,
    },
    {
      text: 'Cantidad',
      key: 'cantidad',
      sortable: true,
    },
    {
      text: 'Personal',
      key: 'personal',
      sortable: true,
    },
    {
      text: 'Fecha',
      key: 'fecha',
      sortable: true,
    },
    {
      text: 'Editado por',
      key: 'editado_por'
    }
  ];

  // Tabla con datos
  return (
    <>
      <GeneralBox title={t('registro_de_salida_de_productos')} size="12">
        {
          loading
            ? <Load size={80} />
            : <TableData
              columns={columns}
              data={data}
              actions={permissions}
              deleteTitle='¿Esta seguro de eliminar la salida?'
              deleteText='La operación es irreversible'
              deleteFunction={onDeleteFunction}
              editComponent={EditExitModal}
            />
        }
      </GeneralBox>
    </>
  )
}

// Tabla Detalles de salidas
export const ElementY = ({ permissions }) => {
  const { reloadComp25: reloadComp } = useContext(ReloadContext);
  const { onSelectExitDetails } = useContext(SelectItemContext);
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [seleccionadoTemp, setSeleccionadoTemp] = useState();
  let seleccionado;
  let elemento;
  // Escuchar cambio
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setData([]);
      try {
        const req = await apiGetExitDetailsById();
        setData([...req.data.content.map(e => ({
          ...e,
          id: Number(e.det_id_det_salida),
          movimiento: e.movimiento,
          det_orden_trabajo: e.det_orden_trabajo ? e.det_orden_trabajo : 'S/ORD',
          det_cliente: e.det_nombre_cliente ? e.det_nombre_cliente : 'S/CLIENTE',
          det_cantidad: e.det_cantidad ? e.det_cantidad : 'S/C'
        }))]);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        return setLoading(false);
      }
    }
    loadData();
  }, [ reloadComp ]);

  const columns = [
    {
      text: 'Salida #',
      key: 'det_id_salida',
      sortable: true,
    },
    {
      text: 'Razón de Movimiento',
      key: 'movimiento',
      sortable: true,
    },
    {
      text: 'Cliente',
      key: 'det_cliente',
      sortable: true,
    },
    {
      text: 'Cantidad',
      key: 'det_cantidad',
      sortable: true,
    },
    {
      text: 'Orden de Trabajo',
      key: 'det_orden_trabajo',
      sortable: true,
    },
  ];

  const onSeeDetails = id => {
    try{
      
      onSelectExitDetails(id);
      seleccionado=id;
      setSeleccionadoTemp(seleccionado);
      if (seleccionadoTemp) {
        elemento=document.getElementById(seleccionadoTemp)
        elemento.classList.remove('seleccionado')
        elemento.classList.add('btn-warning')
      }
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }catch(e){
      
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }
  }

  return (
    <>
        <GeneralBox
          size="8"
          title={t('detalles_de_salida')}
        >
          {
            loading
              ? <Load size={80} />
              : <TableData
                columns={columns}
                data={data}
                actions={permissions}
                editComponent={EditExitModal}
                customTextOverlay="Ver Productos"
                customIcon="si si-eye"
                onCustomAction={onSeeDetails}
              />
          }
        </GeneralBox>
    </>
  )
}

// Tabla Detalles de salidas
export const ElementAI = ({ permissions }) => {
  const { reloadComp33: reloadComp } = useContext(ReloadContext);
  const { selectedExitDetails, selectedExitPlate } = useContext(SelectItemContext);
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Escuchar cambio
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      setData([]);
      try {
        const req = await apiGetExitDetailsMeasures(selectedExitDetails);
        const formatData = req.data.content.map(el => ({
          id: Number(el.id_det_salida_medida),
          nombre: el.nombre,
          valor:  el.valor,
          unidad:  el.unidad,
        }));
        
       setData(formatData);
       setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    
 
      loadData();
      
    
    
  }, [selectedExitDetails, setLoading,reloadComp, selectedExitPlate]);

  const columns = [
    {
      text: 'Propiedad',
      key: 'nombre',
      sortable: true,
    },
    {
      text: 'Valor',
      key: 'valor',
      sortable: true,
    },
    {
      text: 'Unidad',
      key: 'unidad',
      sortable: true,
    },
  ];

  return (
    <>
      {
        selectedExitDetails !== 0 && data.length > 0
          ?
          <GeneralBox
            size="4"
            title={t('medidas')}
          >
            {
              loading
                ? <Load size={80} />
                : <TableData
                  columns={columns}
                  data={data}
                  actions={permissions}
                />
            }
          </GeneralBox>
          : <></>
      }
    </>
  )
}

// Contenedor registro de inventario fisico
export const ElementAA = ({ permissions }) => {
  const { reloadComp28: reloadComp } = useContext(ReloadContext);
  const {
    onSelectStore,
    selectedStore,
    onSelectPhysicalInvent,
  } = useContext(SelectItemContext);
  const {t} = useTranslation();
  // Hook para carga de datos
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  // Datos a manejar
  const [data, setData] = useState([]);

  // Cargar Datos de almacenes
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        // Solicitud a API
        const reqStores = await apiGetStores();
        setStores([...reqStores.data.content]);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, []);

  // Cargar tabla
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const req = await apiGetAllPhysicalInvent({ idStore: selectedStore, page: 1 });
        setData([...req.data.content.map(e => ({
          ...e,
          id: Number(e.id_inventario_fisico),
          personal_realiza: e.id_personal_realiza.nombre,
          personal_responsable: e.id_personal_responsable.nombre,
          fecha: moment(e.fecha_inventario).format("YYYY-MM-DD"),
          status: (e.aplicado === 0 ?
            <p className="btn btn-danger mb-0">
              {t('no_aplicado')}
            </p>
            :
            <p className="btn btn-success mb-0">
              {t('aplicado')}
            </p>
          )
        }))]);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        setLoading(false);
      }
    }
    loadData();
    return () => (setData([]));
  }, [reloadComp, selectedStore, t]);

  const columns = [
    {
      text: 'Realizó',
      key: 'personal_realiza',
      sortable: true,
    },
    {
      text: 'Responsable',
      key: 'personal_responsable',
      sortable: true,
    },
    {
      text: 'Fecha',
      key: 'fecha',
      sortable: true,
    },
    {
      text: 'Estatus',
      key: 'status',
      sortable: true,
    },
  ];

  // Tabla con datos
  return (
    <GeneralBox title={t('registro_de_inventario_fisico')} size="8">
      <>
        <Row>
          <Col md="6" className="mb-40">
            <div className="form-group">
              <label htmlFor="id_store" className="form-label">
                Almacén: *
              </label>
              <select
                className="form-select"
                name="id_store"
                id="id_store"
                value={selectedStore}
                onChange={e => {
                  onSelectStore(e.target.value);
                  onSelectPhysicalInvent(0);
                }}
              >
                <option value="0">Seleccione un almacen</option>
                {
                  stores.map(({ glb_id_almacen, glb_nombre }, index) => (
                    <option value={glb_id_almacen} key={index}>
                      {glb_nombre}
                    </option>
                  ))
                }
              </select>
            </div>
          </Col>
        </Row>
        {
          loading
            ? <Load size={80} />
            : selectedStore !== 0
              ? <TableData
                isCountable
                columns={columns}
                data={data}
                actions={permissions}
                customIcon="si si-eye"
                customTextOverlay="Ver detalle"
                onCustomAction={(id) => {
                  onSelectPhysicalInvent(id);
                }}
              />
              : <></>
        }
      </>
    </GeneralBox>
  );
}
// Contenedor detalle de inventario fisico
export const ElementAB = ({ permissions }) => {
  const { selectedPhysicalInvent } = useContext(SelectItemContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  // Cargar datos
  useEffect(() => {
    // Mapa con valores de status
    const mapObsStatus = {
      "INV_FISICO_MENOR": (
        <p className="btn btn-danger mb-0">
          <i className="si si-dislike me-10" />
          {t('inventario_menor')}
        </p>
      ),
      "INV_FISICO_MAYOR": (
        <p className="btn btn-danger mb-0">
          <i className="si si-dislike me-10" />
          {t('inventario_mayor')}
        </p>
      ),
      "SIN_EXISTENCIA": (
        <p className="btn btn-info mb-0">
          {t('sin_existencia')}
        </p>
      ),
      "SIN_DIFERENCIA": (
        <p className="btn btn-success mb-0">
          <i className="si si-like me-10" />
          Iguales
        </p>
      ),
    }
    const loadData = async () => {
      try {
        setLoading(true);
        const req = await apiGetDetailPhysicalInvent(selectedPhysicalInvent);
        setData([...req.data.content.map(e => ({
          ...e,
          id: Number(e.id_det_inventario_fisico),
          observacion: mapObsStatus[e.observacion]
        }))]);
        const DivDetalleFisico = document.querySelector("#detalle_fisico");
        if(DivDetalleFisico){
          DivDetalleFisico.scrollIntoView({ block: 'start', behavior: "smooth"});
        }
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        setLoading(false);
      }
    }
    loadData();
    return () => (setData([]));
  }, [selectedPhysicalInvent, t]);

  const columns = [
    {
      text: 'Producto',
      key: 'producto',
      sortable: true,
    },
    {
      text: 'Lote',
      key: 'lote',
      sortable: true,
    },
    {
      text: 'Embalaje',
      key: 'codigo_caja',
      sortable: true,
    },
    {
      text: 'Nivel',
      key: 'nivel',
      sortable: true,
    },
    {
      text: 'Ubicacición',
      key: 'codigo_ubicacion',
      sortable: true,
    },
    {
      text: 'Cantidad',
      key: 'cantidad_actual_placas',
      sortable: true,
    },
    {
      text: 'Observación',
      key: 'observacion',
      sortable: true,
    },
  ];

  // Tabla con datos
  return (selectedPhysicalInvent !== 0)
    ? <GeneralBox title={t('detalle_de_inventario_físico')} size="12">
      <div id='detalle_fisico'>
        {
          loading
            ? <Load size={80} />
            : <TableData
              isCountable
              columns={columns}
              data={data}
            />
        }
      </div>
    </GeneralBox>
    : <></>;
}

// Tabla de Usuarios
export const ElementAC = ({ permissions }) => {
  const { reloadComp30: reloadComp } = useContext(ReloadContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  // Titulos de las columnas
  const columns = [
    {
      text: t('nombre')+"(s)",
      key: 'nombres',
      sortable: true,
    },
    {
      text: t('apellido')+'(s)',
      key: 'apellidos',
      sortable: true,
    },
    {
      text: 'Email',
      key: 'email',
      sortable: true,
    },
    {
      text: t('nombre_usuario'),
      key: 'username',
      sortable: true,
    },
    {
      text: 'Rol',
      key: 'rol',
      sortable: true,
    },
    {
      text: 'Recibir notificaciones',
      key: 'notificaciones'
    }
  ];
  
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const req = await apiGetAllUsers();
        const formatData = req.data.content.map(el => ({
          ...el,
          id: Number(el.id_usuario),
          notificaciones: el.notificaciones === 1 ? 'Si': 'No'
        }));
        setData([...formatData]);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        setLoading(false);
      }
    }
    loadData();
    return () => {
      setData([]);
    }
  }, [setLoading, reloadComp])

  // Tabla con datos
  return (
    <GeneralBox title={t('usuarios_con_acceso')} size="12">
      {
        loading
          ? <Load size={80} />
          : <TableData
            mainTitle=''
            isCountable
            columns={columns}
            data={data}
            actions={permissions}
            editComponent={EditUser}
          />
      }
    </GeneralBox>
  )
}

// Contenedor registro de inventario fisico
export const ElementAD = ({ permissions }) => {
  const {
    reloadComp29: reloadComp,
    onReloadComponent,
  } = useContext(ReloadContext);
  const idReloadComponent = 29;

  const {
    onSelectStore,
    selectedStore,
    selectedProduct,
    onSelectProduct
  } = useContext(SelectItemContext);

  // Hook para carga de datos
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState([]);

  // Datos a manejar
  const [data, setData] = useState([]);

  // Cargar Datos de almacenes
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        // Solicitud a API
        const reqStores = await apiGetStores();
        setStores([...reqStores.data.content]);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, []);
  // Cargar Datos de almacenes
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        // Solicitud a API
        const reqProducts = await apiGetAllScrapProductsByStore(selectedStore);
        setProducts([...reqProducts.data.content]);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        setLoading(false);
      }
    }
    if (selectedStore !== "0")
      loadData();
    else {
      setProducts([]);
    }
  }, [selectedStore]);

  // Cargar tabla
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const req = await apiGetAllScrapByStore({ idStore: selectedStore, idProduct: selectedProduct });
        setData([...req.data.content.map((e, i) => ({
          ...e,
          id: Number(e.id_pedaceria),
          fecha: moment(e.fecha_alta).format("YYYY-MM-DD, h:mm:ss a")
        }))]);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        setLoading(false);
      }
    }
    if (selectedStore !== "0" && selectedProduct !== "0")
      loadData();
    else {
      setData([]);
    }
    return () => (setData([]));
  }, [reloadComp, selectedStore, selectedProduct]);

  const columns = [
    {
      text: 'Producto',
      key: 'producto',
      sortable: true,
    },
    {
      text: 'Producto',
      key: 'producto',
      sortable: true,
    },
    {
      text: 'Prod. Proveedor',
      key: 'nombre_prod_proveedor',
      sortable: true,
    },
    {
      text: 'Cod. Proveedor',
      key: 'codigo_proveedor',
      sortable: true,
    },
    {
      text: 'Fecha Alta',
      key: 'fecha',
      sortable: true,
    },
  ];

  // Funcion para "sacar" una pedaceria
  const onGetOutProduct = async (id) => {
    const alert = await Swal.fire({
      showCancelButton: true,
      showCloseButton: true,
      title: "¿Esta seguro que desea sacar este producto?",
      text: "La acción no se podrá deshacer",
      icon: "warning",
      confirmButtonText: "Si, sacar",
      cancelButtonText: "No, cancelar",
      cancelButtonColor: ColorPrimary,
      reverseButtons: true
    });
    // La alerta ha sido aceptada
    if (alert.isConfirmed) {
      try {
        const response = await apiPatchScrap(id);
        if (response.data.type === "success") {
          await Swal.fire({
            title: 'La salida ha sido exitosa',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          });
          // Cargar componente
          onReloadComponent(idReloadComponent);
          onSelectProduct("0");
        } else {
          throw new Error("Error al recibir productos");
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Error al generar salida',
          text: e.response?.data?.msg ?? 'Intentelo nuevamente más tarde',
          confirmButtonText: 'Aceptar',
        });
      }
    }
  }

  // Tabla con datos
  return (
    <GeneralBox title="Pedaceria" size="10">
      <>
        <Row>
          <Col md="4" className="mb-40">
            <div className="form-group">
              <label htmlFor="id_store" className="form-label">
                Almacén: *
              </label>
              <select
                className="form-select"
                name="id_store"
                id="id_store"
                value={selectedStore}
                onChange={e => {
                  onSelectStore(e.target.value);
                }}
              >
                <option value="0">Seleccione un almacen</option>
                {
                  stores.map(({ glb_id_almacen, glb_nombre }, index) => (
                    <option value={glb_id_almacen} key={index}>
                      {glb_nombre}
                    </option>
                  ))
                }
              </select>
            </div>
          </Col>
          <Col md="4" className="mb-40">
            <div className="form-group">
              <label htmlFor="id_product" className="form-label">
                Producto: *
              </label>
              <select
                className="form-select"
                name="id_product"
                id="id_product"
                value={selectedProduct}
                disabled={selectedStore === 0}
                onChange={e => {
                  onSelectProduct(e.target.value);
                }}
              >
                <option value="0">Seleccione un producto</option>
                {
                  products.map(({ id_producto, producto, codigo_proveedor }, index) => (
                    <option value={id_producto} key={index}>
                      {producto} - {codigo_proveedor}
                    </option>
                  ))
                }
              </select>
            </div>
          </Col>
        </Row>
        {
          loading
            ? <Load size={80} />
            : selectedStore !== 0 && data.length > 0
              ? <TableData
                isCountable
                columns={columns}
                data={data}
                actions={permissions}
                customIcon="si si-action-redo"
                onCustomAction={onGetOutProduct}
                customTextOverlay="Salida"
              />
              : data.length === 0
                ? <h4 className='text-center mb-50'>No hay registros para mostrar :( </h4>
                : <></>
        }
      </>
    </GeneralBox>
  );
}
// Tabla de Documentos Salidas
export const ElementAE = ({ permissions }) => {
  const { reloadComp31: reloadComp } = useContext(ReloadContext);
  const { selectedExit } = useContext(SelectItemContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetDocumentExit(selectedExit);
        const formatData = req.data.content.map(el => {
          return {
            id: Number(el.id_doc_salida),
            content: [
              moment(el.fecha_carga).format("YYYY-MM-DD"),
              <OverlayTooltip
                placement="top"
                tooltip="Ver"
              >
                <a
                  className="btn btn-danger"
                  href={el.archivo}
                  target="__blank"
                >
                  <i className="si si-eye" />
                </a>
              </OverlayTooltip>,
              el.descripcion ?? "N/A",
            ]
          };
        });
        setData([...formatData]);
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedExit !== 0) {
      loadData();
    }
  }, [selectedExit, setLoading, reloadComp])

  // Tabla con datos
  return (
    <>
      {
        selectedExit !== 0 && data.length > 0
          ? <GeneralBox title="Documentos de Salida" size="8">
            {
              loading
                ? <Load size={80} />
                : <TableSimple
                  isCountable={true}
                  headTitles={["Fecha Carga", "Archivo", "Descripción"]}
                  body={data}
                />
            }
          </GeneralBox>
          : <></>
      }
    </>
  )
}

// Tabla catologo equivalencias
export const ElementAF = ({ permissions }) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  // Titulos de las columnas
  const columns = [
    {
      text: 'Origen',
      key: 'unidad_origen',
      sortable: true,
    },
    {
      text: 'Destino',
      key: 'unidad_destino',
      sortable: true,
    },
    {
      text: 'Factor',
      key: 'factor',
      sortable: true,
    },
  ];
  // Conexion con el BE para traer datos
  useEffect(() => {
    const getData = async () => {
      const req = await apiGetAllMeasureEq();
      setData([...req.data?.content.map(row => ({
        ...row,
        id: row.id_equivalencia,
        factor: Number(row.factor),
      }))]);
    }
    getData();
    return (() => { setData([]) })
  }, [setData]);

  return (
    <GeneralBox size='6' title={t('equivalencias')}>
      <TableData
        isCountable={true}
        columns={columns}
        data={data}
      />
    </GeneralBox>
  )
}

// Tabla de Pedimentos
export const ElementAG = ({ permissions }) => {
  const [idInfoPetition, setIdInfoPetition] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allPetitionsData, setAllPetitionsData] = useState([]);
  const [infoCostos, setInfoCostos] = useState([]);
  const [infoGastos, setInfoGastos] = useState([]);
  const [infoCuenta, setInfoCuenta] = useState([]);
  const [infoProductos, setInfoProductos] = useState([]);

  // Titulos de las columnas
  const columnsAllPetitions = [
    {
      text: 'Fecha Carga',
      key: 'fecha',
      sortable: true,
    },
    {
      text: 'ID',
      key: 'id',
      sortable: true,
    },
  ];
  const columnsCostos = [
    {
      text: 'Referencia',
      key: 'referencia',
      sortable: true,
    },
    {
      text: 'Proveedor',
      key: 'proveedor',
      sortable: true,
    },
    {
      text: 'Fecha Documento',
      key: 'fecha_documento',
      sortable: true,
    },
    {
      text: 'Fecha Carga',
      key: 'fecha_carga',
      sortable: true,
    },
  ];
  const columnsGastos = [
    {
      text: 'Archivo',
      key: 'archivo',
      sortable: true,
    },
    {
      text: 'Subtotal',
      key: 'subtotal',
      sortable: true,
    },
    {
      text: 'Moneda',
      key: 'moneda_codigo_sat',
      sortable: true,
    },
    {
      text: 'Tipo de Cambio',
      key: 'tipo_cambio',
      sortable: false,
    },
  ];
  const columnsCuenta = [
    {
      text: 'Archivo',
      key: 'archivo',
      sortable: true,
    },
    {
      text: 'Referencia',
      key: 'referencia',
      sortable: true,
    },
    {
      text: 'Total Gasto Comprobable',
      key: 'total_gasto_comprobable',
      sortable: true,
    },
  ];
  const columnsProductos = [
    {
      text: 'Producto',
      key: 'nombre_prod_proveedor',
      sortable: true,
    },
    {
      text: 'Precio Total',
      key: 'precio_producto_total',
      sortable: true,
    },
    {
      text: 'G. IGI',
      key: 'gasto_igi_mxn',
      sortable: true,
    },
    {
      text: 'G. DTA',
      key: 'gasto_dta_mxn',
      sortable: true,
    },
    {
      text: 'G. PRV',
      key: 'gasto_prv_mxn',
      sortable: true,
    },
    {
      text: 'G. Adicional',
      key: 'gasto_adicional_mxn',
      sortable: true,
    },
    {
      text: 'G. Comprobable',
      key: 'gasto_comprobable_mxn',
      sortable: true,
    },
  ];
  // Conexion con el BE para traer datos
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const req = await apiGetAllPetitions();
        setAllPetitionsData([...req.data?.content.map(row => ({
          id: row.id_pedimento,
          fecha: moment(row.fecha_carga).format('YYYY-MM-DD, h:mm:ss a')
        }))]);
      } catch (e) {
        console.log('Ocurrio un error', e);
      } finally {
        setLoading(false);
      }
    }
    getData();
    return (() => { setAllPetitionsData([]) })
  }, [setAllPetitionsData]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const { data: { content: { costos, gastos, cuenta, productos } } } = await apiGetPetitionById(idInfoPetition);
        // Modificar costos
        setInfoCostos([...costos.map(e => ({
          ...e,
          fecha_carga: moment(e.fecha_carga).format('YYYY-MM-DD, h:mm:ss a'),
          fecha_documento: moment(e.fecha_documento).format('YYYY-MM-DD, h:mm:ss a'),
          id: e.id_det_pedimento_doc_costo,
        }))]);
        // Modificar gastos
        setInfoGastos([...gastos.map(e => ({
          ...e,
          tipo_cambio: formatCurrency(e.tipo_cambio),
          subtotal: formatCurrency(e.subtotal),
          id: e.id_det_pedimento_gastos_ad,
        }))]);
        // Modificar cuenta
        setInfoCuenta([{
          ...cuenta,
          id: cuenta.id_det_pedimento_cta_gasto,
          total_gasto_comprobable: formatCurrency(cuenta.total_gasto_comprobable),
        }])
        // Moificar Productos
        setInfoProductos([...productos.map((e, i) => ({
          ...e,
          id: i,
          precio_producto_total: formatCurrency(e.precio_producto_total),
          gasto_igi_mxn: formatCurrency(e.gasto_igi_mxn),
          gasto_dta_mxn: formatCurrency(e.gasto_dta_mxn),
          gasto_prv_mxn: formatCurrency(e.gasto_prv_mxn),
          gasto_adicional_mxn: formatCurrency(e.gasto_adicional_mxn),
          gasto_comprobable_mxn: formatCurrency(e.gasto_comprobable_mxn),
        }))]);
      } catch (e) {
        console.log('Ocurrio un error', e)
      }
    }
    if (idInfoPetition !== 0)
      getDetails();
  }, [idInfoPetition])

  return (
    <>
      <GeneralBox size='6' title='Pedimentos'>
        {
          loading
            ? <Load size={80} />
            : <TableData
              isCountable={true}
              columns={columnsAllPetitions}
              data={allPetitionsData}
              actions={permissions}
              customIcon='si si-eye'
              customTextOverlay='Ver detalle'
              onCustomAction={(id) => { setIdInfoPetition(id) }}
            />
        }
      </GeneralBox>
      {
        infoCostos.length > 0 &&
        <GeneralBox size='10' title={`Costos - Pedimento ID: ${idInfoPetition}`}>
          <TableData
            isCountable={true}
            columns={columnsCostos}
            data={infoCostos}
          />
        </GeneralBox>
      }
      {
        infoGastos.length > 0 &&
        <GeneralBox size='10' title={`Gastos - Pedimento ID: ${idInfoPetition}`}>
          <TableData
            isCountable={true}
            columns={columnsGastos}
            data={infoGastos}
          />
        </GeneralBox>
      }
      {
        infoCuenta.length > 0 &&
        <GeneralBox size='10' title={`Cuenta - Pedimento ID: ${idInfoPetition}`}>
          <TableData
            isCountable={true}
            columns={columnsCuenta}
            data={infoCuenta}
          />
        </GeneralBox>
      }
      {
        infoProductos.length > 0 &&
        <GeneralBox size='12' title={`Productos - Pedimento ID: ${idInfoPetition}`}>
          <TableData
            isCountable={true}
            columns={columnsProductos}
            data={infoProductos}
          />
        </GeneralBox>
      }
    </>
  )
}

// Tabla de Pedimentos Nuevos
export const ElementAO = ({ permissions }) => {
  const [idInfoPetition, setIdInfoPetition] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allPetitionsData, setAllPetitionsData] = useState([]);
  const [infoGastos, setInfoGastos] = useState([]);

  // Titulos de las columnas
  const columnsAllMotions = [
    {
      text: 'Referencia',
      key: 'referencia',
      sortable: true,
    },
    {
      text: 'IGI',
      key: 'igi',
      sortable: true,
    },{
      text: 'PRV',
      key: 'prv',
      sortable: true,
    },{
      text: 'DTA',
      key: 'dta',
      sortable: true,
    },{
      text: 'Gasto Comprobable',
      key: 'gasto_comprobable',
      sortable: true,
    },{
      text: 'Gasto Adicional',
      key: 'gasto_adicional',
      sortable: true,
    },
  ];

  const columnsDetailsMotions = [
    {
      text: 'Producto',
      key: 'producto_nombre',
      sortable: true,
    },
    {
      text: 'Cajas',
      key: 'cantidad',
      sortable: true,
    },
    {
      text: 'Prorrateo',
      key: 'prorrateo_region',
      sortable: true,
    },
    {
      text: 'IGI',
      key: 'gasto_igi_mxn',
      sortable: true,
    },
    {
      text: 'PRV',
      key: 'gasto_prv_mxn',
      sortable: true,
    },
    {
      text: 'DTA',
      key: 'gasto_dta_mxn',
      sortable: true,
    },
    {
      text: 'Gasto Comprobable',
      key: 'gasto_comprobable_mxn',
      sortable: true,
    },
    {
      text: 'Honorarios Agente Aduanal',
      key: 'gasto_adicional_mxn',
      sortable: true,
    },
    {
      text: 'Gasto X Caja',
      key: 'gasto_caja_mxn',
      sortable: true,
    },
    {
      text: 'Gasto Producto Unitario',
      key: 'gasto_producto_unitario_mxn',
      sortable: true,
    },
  ];

  // Conexion con el BE para traer datos
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const req = await apiGetAllPetitionsERP();
        setAllPetitionsData([...req.data?.content.map(row => ({
          ...row,
          id: row.id_pedimento_erp,
          igi: formatCurrency(row.igi),
          prv: formatCurrency(row.prv),
          dta: formatCurrency(row.dta),
          gasto_comprobable: formatCurrency(row.gasto_comprobable),
          gasto_adicional: formatCurrency(row.gasto_adicional)
        }))]);
      } catch (e) {
        console.log('Ocurrio un error', e);
      } finally {
        setLoading(false);
      }
    }
    getData();
    return (() => { setAllPetitionsData([]) })
  }, [setAllPetitionsData]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const req = await apiGetPetitionERPById(idInfoPetition);
        setInfoGastos([...req.data?.content.map(e => ({
          ...e,
          id: e.id_pedimento_erp,
          prorrateo_region: (`${e.prorrateo_region}%`),
          gasto_igi_mxn: formatCurrency(e.gasto_igi_mxn),
          gasto_dta_mxn: formatCurrency(e.gasto_dta_mxn),
          gasto_prv_mxn: formatCurrency(e.gasto_prv_mxn),
          gasto_adicional_mxn: formatCurrency(e.gasto_adicional_mxn),
          gasto_comprobable_mxn: formatCurrency(e.gasto_comprobable_mxn),
          gasto_caja_mxn: formatCurrency(e.gasto_caja_mxn),
          gasto_producto_unitario_mxn: formatCurrency(e.gasto_producto_unitario_mxn),
        }))]);
      } catch (e) {
        console.log('Ocurrio un error', e)
      }
    }
    getDetails();
    return () => {
      setInfoGastos([]);
    }
  }, [idInfoPetition])

  return (
    <>
      <GeneralBox size='10' title='Pedimentos'>
        {
          loading
            ? <Load size={80} />
            : <TableData
              isCountable={true}
              columns={columnsAllMotions}
              data={allPetitionsData}
              actions={permissions}
              customIcon='si si-eye'
              customTextOverlay='Ver detalle'
              onCustomAction={(id) => { setIdInfoPetition(id) }}
            />
        }
      </GeneralBox>
      {
        infoGastos.length > 0 &&
        <GeneralBox size='12' title={`Pedimento ID: ${idInfoPetition}`}>
          <TableData
            isCountable={true}
            columns={columnsDetailsMotions}
            data={infoGastos}
          />
        </GeneralBox>
      }
    </>
  )
}

// Tabla de Clientes
export const ElementAH = ({ permissions }) => {
  const { reloadComp32: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComp = 32;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  // Titulos de las columnas
  const columns = [
    {
      text: 'Cliente',
      key: 'nombre',
      sortable: true,
    },
    {
      text: 'RFC',
      key: 'rfc',
      sortable: true,
    },
  ];
  // Escuchar cambio de producto
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const req = await apiGetAllClients();
        setData([...req.data.content.map(e => ({
          ...e,
          id: Number(e.id_cliente),
        }))]);
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    loadData();
    return () => {
      setData([]);
    }
  }, [setLoading, reloadComp])
  // Funcion para eliminar tupla
  const onDeleteFunction = async id => {
    try {
      await apiDeleteClient(id);
      await Swal.fire({
        icon: 'success',
        title: 'El cliente ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComp);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar cliente',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  // Tabla con datos
  return (
    <GeneralBox title={t('clientes')} size="7">
      {
        loading
          ? <Load size={80} />
          : <TableData
            mainTitle=''
            isCountable
            columns={columns}
            data={data}
            actions={permissions}
            deleteTitle='¿Esta seguro de eliminar el cliente?'
            deleteText='La operación es irreversible'
            deleteFunction={onDeleteFunction}
            editComponent={EditClient}
          />
      }
    </GeneralBox>
  )
}

// Tabla de Pedaceria
export const ElementAK = ({ permissions }) => {
  const { reloadComp34: reloadComp } = useContext(ReloadContext);
  const { onSelectScrap } = useContext(SelectItemContext);
  const [seleccionadoTemp, setSeleccionadoTemp] = useState();
  let seleccionado;
  let elemento;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  // Escuchar cambio de producto
  useEffect(() => {
    // Cargar datos
    const loadData = async () => {
      setLoading(true);
      try {
        setData([]);
        // NEXT FEAT: Setear pagina
        const req = await apiGetAllScrap();
        const formatData = req.data.content.map(e => ({
          ...e,
          id: Number(e.id_pedaceria_entrada),
          fecha: moment(e.fecha_alta).format("YYYY-MM-DD"),
        }));  
        setData([...formatData]); 
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      } finally {
        return setLoading(false);
      }
    }
    loadData();
  }, [ reloadComp ]);

  // Función para ver detalle de la salida
  const handleShowDetailProduct = (id) => { 
    try{
      onSelectScrap(id);
      seleccionado=id;
      setSeleccionadoTemp(seleccionado);
      if (seleccionadoTemp) {
        elemento=document.getElementById(seleccionadoTemp)
        elemento.classList.remove('seleccionado')
        elemento.classList.add('btn-warning')
      }
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }catch(e){
      
      elemento=document.getElementById(seleccionado)
      elemento.classList.remove('btn-warning')
      elemento.classList.add('seleccionado')
  }
  }

  const columns = [
    {
      text: 'Producto',
      key: 'nombre',
      sortable: true,
    },
    {
      text: 'Cantidad',
      key: 'cantidad',
      sortable: true,
    },
    {
      text: 'Nivel',
      key: 'nombre_nivel',
      sortable: true,
    },
    {
      text: 'Codigo Ubicación',
      key: 'codigo_ubicacion',
      sortable: true,
    },
    {
      text: 'Fecha de Alta',
      key: 'fecha',
      sortable: true,
    },
    {
      text: 'Codigo Caja',
      key: 'codigo_caja',
      sortable: false
    }
  ];

  // Tabla con datos
  return (
    <>
      <GeneralBox title={t('registros_de_pedaceria')} size="9">
        {
          loading
            ? <Load size={80} />
            : <TableData
              isCountable={true}
              columns={columns}
              data={data}
              actions={permissions}
              customTextOverlay="Ver Detalle"
              customIcon="si si-eye"
              onCustomAction={handleShowDetailProduct}
            />
        }
      </GeneralBox>
    </>
  )
}

// Tabla Medidas de Pedaceria
export const ElementAL = ({ permissions }) => {
  const { reloadComp35: reloadComp } = useContext(ReloadContext);
  const { selectedScrap } = useContext(SelectItemContext);
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  // Escuchar cambio
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const req = await apiGetMedScrap(selectedScrap);
        const formatData = req.data.content.map(el => ({
          id: Number(el.id_pedaceria_entrada),
          nombre: el.nombre,
          valor:  el.valor,
          unidad:  el.unidad,
        }));
       setData([...formatData]);
       const DivProductos = document.querySelector("#medidas");
       if(DivProductos){
         DivProductos.scrollIntoView({ block: 'start', behavior: "smooth"});
       }
       setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    if (selectedScrap !== 0) {
      loadData();
    }
  }, [selectedScrap, setLoading, reloadComp]);

  const columns = [
    {
      text: 'Propiedad',
      key: 'nombre',
      sortable: true,
    },
    {
      text: 'Valor',
      key: 'valor',
      sortable: true,
    },
    {
      text: 'Unidad',
      key: 'unidad',
      sortable: true,
    },
  ];

  return (
    <>
      {
        selectedScrap !== 0 && data.length > 0
          ?
          <GeneralBox
            size="4"
            title={t('medidas_de_pedaceria')}
          >
            <div id = 'medidas'>
              {
                loading
                  ? <Load size={80} />
                  : <TableData
                    columns={columns}
                    data={data}
                    actions={permissions}
                  />
              }
            </div>
          </GeneralBox>
          : <></>
      }
    </>
  )
}

//Tabla Querys
export const ElementAP = ({ permissions }) => {
  const { reloadComp36: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComponent = 36;
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSql, setSelectedSql] = useState("0");
  const {t} = useTranslation();
  // Titulos de las columnas
  const columns = [
    {
      text: t('nombre'),
      key: 'nombre',
      sortable: true,
    },
    {
      text: t('descripcion'),
      key: 'descripcion',
      sortable: true,
    },
    {
      text: t('rol'),
      key: 'roles',
      sortable: true,
    },
    {
      text: 'Script',
      key: 'script',
      sortable: true,
    },    
  ];

  // Funcion de eliminar producto
  const handleDelete = async id => {
    try {
      await apiDeleteScript(id);
      await Swal.fire({
        icon: 'success',
        title: 'El script ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComponent);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar el script',
        text: error.response?.data?.msg ?? 'Revise las dependencias e intentelo de nuevo despues',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  
  // Conexion con el BE para traer datos
  useEffect(() => {
    const showModalProcess = async (id) => {
      setShowModal(true);
      setSelectedSql(id);
    }
    const getData = async () => {
      const req = await apiGetScripts();
      setData([...req.data?.content.map(row => ({
        ...row,
        id: row.id_reporte_sql,
        nombre: row.nombre,
        descripcion: row.descripcion,
        roles: row.roles,
        script: (
            <OverlayTooltip
              placement="top"
              tooltip="Generar Script"
            >
            <Button className="btn btn-info"  onClick={() => { showModalProcess(row.id_reporte_sql) }}>
              <i className="si si-cloud-download" />
            </Button>
            </OverlayTooltip>
        )
        

      }))]);
    }
    getData();
    return (() => { setData([]) })
  }, [setData, reloadComp]);


  
 
  return (
    <>
    <GeneralBox title={t('tabla_sql_a_excel')}>
      <TableData
        mainTitle=''
        columns={columns}
        data={data}
        actions={permissions}
        deleteTitle='¿Esta seguro de eliminar al script?'
        deleteText='La operación es irreversible'
        isCountable
        deleteFunction={handleDelete}
        editComponent={EditReportSQLData}
        //customIconOverlay="Generar Reporte"
        customIconEdit="si si-cloud-upload"
        //onCustomActionEdit={EditReportSQL}
      />
    </GeneralBox>
    <EditReportSQL
      setShow={setShowModal}
      show={showModal}
      id={selectedSql}
    />
    </>
  )
}

//Tabla End Points
export const ElementAQ = ({ permissions }) => {
  const { reloadComp46: reloadComp, onReloadComponent } = useContext(ReloadContext);
  const idReloadComponent = 37;
  const [data, setData] = useState([]);
  const {t} = useTranslation();
  // Titulos de las columnas
  const columns = [
    {
      text: t('nombre'),
      key: 'nombre',
      sortable: true,
    },
    {
      text: t('descripcion'),
      key: 'descripcion',
      sortable: true,
    },
    {
      text: 'EndPoint',
      key: 'liga',
      sortable: true,
    },    
  ];

  // Funcion de eliminar producto
  const handleDelete = async id => {
    try {
      await apiDeleteEndPoint(id);
      await Swal.fire({
        icon: 'success',
        title: 'El EndPoint ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComponent);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar el script',
        text: error.response?.data?.msg ?? 'Revise las dependencias e intentelo de nuevo despues',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  const helperEndpoint = async (path) => {
    try {
      const data = await apiGetExecute(path);
      const json = JSON.stringify(data.data.content);
      window.open('execute','_blank').document.write(json);
      
      // Recargar componente
      onReloadComponent(idReloadComponent);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al ejecutar el EndPoint',
        text: error.response?.data?.msg ?? 'Revise las dependencias e intentelo de nuevo despues',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  
  // Conexion con el BE para traer datos
  useEffect(() => {
    
    const getData = async () => {
      const req = await apiGetEndPoints();
      setData([...req.data?.content.map(row => ({
        ...row,
        id: row.id_endpoint,
        nombre: row.nombre,
        descripcion: row.descripcion,
        endpoint: row.endpoint,
        liga: (
          <OverlayTooltip
            placement="top"
            tooltip="Ejecutar EndPoint"
          >
          <Button className="btn btn-info"   onClick={() => { helperEndpoint(row.path) }}>
            <i className="si si-cloud-download" />
          </Button>
          </OverlayTooltip>
      )
      }))]);
    }
    getData();
    return (() => { setData([]) })
  }, [setData, reloadComp, t]);


  
 
  return (
    <>
    <GeneralBox title={t('tabla_endpoints')}>
      <TableData
        mainTitle=''
        columns={columns}
        data={data}
        actions={permissions}
        deleteTitle='¿Esta seguro de eliminar el endpoint?'
        deleteText='La operación es irreversible'
        isCountable
        deleteFunction={handleDelete}
        editComponent={EditEndPointModal}
        //customIconOverlay="Generar Reporte"
        customIconEdit="si si-cloud-upload"
        //onCustomActionEdit={EditReportSQL}
      />
    </GeneralBox>
    
    </>
  )
}

// Tabla prepedidos
export const ElementAR = ({ permissions }) => {
  const { reloadComp38: reloadComp, onReloadComponent } = useContext(ReloadContext)
  const idReloadComponent = 38
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false) 
  const [id, setId] = useState(null)

  const columns = [
    {
      text: 'Nombre',
      key: 'nombre',
      sortable: true,
    },
    {
      text: 'Fecha de creacion',
      key: 'fecha_creacion',
      sortable: true,
    },    
  ];

  useEffect(() => {
    const getData = async () => {
      try{
        const res = await apiGetAllPrepedidos()
        const prepedidos = res.data.content.map((prepedido, index) => (
          {
            nombre: `${prepedido.proveedor.nombre} ${index + 1}`, 
            fecha_creacion: moment.utc(prepedido.fecha_creacion).subtract(6, 'hours').format('YYYY-MM-DD HH:mm:ss'),
            id: prepedido.id_prepedido
          }
        ))
  
        setData([...prepedidos])
        setLoading(false)
      }catch(e){
        console.log(`Ocurrio un error al cargar datos:\n${e}`);
      }
    }
    getData()
    return () => {
      setData([]);
    }
  }, [reloadComp])

  const handleDelete = async id => {
    try {
      await apiDeletePrepedido(id);
      await Swal.fire({
        icon: 'success',
        title: 'El cliente ha sido eliminado exitosamente',
        confirmButtonText: 'Aceptar',
      })
      // Recargar componente
      onReloadComponent(idReloadComponent);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error al eliminar el prepedido',
        text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
        confirmButtonText: 'Aceptar',
      });
    }
  }

  const onSave = useCallback( async (data, id) => {
    const prepedido = await apiPutPrepedido(id, data)
    return prepedido
  }, [])

  const onSeeDetails=  id => {
    setShow(true)
    setId(id)
  } 

  return(
    <>
      <GeneralBox title={'Prepedidos'} size="7">
        {
          loading
            ? <Load size={80} />
            : <TableData
              mainTitle=''
              isCountable
              columns={columns}
              data={data}
              actions={permissions}
              deleteTitle='¿Esta seguro de eliminar el prepedido?'
              deleteText='La operación es irreversible'
              deleteFunction={handleDelete}
              editComponent={PrepedidosModal}
              editProps={{onSave: onSave}}
              onCustomAction={onSeeDetails}
            />
        }
      </GeneralBox>
      <PrepedidosModal show={show} setShow={setShow} id={id} isviewDetail={true}/>
    </>
  )
}

/* ----------------------------- ELEMENTOS DEL DASHBOARD ----------------------------- */
// Contenedor de Elemento Dashboard
export const HomeElementA = () => {
  // Productos Actuales
  const { t } = useTranslation();

  useEffect( () => {
    const loadData = async () => {
      try{
        let productsAct = await apiGetProductsAct();
        productsAct = productsAct.data.content.map((e) => ({
          ...e,
          total: e.total
      }));
      
      setProductsExis(parseInt(productsAct[0].total));

      }catch(e){
        console.log(`Error al cargar datos: ${e}`)
      }
    }
    loadData();
  }, [])
  const [productsExis, setProductsExis] = useState([]);
  // Facturas por Recibir
  const [invoicesReceived, setInvoicesReceived] = useState([]);

  useEffect( () => {
    const loadData = async () => {
      try{
        let getInvoice = await apiGetInvoice();
        getInvoice = getInvoice.data.content;
        
        setInvoicesReceived(getInvoice);
      }catch(e){
        console.log(`Error al cargar datos: ${e}`)
      }
    }
    loadData();
  }, [])

  // Entradas actuales
  const [ticketsReceived, setTicketsReceived] = useState([]);

  useEffect( () => {
    const loadData = async () => {
      try{
        let getAllTickets = await apiGetTickets();
        getAllTickets = getAllTickets.data.content;
        
        setTicketsReceived(getAllTickets);
      }catch(e){
        console.log(`Error al cargar datos: ${e}`)
      }
    }
    loadData();
  }, [])

  // Salidas actuales
  const [exitAct, setExitAct] = useState([]);

  useEffect( () => {
    const loadData = async () => {
      try{
        let getAllExit = await apiGetExit();
        getAllExit = getAllExit.data.content;
        
        setExitAct(getAllExit);
      }catch(e){
        console.log(`Error al cargar datos: ${e}`)
      }
    }
    loadData();
  }, [])

  return (
    <Row>
      <Col xs md="4" lg="3">
        <SmallDetailBox
          title={productsExis}
          text={<p className="fs-18 mt-10">{t('existencias')}</p>}
          icon="si si-grid"
          color="primary"
        />
      </Col>
      <Col xs md="4" lg="3">
        <SmallDetailBox
          title={invoicesReceived}
          text={<p className="fs-18 mt-10">{t('facturas')}</p>}
          icon="si si-doc"
          color="warning"
        />
      </Col>
      <Col xs md="4" lg="3">
        <SmallDetailBox
          title={ticketsReceived}
          text={<p className="fs-18 mt-10">{t('entradas')}</p>}
          icon="si si-action-undo"
          color="danger"
        />
      </Col>
      <Col xs md="4" lg="3">
        <SmallDetailBox
          title={exitAct}
          text={<p className="fs-18 mt-10">{t('salidas')}</p>}
          icon="si si-action-redo"
          color="info"
        />
      </Col>
    </Row>
  )
}

export const HomeElementB = () => {
  // Productos en Stock
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [costo, setCosto] = useState([]);
  const [heightLabelsX, setHeightLabels] = useState();
  const [params, setParams] = useState({
    type_movement: '1',
    store: '21',
    date: moment().format('YYYY-MM-DD')
  });
  const [typeMovements, setTypeMovements] = useState([]);
  const [stores, setStores] = useState([]);
  
  const chartRef = useRef();
  const [scrollWidth, setScrollWidth] = useState(0)
  const [loading, setLoading] = useState(true)
  const [limit, setLimit] = useState();

  const [visibleCategories, setVisibleCategories] = useState([]);
  const [visibleData, setVisibleData] = useState([]);
  const [visibleCosto, setVisibleCosto] = useState([]);

  const { t } = useTranslation();
  useEffect( () => {
    const loadData = async () => {
      try{
        let reqTypesMovement = await apiGetAllTypeMov();
        reqTypesMovement = reqTypesMovement.data.content;
        setTypeMovements(reqTypesMovement);
        let reqStores = await apiGetStores();
        reqStores = reqStores.data.content;
        setStores(reqStores);
      }catch(e){
        console.log(`Error al cargar datos: ${e}`)
      }
    }
    loadData();
  }, [])

  useEffect(  () => {
    const loadData = async () => {
      try{
        let getAllStock = await apiGetProductsStock(params.type_movement, params.store, moment(params.date).format('YYYY-MM-DD'));
        getAllStock = getAllStock.data.content;
        setCategories(getAllStock.nombres);
        setData(getAllStock.cantidades);
        setCosto(getAllStock.costos)
        setHeightLabels(params.type_movement === '2' ? 250 : 200)
        setLimit(getAllStock.cantidades.length > 50 ? 50 : getAllStock.cantidades.length)
      }catch(e) {
        console.log(`Ocurrio un error:\n${e}`);
        Swal.fire({
            icon: 'error',
            title: t('error'),
            confirmButtonText: 'Aceptar',
        });
      }
    }
    loadData();
  }, [params.type_movement, params.store, params.date, t])


  useEffect(() => {
    setVisibleCosto([...costo.slice(0, limit)])
    setVisibleData([...data.slice(0, limit)])
    setVisibleCategories([...categories.slice(0, limit)])
    setScrollWidth(chartRef.current.scrollWidth)
    setLoading(false)
  }, [limit, costo, data, categories]);
  

  const loadMoreData = () => {
    let newLimit
    if(limit + 50 < data.length) { newLimit = limit + 50; }
    else{ newLimit = data.length}
    setLimit(newLimit);
  };
  
  const handleChange = e => {
    setVisibleCosto([])
    setVisibleData([])
    setVisibleCategories([])
    setParams({
        ...params,
        [e.target.name]: e.target.value
    });
  }

  const handleScroll = () => {
    const chartElement = chartRef.current;
    if ((chartElement.offsetWidth + chartElement.scrollLeft >= chartElement.scrollWidth) && limit < (data.length)){
      setLoading(true);
      loadMoreData();
    }
  };

  useEffect(() => {
    if(!loading){
      chartRef.current.scrollLeft = ((scrollWidth/3) * 2);
    }
  }, [loading, scrollWidth]);


  
  const dynamicWidth = useMemo(() => data.length * 50, [data])

  const chartWidth = dynamicWidth < window.innerWidth ? '100%' : dynamicWidth;
  const options = {
    chart: {
      animations: {
        enabled: false
      },
      height: 468,
      type: 'bar',
      width: '100%',
      stacked: true
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1],
    },
    labels: visibleCategories,
    xaxis: {
      labels: {
        maxHeight: heightLabelsX,
        rotateAlways: true,
      }
    },
    noData: {
      text: 'No hay datos',
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: [0, 1],
      shared: true,
      intersect: false,
      followCursor: true,
    },
    yaxis: [{
      title: {
        text: 'Cantidad',
      },
    }, {
      opposite: true,
      title: {
        text: 'Costo'
      }
    }],
    colors: [
      ColorDanger,
      ColorWarning
    ],
  };
  const series = [
    {
      name: "Existencias",
      type: 'column',
      data: visibleData
    },
    {
      name: "Costo",
      type: 'column',
      data: visibleCosto
    }
  ];

  return (
    <Row>
      <GeneralBox
        title={t('productos_stock')}
        size="12"
      >
        <>
          <Row>
            <Col xs = "12" md = "3">
              <label htmlFor="store" className ="form-label">
                {t('almacen')}: *
              </label>
              <select 
                name="store" 
                id="store" 
                className='form-select'
                value={params.store}
                onChange={handleChange}
              >
                {
                  stores.map( ({glb_id_almacen, glb_nombre}, index) => (
                    <option value={glb_id_almacen} key={index}>
                        {glb_nombre}
                    </option>
                  ))
                }
              </select>
            </Col>
            <Col xs = "12" md = "3">
              <label htmlFor="type_movement" className ="form-label">
                {t('tipo_movimiento')}: *
              </label>
              <select 
                name="type_movement" 
                id="type_movement" 
                className='form-select'
                value={params.type_movement}
                onChange={handleChange}
              >
                {
                  typeMovements.map( ({glb_id_tipo_salida, glb_nombre}, index) => (
                      <option value={glb_id_tipo_salida} key={index}>
                          {glb_nombre}
                      </option>
                  ))
                }
                </select>
            </Col>
            <Col md='3'>
              <label htmlFor="date" className="form-label">
                  {t('fecha')}: *
              </label>
              <input 
                type="date"
                className="form-control"
                id="date"
                name="date"
                value={params.date}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <div className='contenedor-vertical-scroll' onScroll={handleScroll}  ref={chartRef}>
            <Chart
              options={options}
              series={series}
              type = 'bar'
              width={chartWidth}
              height='468'
            />
          </div>
          </>
      </GeneralBox>
    </Row>
)}

  export const HomeElementC = () => {
    const [existProducts, setExistProducts] = useState([]);
    const { t } = useTranslation();
    const uso = (estado, porcentaje) => {
      switch(estado){
        case 'verde': 
          return (
            <div className='d-flex align-items-center'>
              <span className='mx-5'>Uso</span>
              <div className='progress progress-xs w-p100 mt-0'>
                <div className='progress-bar bg-primary' role='progressbar' aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{width: `${porcentaje}%`}}>
                </div>
              </div>
            </div>
          )
        case 'amarillo':
          return (
            <div className='d-flex align-items-center'>
              <span className='mx-5'>Uso</span>
              <div className='progress progress-xs w-p100 mt-0'>
                <div className='progress-bar bg-warning' role='progressbar' aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{width: `${porcentaje}%`}}>
                </div>
              </div>
            </div>
          )
        case 'rojo':
          return (
            <div className='d-flex align-items-center'>
              <span className='mx-5'>Uso</span>
              <div className='progress progress-xs w-p100 mt-0'>
                <div className='progress-bar bg-danger' role='progressbar' aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{width: `${porcentaje}%`}}>
                </div>
              </div>
            </div>
          )
        default:
          return null;
      }
      
    }

    useEffect( () => {
      const loadData = async () => {
        try{
          let data = [];
          let boxesAllRacks = await apiGetBoxesAllRacks();
          boxesAllRacks = boxesAllRacks.data.content;
          let existBoxes = await apiGetExistBoxes()
          existBoxes = existBoxes.data.content;
          
          existBoxes.forEach( (el) => {
            let status = 'verde';
            let porcentaje = 0;
            if(el.numero_cajas !== '0'){
              for(let i = 0; i <  boxesAllRacks.length; i++){
                if(el.id_rack === boxesAllRacks[i].id_rack){
                  porcentaje = (parseInt(el.numero_cajas) * 100) / parseInt(boxesAllRacks[i].cajas);
                  porcentaje = roundToTwo(porcentaje);
                  break;
                }
              }
              if(porcentaje >= 40 && porcentaje <= 75){
                status = 'amarillo';
              }else if(porcentaje > 75){
                status = 'rojo'
              }
            }
            data.push({
              nombre: `${el.nombre} - ${el.codigo_rack}`,
              uso: uso(status, porcentaje),
              porcentaje: `${porcentaje}%`
            })
          })
          setExistProducts(data);
        }catch(e){
          console.log(`Error al cargar datos: ${e}`)
        }
      }
      loadData();
    }, [])

    function roundToTwo(num) {
      return +(Math.round(num + "e+2")  + "e-2");
  }

    const columns = [
      {
        text: 'nombre',
        key: 'nombre',
        sortable: true,
      },
      {
        text: 'uso',
        key: 'uso',
        sortable: false,
      },
      {
        text: 'porcentaje',
        key: 'porcentaje',
        sortable: true
      }
    ];

    return (
      <Row>
        <GeneralBox
          title={t('existencia_por_rack')}
          size="12"
        >
          <TableData
            isCountable = {true}
            columns={columns}
            data={existProducts}
          />
        </GeneralBox>
      </Row>
  )
}

export const HomeElementD = () => {
  const [productos, setProductos] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [almacen, setAlmacen] = useState({
    id_almacen: '21'
  });
  const { t } = useTranslation();
  useEffect( () => {
    const loadData = async () => {
      try{
        let  reqAlmacenes = await apiGetStores();
        reqAlmacenes = reqAlmacenes.data.content;
        setAlmacenes(reqAlmacenes);
      }catch(e){
        console.log(`Error al cargar datos: ${e}`)
      }
    }
    loadData();
  }, [])

  useEffect( () => {
    const loadData = async () => {
      try{
        const reqProductos = await apiPuntoReorden(almacen.id_almacen);
        setProductos(reqProductos.data.content);
      }catch(e){
        Swal.fire({
            icon: 'error',
            title: t('error'),
            confirmButtonText: 'Aceptar',
        });
      }
    }
    loadData()
  }, [almacen.id_almacen, t])

  const handleChange = e => {
    setAlmacen({
        ...almacen,
        [e.target.name]: e.target.value
    });
  }

  const columns = [
    {
      text: 'Producto',
      key: 'producto',
      sortable: true,
    },
    {
      text: 'Salidas diarias',
      key: 'salidas_diarias',
      sortable: true
    },
    {
      text: 'Stock de seguridad',
      key: 'stock_seguridad',
      sortable: false
    },
    {
      text: 'Tiempo de espera',
      key: 'dias',
      sortable: false,
    },
    {
      text: 'Nivel minimo',
      key: 'nivel_minimo',
      sortable: false
    },
    {
      text: 'Punto de reorden',
      key: 'puntoReorden',
      sortable: false,
    },
    {
      text: 'Nivel maximo',
      key: 'nivel_maximo',
      sortable: false
    },
    {
      text: 'Cantidad actual',
      key: 'cantidad_actual',
      sortable: true
    }, 
    {
      text: 'Hacer pedido',
      key: 'hacer_pedido',
      sortable: false
    },
    {
      text: 'Exceso',
      key: 'exceso',
      sortable: false
    },
  ];

  return (
    <Row>
      <GeneralBox
        title={t('punto_de_reorden')}
        size="12"
      >
        <>
          <Row>
            <Col xs = "12" md = "3" style={{marginBottom: '20px'}}>
              <label htmlFor="id_almacen" className ="form-label">
                {t('almacen')}: *
              </label>
              <select 
                name="id_almacen" 
                id="id_almacen" 
                className='form-select'
                value={almacen.id_almacen}
                onChange={handleChange}
              >
              {
                almacenes.map( ({glb_id_almacen, glb_nombre}, index) => (
                    <option value={glb_id_almacen} key={index}>
                        {glb_nombre}
                    </option>
                ))
              }
              </select>
            </Col>
          </Row>
          <TableData
            isCountable = {false}
            columns={columns}
            data={productos}
          />
        </>
      </GeneralBox>
    </Row>
  )
}

export const HomeElementE = () => {
  return (
    <>
      <Indicators/>
    </>
  );
}
