import fields from '../../../utilities/fieldsOnExit';
import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
    Button,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
// Componentes Generales
import Modals from '../modal.component';
import { OverlayTooltip } from '../../common.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
import '../../../css/stylesItemRowProduct.css';
import { backgroundColors } from '../../../utilities/backgroundColorsRowProduct';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion API
import {
    apiGetAllStaff,
    apiGetStores,
    apiPostNewExit,
    apiGetAllTypeMov,
    apiGetProductByTypeAndStore,
    apiGetRack,
    apiGetLevel,
    apiGetExistencias,
    apiGetPropiertiesProduct,
    apiGetReasonByType,
    apiGetAllRacks,
    getRacksByStore,
    apiGetDetails,
    getClientsERP
} from '../../../common/api-invent';

const AddExitPlate = ({ show, setShow }) => {
    // Recarga de componente
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadCompB = 26;
    const idReloadCompC = 34;
    const idReloadCompD = 25;
    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const [typeMovement, setTypeMovement] = useState([]);
    const [products, setProducts] = useState([]);
    const [racks, setRacks] = useState([]);
    const [levels, setLevels] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [clients, setClients] = useState([]);
    const [stock, setStock] = useState({
        cantidad_actual: '0',
        id_existencia: '0'
    });
    const [area, setArea] = useState({
        area: 0
    });
    const [cajas, setCajas] = useState([]);

    // Datos del formulario
    const [infoPlace, setInfoPlace] = useState({
        type_movement: '0',
        store: '0',
        date: moment().format('YYYY-MM-DD'),
        quantity: '0',
        rack: '0',
        level: '0',
        product: '0',
        area: '0',
        id_personal: '0',
        caja: '0',
        remaininArea: '0',
        properties: [],
    });

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de almacenes
                const reqStores = await apiGetStores();
                setStores([...reqStores.data.content]);
                // Solicitar tipos de movimiento
                const reqTypesMovement = await apiGetAllTypeMov();
                setTypeMovement([...reqTypesMovement.data.content]);
                // Solicitar personal
                const reqStaffs = await apiGetAllStaff();
                setStaffs([...reqStaffs.data.content]);
                //Carga clientes del Erp
                const clientes = await getClientsERP();
                setClients(clientes.data.content);
                setInfoPlace( prev => ({
                    ...prev,
                    date: moment().format('YYYY-MM-DD'),
                }))
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData()
        return () => {
            setLoading(false);
        }
    }, [setLoading, show]);

    //Obtener los productos
    useEffect( () => {
        const loadData = async () =>{
            try{
                setLoading(true);
                const reqProducts = await apiGetProductByTypeAndStore(infoPlace.type_movement, infoPlace.store);
                setProducts([...reqProducts.data.content]);
                setInfoPlace( (prev) => (
                    {
                        ...prev,
                        rack: '0',
                        level: '0',
                        quantity: '0',
                        area : '0',
                        product: '0',
                        caja: '0',
                        remaininArea: '0',
                        properties: [] 
                    }
                ))
            }catch(e){
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }finally {
                setLoading(false);
            }
        }
        if (show && infoPlace.type_movement !== '0' && infoPlace.store !== '0')
        loadData()
        return () => setLoading(false);
    }, [show, infoPlace.store, infoPlace.type_movement]);

    // Manejador de la existencia actual del producto
    const handleChangeQuantity = e =>{
        const areaTotal = parseFloat(area.area).toFixed(8) * parseInt(e.target.value);
        const cm2 = infoPlace.properties.reduce((total, el) => (parseFloat(total) + parseFloat(el.cm2)).toFixed(8), 0)
        setInfoPlace({
            ...infoPlace,
            quantity: e.target.value,
            area: areaTotal,
            remaininArea:  (parseFloat(areaTotal) - parseFloat(cm2)).toFixed(8)
        });
        if(parseFloat(e.target.value) > parseFloat(stock.cantidad_actual)){
            setInfoPlace({
                ...infoPlace,
                area: '0',
                quantity: '0'
            });
            Swal.fire({
                icon: 'error',
                title: 'Se excedio la existencia actual',
                confirmButtonText: 'Aceptar',
            });
        }
    }

    // Cambio en informacion de lugar
    const handleChangeInfoPlace = e => {
        if(e.target.name === 'caja') {
            const index = e.target.options.selectedIndex - 1;
            setStock(cajas[index]);
            if(infoPlace.type_movement === '2') {
                // Obtiene el área de la pedaceria
                const values = cajas[index].mediadas
                let alto = 0;
                let largo = 0;
                for(let i=0; i < values.length; i++){
                    if(values[i].nombre === 'Alto'){
                        alto = parseFloat(values[i].valor);
                    }else if(values[i].nombre ==='Largo'){
                        largo = parseFloat(values[i].valor);
                    }
                }
                const prodArea = alto * largo;

                setArea({
                    area: prodArea
                });
            }
        }

        setInfoPlace({
            ...infoPlace,
            [e.target.name]: e.target.value
        });
    }

    // Cargar racks
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de racks
                const reqRacks = await apiGetRack(infoPlace.store, infoPlace.product, infoPlace.type_movement);
                setRacks([...reqRacks.data.content]);
                setInfoPlace( (prev) =>(
                    {
                        ...prev,
                        level: '0',
                        quantity: '0',
                        area: '0',
                        rack: '0',
                        caja: '0',
                        remaininArea: '0',
                        properties: []
                    }
                ))
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show && infoPlace.product !== '0' && infoPlace.store !== '0' && infoPlace.type_movement !== '0')
            loadData()
        return () => setLoading(false);
    }, [show, infoPlace.product, infoPlace.store, infoPlace.type_movement]);

    // Cargar Niveles
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de racks
                const reqLevels = await apiGetLevel(infoPlace.rack, infoPlace.product, infoPlace.type_movement);
                setLevels([...reqLevels.data.content]);
                setInfoPlace( (prev) => (
                    {
                        ...prev,
                        quantity: '0',
                        area : '0',
                        level: '0',
                        caja: '0',
                        remaininArea: '0',
                        properties: [] 
                    }
                ))
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`);
                setLevels([]);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show && infoPlace.rack !== '0' && infoPlace.product !== '0' && infoPlace.type_movement !== '0')
            loadData()
        return () => setLoading(false);
    }, [show, infoPlace.rack, infoPlace.product, infoPlace.type_movement]);

    
    // Carga de cajas
    useEffect( () => {
        const loadData = async () => {
            try{
                setLoading(true)
                let reqCajas = await apiGetExistencias(infoPlace.level, infoPlace.product, infoPlace.type_movement);
                if (infoPlace.type_movement === '1'){
                    reqCajas = reqCajas.data.content.map((el) => {
                        return {
                            nombre: `Caja ${el.id_caja} - Existencias: ${parseInt(el.cantidad_actual)}`,
                            id: el.id_entrada,
                            cantidad_actual: el.cantidad_actual,
                            id_existencia: el.id_existencia
                        }
                        
                    })
                }else {
                    reqCajas = reqCajas.data.content.map((el) => {
                        let nombre = '-';
                        for(let i = 0;  i < el.mediadas.length; i++){
                            if (el.mediadas[i].nombre === 'Largo'){
                                nombre = `${el.mediadas[i].nombre} ${el.mediadas[i].valor}${el.mediadas[i].unidad} ${nombre}` 
                            }else if(el.mediadas[i].nombre === 'Alto'){
                                nombre = `${nombre} ${el.mediadas[i].nombre} ${el.mediadas[i].valor}${el.mediadas[i].unidad}`  
                            }
                        }
                        return {
                            nombre: nombre,
                            id: el.id_pedaceria_entrada,
                            cantidad_actual: el.cantidad_actual,
                            id_existencia: el.id_existencia,
                            mediadas: el.mediadas
                        }    
                    })
                }
                if(reqCajas.length > 0) {
                    setCajas([...reqCajas])
                }
            }catch (e){
                console.log(`Ocurrio un error:\n${e}`);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setInfoPlace( (prev) => (
                    {
                        ...prev,
                        quantity: '0',
                        area : '0',
                        caja: '0',
                        remaininArea: '0',
                        properties: [] 
                    }
                ))
                setLoading(false);
            }
        }
        if (show && infoPlace.level !== '0' &&  infoPlace.product !== '0')
        loadData()
    }, [show, infoPlace.level,  infoPlace.product, infoPlace.type_movement])

    // Carga de propiedades del producto
    useEffect(  () => {
        const loadData = async () =>{
            try{
                setLoading(true);
                let reqArea = await apiGetPropiertiesProduct(infoPlace.product);
                reqArea = reqArea.data.content;
                for (let i = 0; i < reqArea.length; i++){
                    if(reqArea[i].nombre === 'Area'){
                        setArea({area: reqArea[i].valor});
                        break;
                    }
                }
            }catch(e){
                console.log(`Ocurrio un error:\n${e}`);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
           
        } 
        if (show && infoPlace.product !== '0' && infoPlace.type_movement === '1')
        loadData()
        return () => setLoading(false);
    }, [show, infoPlace.product, infoPlace.type_movement])

    const calculateRemainingArea = (index, auxProperties) => {
        let areaAllItems = 0;
        if( auxProperties.length > 0 ){
            auxProperties.forEach( (element, indexElement) => {
                if (index !== indexElement)
                areaAllItems = parseFloat(element.cm2) + parseFloat(areaAllItems);
            })
        }
        return (parseFloat(infoPlace.area) - areaAllItems).toFixed(8);
    }

/* --------------------------------- Funciones para agregar o eliminar las razones -----------------------------------------------*/
     // Eliminar fila de producto
     const handleDeleteRow = e => {
        const index = Number(e.currentTarget.getAttribute('index'));
        // Objeto auxiliar para guardar valores
        const auxProperties = [...infoPlace.properties];
        const cm2Deleted = auxProperties[index].cm2 
        // Eliminar valor
        auxProperties.splice(index, 1);
        // Guardar valores
        setInfoPlace({
            ...infoPlace,
            properties: auxProperties,
            remaininArea: parseFloat(parseFloat(infoPlace.remaininArea) + parseFloat(cm2Deleted)).toFixed(8)
        })
    }

    // Cambio en valores de fila de producto
    const handleChangeRow = e => {
        const index = Number(e.target.getAttribute('index'));
        const field = e.target.name;
        const auxProperties = [...infoPlace.properties];
        auxProperties[index] = {
            ...auxProperties[index],
            [e.target.name]: e.target.value,
        }
        if (field === 'id_almacen'){
            auxProperties[index] = {
                ...auxProperties[index],
                id_rack: '0',
                id_almacen_ubicacion: '0'
            };
        }else if(field === 'id_rack'){
            auxProperties[index] = {
                ...auxProperties[index],
                id_almacen_ubicacion: '0'
            };
        }else if(field === 'id_razon_movimiento'){
            auxProperties[index] = {
                ...auxProperties[index],
                id_almacen: '0',
                id_rack: '0',
                id_almacen_ubicacion: '0',
                largo: '0',
                alto: '0',
                cantidad: '0',
                cm2: '0',
                id_razones_movimiento_detalles: '0',
                cdi: '0',
                orden_trabajo: '',
                version: '',
                id_cliente: '0'
            };
        }else if(field === 'largo'){
            auxProperties[index] = {
                ...auxProperties[index],
                cm2: ((parseFloat(e.target.value) * parseFloat(auxProperties[index].alto)) * parseInt(auxProperties[index].cantidad)).toFixed(8)
            }
        }else if(field === 'alto'){
            auxProperties[index] = {
                ...auxProperties[index],
                cm2: ((parseFloat(e.target.value) * parseFloat(auxProperties[index].largo)) * parseInt(auxProperties[index].cantidad)).toFixed(8)
            }
        }else if(field === 'cantidad'){
            auxProperties[index] = {
                ...auxProperties[index],
                cm2: (parseFloat(auxProperties[index].largo) * parseFloat(auxProperties[index].alto) * parseInt(e.target.value)).toFixed(8)
            }
        }
        if (!fields[auxProperties[index].id_razon_movimiento].visibleFields.cm2){
            auxProperties[index] = {
                ...auxProperties[index],
                cm2: calculateRemainingArea(index, auxProperties),
                cantidad: '1'
            }
        }
        setInfoPlace({
            ...infoPlace,
            remaininArea: (calculateRemainingArea(index, auxProperties) - auxProperties[index].cm2),
            properties: auxProperties,
        })
    }

    const handleChangeCm2 = e => {
        const index = Number(e.target.getAttribute('index'));
        const auxProperties = [...infoPlace.properties];
        auxProperties[index] = {
            ...auxProperties[index],
            [e.target.name]: parseFloat(e.target.value)
        };
        setInfoPlace({
            ...infoPlace,
            properties: auxProperties,
            remaininArea: (calculateRemainingArea(index, auxProperties) - auxProperties[index].cm2)
        })
    }

    const handleChangeRowOnlyNumbers = (e) => {
        const isValid = e.target.validity.valid;
        if(isValid){
            const index = Number(e.target.getAttribute('index'));
            const auxProperties = [...infoPlace.properties];
            auxProperties[index] = {
                ...auxProperties[index],
                [e.target.name]: e.target.value,
            };
            setInfoPlace({
                ...infoPlace,
                properties: auxProperties,
            })
        }
     
    }

    //Validación para formulario
    const isFormValid = () => {
        if (infoPlace.store === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere almacén',
                show: true
            });
            return false;
        }
        if (infoPlace.type_movement === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere tipo de movimiento',
                show: true
            });
            return false;
        }
        if (infoPlace.product === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere producto',
                show: true
            });
            return false;
        }
        if (infoPlace.rack === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere rack',
                show: true
            });
            return false;
        }
        if (infoPlace.level === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nivel',
                show: true
            });
            return false;
        }
        if (infoPlace.quantity === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere cantidad',
                show: true
            });
            return false;
        }
        if (infoPlace.id_personal === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere personal',
                show: true
            });
            return false;
        }
        if(parseFloat(infoPlace.remaininArea) !== 0){
            setAlert({
                title: 'Formulario incompleto',
                message: 'El area de la placa no coincide con el de la salida',
                show: true
            });
            return false;
        }
        if(infoPlace.properties.length <= 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere razón movimiento',
                show: true
            });
            return false;
        } else {
            let save = true;
            let message = 'Se requiere llenar todos los campos de las razones de movimiento'
            infoPlace.properties.forEach((property) => {
                const requiereFields = fields[property.id_razon_movimiento].requiredFields;
                if(property.cantidad === '0' || property.cantidad === '' || property.id_razon_movimiento === '0') save = false;
                if(property.id_razones_movimiento_detalles === '30' || property.id_razones_movimiento_detalles === '31'){
                    if(property.orden_trabajo === '' || property.version === '' || property.id_cliente === '0') save = false;
                    if(infoPlace.store === '21' && ((property.cdi === '0' || property.cdi === '' || property.cdi === 0))) save = false;
                };
                requiereFields.forEach(requiereField => {   
                    if (requiereField === 'medidas'){
                        if(property.largo <= 0 || property.alto <= 0) save = false;
                    } else if(requiereField === 'cm2'){
                        if(property.cm2 <= 0){
                            save = false;
                            message = 'los cm2 del desperdicio no pieden ser menores o iguales a 0'
                        }
                    }else if(requiereField === 'cdi'){
                        if((property[requiereField] === '0' || property[requiereField] === '' || property[requiereField] === 0) && infoPlace.store === '21'){
                            save = false;
                        }
                    }
                    else{
                        if(property[requiereField] === '0' || property[requiereField] === '' || property[requiereField] === 0)  save = false;
                    }
                })
            })
            if(save) {
                return true;
            } else {
                setAlert({
                    title: 'Formulario incompleto',
                    message: message,
                    show: true
                });
                return false;
            }
        }
    }

    // Envio de formulario
    const handleSubmit = async () => {
        if (isFormValid()) {
            const request = bodyRequest();
            try{
                const response = await apiPostNewExit(infoPlace.type_movement, request);
                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "La salida ha sido agregado exitosamente",
                        icon: "success",
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componentes
                    onReloadComponent(idReloadCompB, idReloadCompC);
                    onReloadComponent(idReloadCompD);
                    cleanInfoPlace();
                }
            }catch(error){
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar salida',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }finally {
                // Cerrar modal
                setIsSubmitLoading(false);
                setShow(false);
            }
        }
    }

    const bodyRequest = () => {
        let req = {
            id_almacen: infoPlace.store,
            id_existencia: stock.id_existencia,
            id_personal: parseInt(infoPlace.id_personal),
            cantidad: parseInt(infoPlace.quantity),
            fecha_movimiento: moment(infoPlace.date).format('YYYY-MM-DD'),
            movimientos: []
        };
        const movements = infoPlace.properties.map( (el) => {
            let detail = {
                id_razon_movimiento: el.id_razon_movimiento,
                cantidad: parseInt(el.cantidad),
                medidas: [
                    {
                        valor: parseFloat(el.cm2),
                        id_medida: 4,
                        id_unidad: 7
                    }
                ]
            }
            const visibleFields = fields[el.id_razon_movimiento].visibleFields;
            for (let key in visibleFields){
                if(visibleFields[key]){
                    if (key === 'medidas'){
                        detail = {
                            ...detail,
                            medidas: [
                                {
                                    valor: parseFloat(el.largo) * parseFloat(el.alto),
                                    id_medida: 4,
                                    id_unidad: 7
                                },
                                {
                                    valor: parseFloat(el.largo),
                                    id_medida: 2,
                                    id_unidad: 6
                                },
                                {
                                    valor: parseFloat(el.alto),
                                    id_medida: 1,
                                    id_unidad: 6
                                }
                            ]
                        }
                    }else if(key === 'orden_trabajo'){
                        detail.orden_trabajo = `${el.orden_trabajo}-${el.version}`;
                    }else if(key === 'id_cliente'){
                        detail.id_cliente = parseInt(el.id_cliente) > 0 ? clients[parseInt(el.id_cliente)-1].id_cliente : null;
                        detail.nombre_cliente = parseInt(el.id_cliente) > 0 ? clients[parseInt(el.id_cliente)-1].nombre_cliente : null;
                    }else if(key === 'cdi'){
                        if(infoPlace.store === '21') detail[key] = el[key];
                    }else if(key === 'id_razones_movimiento_detalles' && (el[key] === '30' || el[key] === '31')){
                        detail.orden_trabajo = `${el.orden_trabajo}-${el.version}`;
                        detail.id_cliente = parseInt(el.id_cliente) > 0 ? clients[parseInt(el.id_cliente)-1].id_cliente : null;
                        detail.nombre_cliente = parseInt(el.id_cliente) > 0 ? clients[parseInt(el.id_cliente)-1].nombre_cliente : null;
                        if(infoPlace.store === '21') detail.cdi = el.cdi;
                        detail[key] = el[key];
                    }else{
                        detail[key] = el[key];
                    }
                }
            }
            return detail
        })
        return {
            ...req,
            movimientos: movements
        }
    }

    const cleanInfoPlace = () => {
        setInfoPlace({
            type_movement: '0',
            date: moment().format('YYYY-MM-DD'),
            store: '0',
            quantity: '0',
            rack: '0',
            level: '0',
            product: '0',
            area: '0',
            id_personal: '0',
            remaininArea: '0',  
            properties: [],
        })
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => {
                    // Borrar parametro de url
                    cleanInfoPlace();
                    setShow(false);
                }}
                modalTitle="Salidas"
                buttonTitle="Guardar Salida"
                handleClick={handleSubmit}
                size="xl"
                isButtonLoading={isSubmitLoading}
            >

                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="2" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="date" className="form-label">
                                            Fecha: *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="date"
                                            name="date"
                                            value={infoPlace.date}
                                            onChange={handleChangeInfoPlace}
                                        />
                                    </div>
                                </Col>
                                <Col xs="12" md="3">
                                    <div className="form-group">
                                        <label htmlFor="store" className="form-label">
                                            Almacén: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="store"
                                            id="store"
                                            value={infoPlace.store}
                                            onChange={handleChangeInfoPlace}
                                        >
                                            <option disabled value="0">Seleccione un almacén</option>
                                            {
                                                stores.map(({ glb_nombre, glb_id_almacen }, index) => (
                                                    <option value={glb_id_almacen} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs = "12" md = "3">
                                    <div className="form-group">
                                         <label htmlFor="type-movement" className ="form-label">
                                            Tipo de Movimiento: *
                                        </label>
                                        <select 
                                            name="type_movement" 
                                            id="type_movement" 
                                            className='form-select'
                                            value={infoPlace.type_movement}
                                            onChange={handleChangeInfoPlace}
                                        >
                                            <option disabled value="0">Selecione el tipo de movimiento:</option>
                                            {
                                                typeMovement.map( ({glb_id_tipo_salida, glb_nombre}, index) => (
                                                    <option value={glb_id_tipo_salida} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" md="3">
                                    <div className="form-group">
                                        <label htmlFor="producto_nombre" className="form-label">
                                            Producto: *
                                        </label>
                                        <select
                                            className="form-select"
                                            id="product"
                                            name="product"
                                            value={infoPlace.product}
                                            onChange={handleChangeInfoPlace}
                                            disabled={infoPlace.store === '0' || infoPlace.type_movement === '0'}
                                        >
                                            <option disabled value="0">Selecione el producto:</option>
                                            {
                                                products.map( ({id_producto, producto}, index) => (
                                                    <option value={id_producto} key={index}>
                                                        {producto}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" md="3">
                                    <div className="form-group">
                                        <label htmlFor="rack" className="form-label">
                                            Rack: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="rack"
                                            id="rack"
                                            value={infoPlace.rack}
                                            disabled={infoPlace.product === '0'}
                                            onChange={handleChangeInfoPlace}
                                        >
                                            <option disabled value="0">Seleccione un rack</option>
                                            {
                                                racks.map(({ nombre_rack, codigo_rack, id_rack }, index) => (
                                                    <option value={id_rack} key={index}>
                                                        {`${nombre_rack} - ${codigo_rack}`}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" md="3">
                                    <div className="form-group">
                                        <label htmlFor="level" className="form-label">
                                            Nivel: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="level"
                                            id="level"
                                            value={infoPlace.level}
                                            disabled={infoPlace.rack === '0'}
                                            onChange={handleChangeInfoPlace}
                                        >
                                            <option disabled value="0">Seleccione un nivel</option>
                                            {
                                                levels.map(({ nombre_nivel, codigo_ubicacion, id_almacen_ubicacion }, index) => (
                                                    <option value={id_almacen_ubicacion} key={index}>
                                                        {`${nombre_nivel} - ${codigo_ubicacion}`}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" md="3" hidden={infoPlace.type_movement === '0'}>
                                    <div className="form-group">
                                        <label htmlFor="caja" className="form-label">
                                            {infoPlace.type_movement === '1' ? 'Seleccione la caja *' : ' Medidas de la pedaceria: *'}
                                        </label>
                                        <select
                                            className="form-select"
                                            name="caja"
                                            id="caja"
                                            value={infoPlace.caja}
                                            disabled={infoPlace.level === '0'}
                                            onChange={handleChangeInfoPlace}
                                        >
                                            <option disabled value="0">{infoPlace.type_movement === '1' ? 'Seleccione una caja' : ' Medidas una pedaceria'}</option>
                                            {
                                                cajas.map(({ id, nombre }, index) => (
                                                    <option value={id} key={index}>
                                                        {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" md="3">
                                    <div className="form-group">
                                        <label htmlFor="quantity" className="form-label">
                                            Cantidad: *
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="quantity"
                                            name="quantity"
                                            value={infoPlace.quantity}
                                            max={stock.cantidad_actual}
                                            min='1'
                                            onChange={handleChangeQuantity}
                                            disabled={infoPlace.level === '0'}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" md="4">
                                    <div className="form-group">
                                        <label htmlFor="id_personal" className="form-label">
                                            Personal: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_personal"
                                            id="id_personal"
                                            value={infoPlace.id_personal}
                                            onChange={handleChangeInfoPlace}
                                        >
                                            <option disabled value="0">Seleccione un personal</option>
                                            {
                                                staffs.map(({ id_personal, nombre }, index) => (
                                                    <option value={id_personal} key={index}>
                                                        {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" md="4">
                                    <div className="form-group">
                                        <label htmlFor="area" className="form-label">
                                            cm² Totales:
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="area"
                                            name="area"
                                            value={infoPlace.area}
                                            disabled
                                        />
                                    </div>
                                </Col>
                                <Col xs="12" md="4">
                                    <div className="form-group">
                                        <label htmlFor="remaininArea" className="form-label">
                                            cm² Faltantes:
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            disabled
                                            value={infoPlace.remaininArea}
                                            name='remaininArea'
                                            id='remaininArea'
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            {
                                infoPlace.properties.map((element, index) => (
                                    <ItemRowProduct
                                        index={index}
                                        key={index}
                                        onChangeRow={handleChangeRow}
                                        onDeleteRow={handleDeleteRow}
                                        rowData={element}
                                        formIsShowed={show}
                                        stores={stores}
                                        storeSelected={infoPlace.store} 
                                        clientes={clients}
                                        onChangeRowOnlyNumbers={handleChangeRowOnlyNumbers}
                                        id_almacen = {infoPlace.store}
                                        onChangeCm2 = {handleChangeCm2}
                                    />
                                ))
                            }
                            <Row>
                                <Col>
                                    <Button
                                        variant="info"
                                        className='waves-effect waves-light'
                                        disabled={infoPlace.quantity === "0"}
                                        onClick={() => {
                                            setInfoPlace({
                                                ...infoPlace,
                                                properties:[
                                                    ...infoPlace.properties,
                                                    {
                                                        id_razon_movimiento: '0',
                                                        orden_trabajo: '',
                                                        version: '',
                                                        id_almacen: '0',
                                                        id_rack: '0',
                                                        id_almacen_ubicacion: '0',
                                                        cantidad: '0',
                                                        largo: '0',
                                                        alto: '0',
                                                        cm2: '0',
                                                        id_razones_movimiento_detalles: '0',
                                                        comentarios: '',
                                                        id_cliente: '0',
                                                        cdi: '0'
                                                    }
                                                ]
                                            })
                                        }}
                                    >
                                        <i className='si si-plus' /> Agregar Movimiento
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    )
}

// ----------------------------------------------------------------------------------------------------------
// ------------------ Item de Fila para nuevo producto-------------------------------------------------------
const ItemRowProduct = ({ 
    index,
    onChangeRow, 
    onDeleteRow,
    onChangeRowOnlyNumbers,
    rowData,
    formIsShowed,
    stores,
    storeSelected,
    clientes,
    id_almacen,
    onChangeCm2
}) => {
    const [movs, setMovs] = useState([]);
    const [racks, setRacks] = useState([]);
    const [levels, setLevels] = useState([]);
    const [details, setDetails] = useState([]);


    // Cargar datos
    useEffect ( () => {
        const loadData = async () => {
            try {
                const reqMovs = await apiGetReasonByType(2, '&status=1');
                setMovs([...reqMovs.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        if (formIsShowed)
            loadData()
        return () => loadData();
    }, [formIsShowed]);

    //Carga de detalles de la razon de movimiento
    useEffect( () => {
        const loadData = async () => {
            try{
                const detalles = await apiGetDetails(rowData.id_razon_movimiento);
                setDetails(detalles.data.content);
            }catch(e){
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        if(formIsShowed && fields[rowData.id_razon_movimiento].visibleFields.id_razones_movimiento_detalles)
        loadData();
    }, [formIsShowed, rowData.id_razon_movimiento])

    //carga de Rack por almacen 
    useEffect( () => {
        const loadData = async () => {
            try{
                let store;
                if(rowData.id_razon_movimiento === '14' && rowData.id_almacen !== '0'){
                    store = rowData.id_almacen
                }else if(rowData.id_razon_movimiento === '17'){
                    store = storeSelected;
                }
                if(store){
                  const reqRack = await apiGetAllRacks(store); 
                  setRacks([...reqRack.data.content]);
                }
            }catch(e){
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }   
        }
        if (formIsShowed && (rowData.id_razon_movimiento === '14' || rowData.id_razon_movimiento === '17'))
            loadData();
    }, [formIsShowed, rowData.id_razon_movimiento, rowData.id_almacen, storeSelected])

    //Carga de niveles
    useEffect( () => {
        const loadData = async () => {
            try{
                const reqLevels = await getRacksByStore(rowData.id_rack);
                setLevels([...reqLevels.data.content]);
            }catch(e){
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        if(formIsShowed && rowData.id_rack !== '0')
        loadData();
    }, [formIsShowed, rowData.id_rack])


    //-------------------------------------------------------------------------------------------------------//

    //Limpia racks y niveles
    useEffect( () => {
        setRacks([]);
        setLevels([]);
    },[formIsShowed, rowData.id_razon_movimiento])
    
    //FUNCION QUE SE ENCARGA DE OCULTAR LOS CAMPOS NO NECESARIOS POR LA RAZON DE MOVIMIENTO
    const hiddenField = (field) => {
        if(field === 'orden_trabajo' || field ===  'id_cliente'){
            if(fields[rowData.id_razon_movimiento].visibleFields[field] || rowData.id_razones_movimiento_detalles === '30' || rowData.id_razones_movimiento_detalles === '31') return false
            return true
        }
        if(field === 'cdi'){
            if((fields[rowData.id_razon_movimiento].visibleFields[field] ||  rowData.id_razones_movimiento_detalles === '30' || rowData.id_razones_movimiento_detalles === '31') && id_almacen === '21') return false
            return true
        }
        if(fields[rowData.id_razon_movimiento].visibleFields[field]) return false;
        return true
    }

    return (
        <div 
            style={{borderColor: `${backgroundColors[index % 6]}`}}
            className='rowProduct'
        >
            <Row>
                <Col xs="3">
                    <div className="form-group">
                        <label htmlFor="id_razon_movimiento" className="form-label">
                            Razón Movimiento: *
                        </label>
                        <select 
                            name="id_razon_movimiento" 
                            id="id_razon_movimiento" 
                            className='form-select'
                            value={rowData.id_razon_movimiento}
                            index={index}
                            onChange={onChangeRow}
                        >
                            <option disabled value="0">Selecione razón movimiento:</option>
                            {
                                movs.map( ({id_razon_movimiento, nombre}, index) => (
                                    <option value={id_razon_movimiento} key={index}>
                                        {nombre}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </Col>
                <Col 
                    xs='2'
                    hidden ={hiddenField('id_razones_movimiento_detalles')}
                >
                    <div className='form-group'>
                        <label htmlFor="id_razones_movimiento_detalles" className='form-label'>
                            Detalle*:
                        </label>
                        <select
                            className="form-select"
                            name="id_razones_movimiento_detalles"
                            id="id_razones_movimiento_detalles"
                            value={rowData.id_razones_movimiento_detalles}
                            index={index}
                            onChange={onChangeRow}
                            
                        >
                            <option disabled value="0">Seleccione el detalle</option>
                                {
                                    details.map(({ id_razones_movimiento_detalles, nombre }, index) => (
                                        <option value={id_razones_movimiento_detalles} key={index}>
                                            {nombre}
                                        </option>
                                    ))
                                }
                        </select>
                    </div>
                </Col>
                <Col 
                    xs="12" md="2"
                    hidden ={hiddenField('id_cliente')}
                >
                    <div className="form-group">
                        <label htmlFor="id_cliente" className="form-label">
                            Cliente: *
                        </label>
                        <select
                            className="form-select"
                            name="id_cliente"
                            id="id_cliente"
                            value={rowData.id_cliente}
                            onChange={onChangeRow}
                            index={index}
                        >
                             <option disabled value="0">Seleccione un cliente</option>
                            {
                                clientes.map( ({nombre_cliente}, index) => (
                                    <option value={index+1} key={index}>
                                        {nombre_cliente}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </Col>
                <Col 
                    xs="2"
                    hidden ={hiddenField('orden_trabajo')}
                >
                    <div className="form-group">
                        <label htmlFor="orden_trabajo" className="form-label">
                            Orden de Trabajo: *
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="orden_trabajo"
                            name="orden_trabajo"
                            value={rowData.orden_trabajo}
                            onChange={onChangeRowOnlyNumbers}
                            index={index}
                            pattern="[0-9]{0,7}"
                        />
                    </div>
                </Col>
                <Col 
                    xs="1"
                    hidden ={hiddenField('orden_trabajo')}
                >
                    <div className="form-group">
                        <label htmlFor="version" className="form-label">
                            Version: *
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="version"
                            name="version"
                            value={rowData.version}
                            onChange={onChangeRowOnlyNumbers}
                            index={index}
                            pattern="[0-9]{0,1}"
                            
                        />
                    </div>
                </Col>
                <Col 
                    xs='2'
                    hidden ={hiddenField('cdi')}
                >
                    <div className='form-group'>
                        <label htmlFor="cdi" className='form-label'>
                            cdi*:
                        </label>
                        <select
                            className="form-select"
                            name="cdi"
                            id="cdi"
                            value={rowData.cdi}
                            index={index}
                            onChange={onChangeRow}
                            
                        >
                            <option disabled value="0">Seleccione el cdi</option>
                            <option value='A'>A</option>
                            <option value='B'>B</option>
                            <option value='C'>C</option>
                        </select>
                    </div>
                </Col>
                <Col 
                    xs="12" md="3"
                    hidden ={hiddenField('id_almacen')} 
                >
                    <div className="form-group">
                        <label htmlFor="id_almacen" className="form-label">
                            Almacén: *
                        </label>
                        <select
                            className="form-select"
                            name="id_almacen"
                            id="id_almacen"
                            value={rowData.id_almacen}
                            index={index}
                            onChange={onChangeRow}
                            
                        >
                            <option disabled value="0">Seleccione un almacen</option>
                                {
                                    stores.map(({ glb_nombre, glb_id_almacen }, index) => (
                                        <option value={glb_id_almacen} key={index}>
                                            {glb_nombre}
                                        </option>
                                    ))
                                }
                        </select>
                    </div>
                </Col>
                <Col 
                    xs="12" md="2"
                    hidden ={hiddenField('id_rack')}
                >
                    <div className="form-group">
                        <label htmlFor="id_rack" className="form-label">
                            Rack: *
                        </label>
                        <select
                            className="form-select"
                            name="id_rack"
                            id="id_rack"
                            value={rowData.id_rack}
                            onChange={onChangeRow}
                            index={index}
                        >
                            <option disabled value="0">Seleccione un rack</option>
                            { 
                                racks.map(({ nombre, codigo_rack, id_rack }, index) => (
                                    <option value={id_rack} key={index}>
                                        {`${nombre} - ${codigo_rack}`}
                                    </option>
                                )) 
                            }
                        </select>
                    </div>
                </Col>
                <Col 
                    xs="12" md="2"
                    hidden ={hiddenField('id_almacen_ubicacion')}
                >
                    <div className="form-group">
                        <label htmlFor="id_almacen_ubicacion" className="form-label">
                            Nivel: *
                        </label>
                        <select
                            className="form-select"
                            name="id_almacen_ubicacion"
                            id="id_almacen_ubicacion"
                            value={rowData.id_almacen_ubicacion}
                            onChange={onChangeRow}
                            index={index}
                        >
                            <option disabled value="0">Seleccione un nivel</option>
                            {
                                levels.map( ({glb_id_almacen_ubicacion, glb_nombre, glb_codigo_ubicacion}, index) => (
                                    <option value={glb_id_almacen_ubicacion} key={index}>
                                        {`${glb_nombre} - ${glb_codigo_ubicacion}`}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="12" md="2">
                    <div className="form-group">
                        <label htmlFor="cantidad" className="form-label">
                            Cantidad: *
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="cantidad"
                            name="cantidad"
                            value={rowData.cantidad}
                            onChange={onChangeRow}
                            min='1'
                            index={index}
                            disabled = {fields[rowData.id_razon_movimiento].visibleFields.cantidad}
                        />
                    </div>
                </Col>
                <Col 
                    xs="12" 
                    md="1"
                    hidden ={hiddenField('medidas')}
                >
                    <div className="form-group">
                        <label htmlFor="largo" className="form-label">
                            Largo: *
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="largo"
                            name="largo"
                            value={rowData.largo}
                            onChange={onChangeRow}
                            index={index}
                            min="1"
                        />
                    </div>
                </Col>
                <Col 
                    xs="12" 
                    md="1"
                    hidden ={hiddenField('medidas')}
                >
                    <div className="form-group">
                        <label htmlFor="alto" className="form-label">
                            Alto: *
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="alto"
                            name="alto"
                            value={rowData.alto}
                            onChange={onChangeRow}
                            index={index}
                            min="1"
                        />
                    </div>
                </Col>
                <Col xs="12" md="2">
                    <div className="form-group">
                        <label htmlFor="cm2" className="form-label">
                            cm²:
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="cm2"
                            name="cm2"
                            value={rowData.cm2}
                            index={index}
                            disabled={!hiddenField('cm2')}
                            onChange={onChangeCm2}
                        />
                    </div>
                </Col>
                <Col 
                    xs="3"
                    hidden ={hiddenField('comentarios')}
                >
                    <div className="form-group">
                        <label htmlFor="comentarios" className="form-label">
                            Comentario: *
                        </label>
                        <textarea
                            className="form-control"
                            id="comentarios"
                            name="comentarios"
                            value={rowData.comentarios}
                            onChange={onChangeRow}
                            index={index}
                        />
                    </div>
                </Col>
                <Col xs="12" md="2" style={{ paddingTop: "30px" }}>
                    <OverlayTooltip
                        placement="top"
                        tooltip="Eliminar"
                    >
                        <Button
                            size="sm"
                            variant="danger"
                            className='waves-effect waves-light'
                            onClick={onDeleteRow}
                            index={index}
                        >
                            <i className='si si-trash' />
                        </Button>
                    </OverlayTooltip>
                </Col>
            </Row>
        </div>
    )
}

export default AddExitPlate;