import { useEffect, useState, useContext } from 'react';
// Componentes
import {
    Container,
    Row,
    Col,
    ToastContainer,
    Form,
    InputGroup,
    FormControl,
    Button
} from 'react-bootstrap';
// Componentes Generales
import ToastComponent from './../components/Toast/toast.component';
import Load from '../components/Load/load.component';
// Imagenes
import LogoLetters from '../assets/images/inventarios_feraz_login_titulo.png'
import Icon1 from '../assets/images/inventarios_feraz_login_1.png';
import Icon2 from '../assets/images/inventarios_feraz_login_2.png';
import Icon3 from '../assets/images/inventarios_feraz_login_3.png';
import Icon4 from '../assets/images/inventarios_feraz_login_4.png';
import Icon5 from '../assets/images/inventarios_feraz_login_5.png';
import Icon6 from '../assets/images/inventarios_feraz_login_6.png';
import Icon7 from '../assets/images/inventarios_feraz_login_7.png';
// Context
import AuthContext from '../context/auth/auth.context';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';


const LoginPage = ({ history }) => {
    
    const [isFirstTime, setIsFirstTime] = useState(true);
    // Extraer valores del context
    const { loginUser, isAuth, errorMessage, silenceLogin, isUserLoading } = useContext(AuthContext);

    // Objeto para login
    const [loginData, setLoginData] = useState({
        user: '',
        password: ''
    });

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Funcion para modificar el objeto de datos
    const handleChangeInput = e => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value,
        });
    }

    // Funcion para validacion de campos en objeto de datos
    const validateForm = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        // Validar usuario
        if (loginData.user === '') {
            setAlert({
                title: 'Datos incompletos',
                message: 'El usuario es requerido',
                show: true
            });
            return false;
        }
        // Validar contraseña
        if (loginData.password === '') {
            setAlert({
                title: 'Datos incompletos',
                message: 'La contraseña es requerida',
                show: true
            });
            return false;
        }
        if (loginData.password.length < 4) {
            setAlert({
                title: 'Contraseña incompleta',
                message: 'Se requieren de al menos 4 caracteres',
                show: true
            });
        }
        // Paso todas las validaciones
        return true;
    }

    // Funcion para ingreso
    const handleLogIn = async e => {
        // Validar formulario de ingreso
        if (validateForm()) {
            // Pasar datos al action
            await loginUser(loginData);
            await serviceWorkerRegistration.register();
        }
    }

    useEffect(() => {
        // Ha sido autenticado
        if (isAuth) {
            history.push('/dashboard');
        }
        if (isFirstTime) {
            silenceLogin();
            setIsFirstTime(false);
        } else {
            if (errorMessage) {
                setAlert({
                    title: 'Verifica tus datos',
                    message: errorMessage ?? 'Intentalo de nuevo',
                    show: true
                });
            }
        }
    }, [isAuth, setIsFirstTime, isFirstTime, silenceLogin, errorMessage, history]);

    if (isUserLoading) {
        return (
            <main className="dark-skin">
                <div className="wrapper d-flex flex-column justify-content-center" style={{
                    height: "100vh",
                }}>
                    <Load size={150} />
                </div>
            </main >
        )
    }
    return (
        <main className="section-full-heigth" >
            <section
                className="theme-primary bg-img section-full-parent">
                <ToastContainer className="p-3" position="top-end">
                    <ToastComponent
                        type="danger"
                        title={alert.title}
                        message={alert.message}
                        show={alert.show}
                        onClose={() => setAlert({
                            ...alert,
                            show: false,
                        })}
                    />
                </ToastContainer>
                <Container
                    className="h-p100">
                    <Row
                        className="h-p100 align-items-center">
                        <Col xs="12">
                            <Row className="justify-content-center g-0">
                                <Col
                                    xs
                                    lg="5"
                                    md="5"
                                    className="bg-white rounded10 shadow-lg"
                                >
                                    <div
                                        className="d-flex flex-column mt-60"
                                        style={{ alignItems: "center" }}
                                    >
                                        <img
                                            src={LogoLetters}
                                            alt="Logo Principal"
                                            width="70%"
                                        />
                                        <div className="d-flex mt-40" style={{gap: "12px"}}>
                                            <div style={{ flex: "1" }}>
                                                <img
                                                    src={Icon1}
                                                    alt="Icono1"
                                                />
                                            </div>
                                            <div style={{ flex: "1" }}>
                                                <img
                                                    src={Icon2}
                                                    alt="Icono2"
                                                />
                                            </div>
                                            <div style={{ flex: "1" }}>
                                                <img
                                                    src={Icon3}
                                                    alt="Icono3"
                                                />
                                            </div>
                                            <div style={{ flex: "1" }}>
                                                <img
                                                    src={Icon4}
                                                    alt="Icono4"
                                                />
                                            </div>
                                            <div style={{ flex: "1" }}>
                                                <img
                                                    src={Icon5}
                                                    alt="Icono5"
                                                />
                                            </div>
                                            <div style={{ flex: "1" }}>
                                                <img
                                                    src={Icon6}
                                                    alt="Icono6"
                                                />
                                            </div>
                                            <div style={{ flex: "1" }}>
                                                <img
                                                    src={Icon7}
                                                    alt="Icono7"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Form className="p-40">
                                        <Form.Group>
                                            <Form.Label>
                                                Nombre de Usuario
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text className="bg-transparent">
                                                    <i className="si-user si"></i>
                                                </InputGroup.Text>
                                                <FormControl
                                                    type="text"
                                                    size="lg"
                                                    className="ps-15 bg-transparent"
                                                    placeholder="Usuario"
                                                    name="user"
                                                    onChange={handleChangeInput}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>
                                                Contraseña
                                            </Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text className="bg-transparent">
                                                    <i className="si-lock si"></i>
                                                </InputGroup.Text>
                                                <FormControl
                                                    type="password"
                                                    size="lg"
                                                    className="ps-15 bg-transparent"
                                                    placeholder="Contraseña"
                                                    name="password"
                                                    onChange={handleChangeInput}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Row>
                                            <Col xs className="text-center">
                                                <Button
                                                    variant="info"
                                                    className="mt-10"
                                                    onClick={handleLogIn}
                                                >
                                                    INGRESAR
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section >
        </main >
    );
}

export default LoginPage;