import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
import SelectItemContext from "../../../context/selectItem/selectItem.context";
// Conexion API
import {
    apiGetStores,
    apiGetAllRacks,
    apiGetLevelsWithRack,
    apiGetEntriesbyLevel,
    apiGetEntryById,
    apiGetEntryExtraById,
    apiPostNewExistence,
    apiGetLevelId,
    apiGetRackId,
} from '../../../common/api-invent';

const AddEntranceByLevel = ({ show, setShow, id }) => {
    // Recarga de componente
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 24;
    // Cargar con nivel
    const { extraParams, onDeleteExtraParam } = useContext(SelectItemContext);

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [stores, setStores] = useState([]);
    const [racks, setRacks] = useState([]);
    const [levels, setLevels] = useState([]);
    const [entries, setEntries] = useState([]);

    // Hook para formulario
    const [data, setData] = useState({
        id_entrada: '0',
        id_almacen_ubicacion: '0',
    });
    // Datos para nivel
    const [infoPlace, setInfoPlace] = useState({
        store: '0',
        rack: '0',
    });
    // Datos para producto
    const [infoProduct, setInfoProduct] = useState({
        producto_nombre: 'Seleccione un producto',
        codigo_caja: 'Seleccione un producto',
        codigo_tarima: 'Seleccione un producto',
        lote: 'Seleccione un producto',
    });

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Escuchar cambio en id
    useEffect(() => {
        setData(data => ({ ...data, id_entrada: id }));
    }, [id]);

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de almacenes
                const reqStores = await apiGetStores();
                setStores([...reqStores.data.content]);
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        if (show)
            loadData()
        return () => setLoading(false);
    }, [show, id, extraParams]);

    // Abrir modal desde url
    useEffect(() => {
        const loadData = async (idLevel) => {
            try {
                setShow(true);
                setLoading(true);
                // Solicitar datos de lugar
                const reqLevel = await apiGetLevelId(idLevel);
                const idRack = reqLevel.data.content?.id_rack;
                const reqRack = await apiGetRackId(idRack);
                const idStore = reqRack.data.content?.id_almacen;
                // Cargar informacion
                setInfoPlace({
                    store: idStore,
                    rack: idRack,
                });
                setData(data => ({
                    ...data,
                    id_almacen_ubicacion: idLevel,
                }))
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        if (extraParams && extraParams[1] && extraParams[1] !== 0) {
            const idlevelAux = extraParams[1];
            loadData(idlevelAux)
        }
    }, [extraParams, setShow])
    // Llena el arreglo de datos con los datos del formulario
    const handleChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }
    // Cambio en informacion de lugar
    const handleChangeInfoPlace = e => {
        setInfoPlace({
            ...infoPlace,
            [e.target.name]: e.target.value
        });
    }

    // Cargar racks
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de racks
                const reqRacks = await apiGetAllRacks(infoPlace.store);
                setRacks([...reqRacks.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData()
        return () => setLoading(false);
    }, [show, infoPlace.store]);
    // Cargar Niveles
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de racks
                const reqLevels = await apiGetLevelsWithRack(infoPlace.rack);
                setLevels([...reqLevels.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`);
                setLevels([]);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData()
        return () => setLoading(false);
    }, [show, infoPlace.rack]);
    // Cargar Productos a acomodar
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de racks
                const reqEntries = await apiGetEntriesbyLevel(data.id_almacen_ubicacion);
                setEntries([...reqEntries.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                setEntries([]);
            } finally {
                setLoading(false);
            }
        }
        if (show && data.id_almacen_ubicacion !== '0')
            loadData()
        else if (data.id_almacen_ubicacion === '0')
            setEntries([]);

        return () => setLoading(false);
    }, [show, data.id_almacen_ubicacion]);
    // Cargar Informacion de producto
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de producto
                const reqEntry = await apiGetEntryById(data.id_entrada);
                const reqEntryExtra = await apiGetEntryExtraById(data.id_entrada);
                setInfoProduct(currentData => ({
                    ...currentData,
                    producto_nombre: reqEntry.data.content.producto,
                    codigo_caja: reqEntry.data.content.codigo_caja,
                    codigo_tarima: reqEntryExtra.data.content.codigo_tarima,
                    lote: reqEntry.data.content.lote,
                }))
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
            } finally {
                setLoading(false);
            }
        }
        if (show && data.id_entrada !== '0' && data.id_entrada)
            loadData()
        else if (data.id_entrada === '0') {
            setInfoProduct({
                producto_nombre: 'Seleccione un producto',
                codigo_caja: 'Seleccione un producto',
                codigo_tarima: 'Seleccione un producto',
                lote: 'Seleccione un producto',
            })
        }
        return () => setLoading(false);
    }, [show, data.id_entrada]);

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.id_almacen_ubicacion === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nivel para almacenear',
                show: true
            });
            return false;
        }
        if (data.id_entrada === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere producto',
                show: true
            });
            return false;
        }
        return true;
    }

    // Envio de formulario
    const handleSubmit = async () => {
        if (isFormValid()) {
            try {
                const response = await apiPostNewExistence(data);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "La entrada ha sido agregado exitosamente",
                        icon: "success",
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Limpiar datos
                    setData({
                        id_entrada: '0',
                        id_almacen_ubicacion: '0',
                    });
                    setInfoPlace({
                        store: '0',
                        rack: '0',
                    });
                    setInfoProduct({
                        producto_nombre: '',
                        codigo_caja: '',
                        codigo_tarima: '',
                        lote: '',
                    });
                    // Borrar parametro de url
                    onDeleteExtraParam(1);
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al agregar entrada");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar entrada',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }


    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => {
                    // Borrar parametro de url
                    onDeleteExtraParam(1);
                    setShow(false);
                }}
                modalTitle="Almacenar en Nivel"
                buttonTitle="Almacenar"
                handleClick={handleSubmit}
                size="md"
            >

                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col xs="12">
                                    <div className="form-group">
                                        <label htmlFor="store" className="form-label">
                                            Almacén: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="store"
                                            id="store"
                                            value={infoPlace.store}
                                            onChange={handleChangeInfoPlace}
                                        >
                                            <option value="0">Seleccione un almacen</option>
                                            {
                                                stores.map(({ glb_nombre, glb_id_almacen }, index) => (
                                                    <option value={glb_id_almacen} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" md="6">
                                    <div className="form-group">
                                        <label htmlFor="rack" className="form-label">
                                            Rack: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="rack"
                                            id="rack"
                                            value={infoPlace.rack}
                                            onChange={handleChangeInfoPlace}
                                            disabled={infoPlace.store === '0'}
                                        >
                                            <option value="0">Seleccione un rack</option>
                                            {
                                                racks.map(({ nombre, codigo_rack, id_rack }, index) => (
                                                    <option value={id_rack} key={index}>
                                                        {`${nombre} - ${codigo_rack}`}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" md="6">
                                    <div className="form-group">
                                        <label htmlFor="id_almacen_ubicacion" className="form-label">
                                            Nivel: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_almacen_ubicacion"
                                            id="id_almacen_ubicacion"
                                            value={data.id_almacen_ubicacion}
                                            onChange={handleChange}
                                            disabled={infoPlace.rack === '0'}
                                        >
                                            <option value="0">Seleccione un nivel</option>
                                            {
                                                levels.map(({ nombre, codigo_ubicacion, id_almacen_ubicacion }, index) => (
                                                    <option value={id_almacen_ubicacion} key={index}>
                                                        {`${nombre} - ${codigo_ubicacion}`}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="12" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_entrada" className="form-label">
                                            Producto a almacenar: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_entrada"
                                            id="id_entrada"
                                            value={data.id_entrada}
                                            onChange={handleChange}
                                        >
                                            {
                                                entries.length === 0
                                                    ? <option value="0">No hay productos disponibles :(</option>
                                                    : <>
                                                        <option value="0">Seleccione un producto</option>
                                                        {
                                                            entries.map(({ nombre, codigo_proveedor, id_entrada }, index) => (
                                                                <option value={id_entrada} key={index}>
                                                                    {`${nombre} - ${codigo_proveedor}`}
                                                                </option>
                                                            ))
                                                        }
                                                    </>
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="producto_nombre" className="form-label">
                                            Producto:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="producto_nombre"
                                            name="producto_nombre"
                                            value={infoProduct.producto_nombre}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="codigo_caja" className="form-label">
                                            Codigo de Embalaje:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="codigo_caja"
                                            name="codigo_caja"
                                            value={infoProduct.codigo_caja}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="codigo_tarima" className="form-label">
                                            Codigo de Tarima:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="codigo_tarima"
                                            name="codigo_tarima"
                                            value={infoProduct.codigo_tarima}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="lote" className="form-label">
                                            Lote:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lote"
                                            name="lote"
                                            value={infoProduct.lote}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                            </Row>

                        </Form>
                }
            </Modals>
        </>
    )
}

export default AddEntranceByLevel;