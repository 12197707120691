import { useReducer } from "react";
// Contexto
import SelectItemContext, { selectItem_types } from "./selectItem.context";
import SelectItemReducer from "./selectItem.reducer";

const SelectItemState = ({ children }) => {
    const initialState = {
        lastVisitedPage: localStorage.getItem('last_visited_page') ?? process.env.REACT_APP_PAGE_HOME,
        extraParams: JSON.parse(localStorage.getItem('extra_url_params')) ?? {},
        selectedProduct: 0,
        selectedStore: 0,
        selectedRack: 0,
        selectedLevel: 0,
        selectedProvider: 0,
        selectedProviderCosts: 0,
        selectedListPrices: 0,
        selectedBillProds: 0,
        selectedProcessingBill: 0,
        selectedPhysicalInvent: 0,
        selectedExit: 0,
        selectedExitDetails: 0,
        selectedScrap: 0,
        selectedScrapDetails: 0,
    }
    const [state, dispatch] = useReducer(SelectItemReducer, initialState);

    // Guardar ubicacion de pagina
    const onSaveLastPage = (id) => {
        dispatch({
            type: selectItem_types.NAVIGATE_PAGE,
            payload: {
                id,
            }
        })
    }

    // Guardar parametro URL extra
    const onSaveExtraParam = (number, value) => {
        dispatch({
            type: selectItem_types.ADD_EXTRA_PARAM,
            payload: {
                number,
                value,
            }
        })
    }
    
    // Remover, parametro extra
    const onDeleteExtraParam = (number) => {
        dispatch({
            type: selectItem_types.REMOVE_EXTRA_PARAM,
            payload: {
                number,
            }
        })
    }

    // Seleccionar un producto
    const onSelectProduct = (id) => {
        dispatch({
            type: selectItem_types.SELECT_PRODUCT,
            payload: {
                id,
            }
        })
    }

    // Seleccionar almacen
    const onSelectStore = (id) => {
        dispatch({
            type: selectItem_types.SELECT_STORE,
            payload: {
                id,
            }
        })
    }

    // Seleccionar Rack
    const onSelectRack = (id) => {
        dispatch({
            type: selectItem_types.SELECT_RACK,
            payload: {
                id,
            }
        })
    }

    // Seleccionar Nivel
    const onSelectLevel = (id) => {
        dispatch({
            type: selectItem_types.SELECT_LEVEL,
            payload: {
                id,
            }
        })
    }

    // Seleccionar Proveedor
    const onSelectProvider = (id) => {
        dispatch({
            type: selectItem_types.SELECT_PROVIDER,
            payload: {
                id,
            }
        })
    }

    // Seleccionar Proveedor
    const onSelectProviderCosts = (id) => {
        dispatch({
            type: selectItem_types.SELECT_PROVIDERCOSTS,
            payload: {
                id,
            }
        })
    }

    // Seleccionar Lista de Precio
    const onSelectListPrice = (id) => {
        dispatch({
            type: selectItem_types.SELECT_LISTPRICES,
            payload: {
                id,
            }
        });
    }

    // Seleccionar Factura
    const onSelectBillProds = (id) => {
        dispatch({
            type: selectItem_types.SELECT_BILLPRODS,
            payload: {
                id,
            }
        });
    }

    // Seleccionar Factura por recibir (pendiente)
    const onSelectProcessingBill = (id) => {
        dispatch({
            type: selectItem_types.SELECT_PROCESSSINGBILL,
            payload: {
                id,
            }
        });
    }

    // Seleccionar Inventario Fisico
    const onSelectPhysicalInvent = (id) => {
        dispatch({
            type: selectItem_types.SELECT_PHYSICALINVENT,
            payload: {
                id,
            }
        });
    }

    // Seleccionar Salida
    const onSelectExit= (id) => {
        dispatch({
            type: selectItem_types.SELECT_EXIT,
            payload: {
                id,
            }
        });
    }

    // Seleccionar Detalle de Salida
    const onSelectExitPlate= (id) => {
        dispatch({
            type: selectItem_types.SELECT_EXITPLATE,
            payload: {
                id,
            }
        });
    }

    // Seleccionar Medidas de Salida
    const onSelectExitDetails= (id) => {
        dispatch({
            type: selectItem_types.SELECT_EXITDETAILS,
            payload: {
                id,
            }
        });
    }

    // Seleccionar Pedaceria
    const onSelectScrap= (id) => {
        dispatch({
            type: selectItem_types.SELECT_SCRAP,
            payload: {
                id,
            }
        });
    }

    // Seleccionar Medida de Pedaceria
    const onSelectScrapDetails= (id) => {
        dispatch({
            type: selectItem_types.SELECT_SCRAPDETAILS,
            payload: {
                id,
            }
        });
    }

    // --------------------------------------------
    return (
        <SelectItemContext.Provider
            value={{
                lastVisitedPage: state.lastVisitedPage,
                extraParams: state.extraParams,
                selectedProduct: state.selectedProduct,
                selectedStore: state.selectedStore,
                selectedRack: state.selectedRack,
                selectedLevel: state.selectedLevel,
                selectedProvider: state.selectedProvider,
                selectedProviderCosts: state.selectedProviderCosts,
                selectedListPrices: state.selectedListPrices,
                selectedBillProds: state.selectedBillProds,
                selectedProcessingBill: state.selectedProcessingBill,
                selectedPhysicalInvent: state.selectedPhysicalInvent,
                selectedExit: state.selectedExit,
                selectedExitPlate: state.selectedExitPlate,
                selectedExitDetails: state.selectedExitDetails,
                selectedScrap: state.selectedScrap,
                selectedScrapDetails: state.selectedScrapDetails,
                onSaveLastPage,
                onSaveExtraParam,
                onDeleteExtraParam,
                onSelectProduct,
                onSelectStore,
                onSelectRack,
                onSelectLevel,
                onSelectProvider,
                onSelectProviderCosts,
                onSelectListPrice,
                onSelectBillProds,
                onSelectProcessingBill,
                onSelectPhysicalInvent,
                onSelectExit,
                onSelectExitPlate,
                onSelectExitDetails,
                onSelectScrap,
                onSelectScrapDetails,
            }}
        >
            {children}
        </SelectItemContext.Provider>
    )
}
export default SelectItemState;