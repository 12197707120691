import { useState, useContext, useEffect } from "react";
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from "sweetalert2";
import moment from "moment";
import { Dropzone, FileItem } from "@dropzone-ui/react";
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
import SelectItemContext from "../../../context/selectItem/selectItem.context";
// Conexion con api
import {
    apiGetStores,
    apiGetAllRacks,
    apiGetLevelsWithRack,
    apiGetExitsByLevelPerPage,
    apiPostNewDocumentExit,
} from '../../../common/api-invent';

const AddExitDocument = ({ show, setShow }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 31;
    const { onSelectExit } = useContext(SelectItemContext);

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [stores, setStores] = useState([]);
    const [racks, setRacks] = useState([]);
    const [levels, setLevels] = useState([]);
    const [exits, setExits] = useState([]);

    // Hook de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });
    // Hook de formulario
    const [data, setData] = useState({
        id_salida: '0',
        descripcion: '',
        archivos: []
    });
    // Datos para nivel
    const [infoPlace, setInfoPlace] = useState({
        store: '0',
        rack: '0',
        id_level: '0',
    });

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de almacenes
                const reqStores = await apiGetStores();
                setStores([...reqStores.data.content]);
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setStores([]);
            setLoading(false);
        }
    }, [setStores, setLoading, show]);
    // Cargar racks
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de racks
                const reqRacks = await apiGetAllRacks(infoPlace.store);
                setRacks([...reqRacks.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData()
        return () => setLoading(false);
    }, [show, infoPlace.store]);
    // Cargar Niveles
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de racks
                const reqLevels = await apiGetLevelsWithRack(infoPlace.rack);
                setLevels([...reqLevels.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`);
                setLevels([]);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData()
        return () => setLoading(false);
    }, [show, infoPlace.rack]);
    // Cargar Salidas de un nivel
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de salidas
                const reqExits = await apiGetExitsByLevelPerPage(infoPlace.id_level);
                setExits([...reqExits.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                setExits([]);
            } finally {
                setLoading(false);
            }
        }
        if (show && infoPlace.id_level !== '0')
            loadData()
        else if (infoPlace.id_level === '0')
            setExits([]);

        return () => { setExits([]); };
    }, [show, infoPlace.id_level]);

    // Cambio en informacion de lugar
    const handleChangeInfoPlace = e => {
        setInfoPlace({
            ...infoPlace,
            [e.target.name]: e.target.value
        });
    }

    // Cambio en informacion de formulario
    const handleDataChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    const updateFiles = (files) => {
        setData({
            ...data,
            archivos: files
        })
    };

    const isFormValid = () => {
        if (data.id_salida === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere producto',
                show: true
            });
            return false;
        }
        if (data.archivos.length === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requieren archivos',
                show: true
            });
            return false;
        }
        return true;
    }
    // Boton de agregar en formulario
    const _handleSubmit = async (closeModal = true) => {
        if (isFormValid()) {
            try {
                // Crear objeto para enviar datos
                const sendData = new FormData();
                const sendFiles = data.archivos.map(({ file }) => (file));
                sendData.append('id_salida', data.id_salida);
                sendData.append('descripcion', data.descripcion);
                sendData.append('archivos', sendFiles[0]);
                const response = await apiPostNewDocumentExit(sendData);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: 'Tu documento ha sido agregada exitosamente',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onSelectExit(data.id_salida);
                    onReloadComponent(idReloadComp);
                    // Limpiar datos
                    setData({
                        id_salida: '0',
                        descripcion: '',
                        archivos: []
                    });
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar documento");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nuevo documento',
                    text: error.response.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(true);
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="md"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Agregar Documento de Salida"
                buttonTitle="Agregar"
                handleClick={handleSaveClose}
            >{
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col xs="12" md="6">
                                    <div className="form-group">
                                        <label htmlFor="store" className="form-label">
                                            Almacén: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="store"
                                            id="store"
                                            value={infoPlace.store}
                                            onChange={handleChangeInfoPlace}
                                        >
                                            <option value="0">Seleccione un almacen</option>
                                            {
                                                stores.map(({ glb_nombre, glb_id_almacen }, index) => (
                                                    <option value={glb_id_almacen} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" md="6">
                                    <div className="form-group">
                                        <label htmlFor="rack" className="form-label">
                                            Rack: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="rack"
                                            id="rack"
                                            value={infoPlace.rack}
                                            onChange={handleChangeInfoPlace}
                                            disabled={infoPlace.store === '0'}
                                        >
                                            <option value="0">Seleccione un rack</option>
                                            {
                                                racks.map(({ nombre, codigo_rack, id_rack }, index) => (
                                                    <option value={id_rack} key={index}>
                                                        {`${nombre} - ${codigo_rack}`}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" md="6">
                                    <div className="form-group">
                                        <label htmlFor="id_level" className="form-label">
                                            Nivel: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_level"
                                            id="id_level"
                                            value={infoPlace.id_level}
                                            onChange={handleChangeInfoPlace}
                                            disabled={infoPlace.rack === '0'}
                                        >
                                            <option value="0">Seleccione un nivel</option>
                                            {
                                                levels.map(({ nombre, codigo_ubicacion, id_almacen_ubicacion }, index) => (
                                                    <option value={id_almacen_ubicacion} key={index}>
                                                        {`${nombre} - ${codigo_ubicacion}`}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="12" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_salida" className="form-label">
                                            Producto: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_salida"
                                            id="id_salida"
                                            value={data.id_salida}
                                            onChange={handleDataChange}
                                        >
                                            {
                                                exits.length === 0
                                                    ? <option value="0">No hay productos disponibles :(</option>
                                                    : <>
                                                        <option value="0">Seleccione un producto</option>
                                                        {
                                                            exits.map(({ nombre, fecha_registro, id_salida }, index) => (
                                                                <option value={id_salida} key={index}>
                                                                    {`${nombre} - ${moment(fecha_registro).format("DD/MMM/YYYY")}`}
                                                                </option>
                                                            ))
                                                        }
                                                    </>
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="12" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="descripcion" className="form-label">
                                            Descripción:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="descripcion"
                                            name="descripcion"
                                            value={data.descripcion}
                                            onChange={handleDataChange}
                                            placeholder="Observación"
                                        />
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="form-group">
                                        <label htmlFor="archivos" className="form-label">
                                            Documento: (pdf, doc, docx, xls, xlsx, csv, ppt, pptx)*
                                        </label>
                                        <Dropzone
                                            accept="application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx"
                                            onChange={updateFiles}
                                            value={data.archivos}
                                            footer={false}
                                            localization="ES-es"
                                            maxFiles={1}
                                        >
                                            {data.archivos.map((file, index) => (
                                                <FileItem
                                                    {...file}
                                                    localization="ES-es"
                                                    key={index}
                                                    preview
                                                />
                                            ))}
                                        </Dropzone>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    )
}

export default AddExitDocument