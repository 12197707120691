import { Col } from 'react-bootstrap';
import { SmallDetailBox } from '../SmallDetailBox/SmallDetailBox';


const fondos = ['#6cbbfa ', '#00875a', '#fbc66c ', '#fb5ea8']
export const CardTotales = ({totales}) => {
    return(
        <>
            {
                totales.map(({title, cm2, costo, cantidad}, index) => (
                    <Col xs='12' md="6" lg="3">  
                        <SmallDetailBox
                            title={title}
                            text={title !== "spinning" ? 
                                <>
                                    <p className="fs-18 mt-10" style={{color: "#ffff"}}>{`cm²: ${cm2}`}</p>
                                    <p className="fs-18 mt-10" style={{color: "#ffff"}}>{`Costo: ${costo} USD`}</p>
                                </>
                                :
                                <>
                                    <p className="fs-18 mt-10" style={{color: "#ffff"}}>{`Cantidad: ${cantidad}`}</p>
                                </>
                            }
                            styles = {{color: '#ffff !important'}}
                            fondo={fondos[index % fondos.length]}
                        />
                    </Col>
                ))
            }
        </>
    )
}