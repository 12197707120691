import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
    Button,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
// Componentes Generales
import Modals from '../modal.component';
import { OverlayTooltip } from '../../common.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
import SelectItemContext from '../../../context/selectItem/selectItem.context';
// Conexion Api
import {
    apiGetStaffRelationUsers,
    apiGetAllStaff,
    apiGetStores,
    apiGetAllRacks,
    apiGetLevelsWithRack,
    apiGetPossibleExitsByLevel,
    apiGetBoxById,
    apiPostNewPhysicalInvent,
    apiGetAllProducts,
    apiPostApplyPhysicalInvent,
    apiPostComparePassword,
    apiGetTheresNoAppliedPhysicalInvent,
    apiGetByIdPhysicalInvent,
    apiGetAllProdsPhysicalInvent,
    apiPatchPhysicalInvent,
    apiGetAllTypeMov,
} from '../../../common/api-invent';
// Utilidades
import { hashText } from '../../../utilities/funcs';

// ID unico (codigo de caja) para agregar nueva caja
const idAddNewBox = "-1"

const AddPhysicalInvent = ({ show, setShow }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const {
        onSelectStore,
        onSelectPhysicalInvent,
    } = useContext(SelectItemContext);
    const idReloadComp = 28;

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [staffs, setStaffs] = useState([]);
    const [masterStaffs, setMasterStaffs] = useState([]);
    const [stores, setStores] = useState([]);
    const [racks, setRacks] = useState([]);
    const [products, setProducts] = useState([]);
    const [saverIdPi, setSaverIdPi] = useState(0);
    const [typeMovement, setTypeMovement] = useState([]);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        id_almacen: "0",
        id_personal_realiza: "0",
        id_personal_responsable: "0",
        fecha_inventario: moment().format('YYYY-MM-DD'),
        productos: []
    });

    // Cargar datos
    useEffect(() => {
        // Cargar informacion guardada
        const loadSaveData = async () => {
            const reqTypesMovement = await apiGetAllTypeMov();
            setTypeMovement([...reqTypesMovement.data.content]);
            // Verificar si hay inventario fisico almacenado
            const reqThersPI = await apiGetTheresNoAppliedPhysicalInvent(data.id_almacen);
            if (reqThersPI.data.content) {
                const { id_inventario_fisico } = reqThersPI.data.content;
                // Traer informacion de ese inventario fisico
                const { data: { content: infoPI } } = await apiGetByIdPhysicalInvent(id_inventario_fisico);
                // Traer productos de inventario fisico
                const auxSaveIdPI = infoPI.id_inventario_fisico;
                setSaverIdPi(auxSaveIdPI);
                const reqProdsPI = await apiGetAllProdsPhysicalInvent(auxSaveIdPI)
                setData(data => ({
                    ...data,
                    id_personal_realiza: infoPI.id_personal_realiza,
                    id_personal_responsable: infoPI.id_personal_responsable,
                    fecha_inventario: moment(infoPI.fecha_inventario).format('YYYY-MM-DD'),
                    productos: [...reqProdsPI.data.content.map(prod => {
                        let producto = {
                            //id_rack: prod.id_rack,
                            id_almacen_ubicacion: prod.id_almacen_ubicacion,
                            id_producto: prod.id_producto,
                            cantidad_actual_placas: Number(prod.cantidad_actual_placas),
                            fecha_consumo: moment(prod.fecha_consumo).format('YYYY-MM-DD'),
                            tipo_movimiento: prod.id_tipo_salida.toString(),
                            id_rack: prod.id_rack
                        };

                        if(prod.id_tipo_salida === 1){
                            producto.lote = prod.lote;
                            producto.codigo_caja = prod.codigo_caja;
                            producto.id_caja = prod.id_caja ?? idAddNewBox;
                        }else{
                            producto.largo = prod.largo;
                            producto.alto = prod.alto;
                            producto.id_unidad = prod.id_unidad;
                        }
                        return producto
                    })]
                }));
            } else {
                setData(data => ({
                    ...data,
                    id_personal_realiza: "0",
                    id_personal_responsable: "0",
                    fecha_inventario: moment().format('YYYY-MM-DD'),
                    productos: [],
                }));
            }
        }
        // Cargar datos necesarios
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar personal
                const reqStaffs = await apiGetAllStaff();
                setStaffs([...reqStaffs.data.content]);
                // Solicitar personal con relacion a usuarios
                const reqMasterStaffs = await apiGetStaffRelationUsers();
                setMasterStaffs([...reqMasterStaffs.data.content]);
                // Solicitar Almacenes
                const reqStores = await apiGetStores();
                setStores([...reqStores.data.content]);
                // Solicitar Productos
                const reqProds = await apiGetAllProducts();
                setProducts([...reqProds.data.content]);
                await loadSaveData();
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData()
        return () => setLoading(false);
    }, [show, data.id_almacen]);

    // Traer productos de proveedor
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const reqRacks = await apiGetAllRacks(data.id_almacen);
                setRacks([...reqRacks.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData();
    }, [data.id_almacen, setLoading, show]);


    // Llena datos con informacion del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.id_almacen === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere almacen',
                show: true
            });
            return false;
        }
        if (data.id_personal_realiza === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere personal que realiza',
                show: true
            });
            return false;
        }
        if (data.id_personal_responsable === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere personal responsable',
                show: true
            });
            return false;
        }
        let save = true;
        if(data.productos.length <= 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere al menos un embalaje',
                show: true
            });
            return false;
        } else{
            data.productos.forEach(element => {
                if(element.cantidad_actual_placas < '0'){
                    save = false;
                }
                if(element.tipo_movimiento === '1'){
                    if(element.id_caja === '0'){
                        save = false
                    }
                    if(element.codigo_caja === ''){
                        save = false
                    }
                    if(element.codigo_caja === ''){
                        save = false
                    } 
                } else{
                    if(element.largo <= 0 || element.alto <= 0){
                        save = false
                    } 
                }
            })
        }
        if(!save) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere llenar todos los campos de las razones de movimiento',
                show: true
            });
            return false;
        } 
        return true;
    }

    const fetchSaveApi = async () => {
        const sendData = cleanDataToSend();
        return await apiPostNewPhysicalInvent(sendData);
    }

    const cleanDataToSend = () => ({
        ...data,
        productos: data.productos.map((prod) => {
            let producto = {
                //id_rack: prod.id_rack,
                id_almacen_ubicacion: prod.id_almacen_ubicacion,
                id_producto: prod.id_producto,
                cantidad_actual_placas: prod.cantidad_actual_placas,
                fecha_consumo: prod.fecha_consumo,
                id_tipo_salida: prod.tipo_movimiento,
            };

            if(prod.tipo_movimiento === '1'){
                producto.lote = prod.lote;
                producto.codigo_caja = prod.codigo_caja;
                producto.id_caja = prod.id_caja === idAddNewBox
                    ? null
                    : Number(prod.id_caja);
            }else{
                producto.largo = prod.largo;
                producto.alto = prod.alto;
                producto.id_unidad = 6;
            }
            return producto
        })
    })

    const onShowSuccess = async (message) => {
        await Swal.fire({
            title: message,
            icon: 'success',
            confirmButtonText: 'Aceptar'
        });
        // Recarga de componente
        onSelectStore(data.id_almacen);
        onReloadComponent(idReloadComp);
        onSelectPhysicalInvent(0);
        // Limpiar datos
        setData({
            id_almacen: "0",
            id_personal_realiza: "0",
            id_personal_responsable: "0",
            fecha_inventario: moment().format('YYYY-MM-DD'),
            productos: []
        });
        // Cerrar modal
        setShow(false);
    }

    // Funcion para guardar datos de inventario
    const saveTemporary = async () => {
        // Verificar si se tiene un inventario ya generado anteriormente
        let reqSave;
        if (saverIdPi === 0) {
            // Enviar peticion para guardar inventario fisico
            reqSave = await fetchSaveApi();
        } else {
            // Enviar peticion para editar inventario fisico
            reqSave = await apiPatchPhysicalInvent({
                ...cleanDataToSend(),
                id_inventario_fisico: saverIdPi,
            });
        }
        return reqSave;
    }

    // Guardar inventario fisico
    const handleSave = async () => {
        if (isFormValid()) {
            try {
                // Guardar inventario
                const response = await saveTemporary();
                if (response.data.type === "success") {
                    onShowSuccess("Se ha guardado el inventario fisico exitosamente");
                } else {
                    throw new Error("Error al agregar inventario fisico");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar inventario físico',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    const handelApply = async () => {
        if (isFormValid()) {
            // Permitir focus en input
            const modal = document.querySelector(".modal.show");
            modal.removeAttribute("tabindex");

            const { value: masterPassword } = await Swal.fire({
                icon: 'warning',
                title: '¿Esta seguro que desea aplicar el inventario físico?',
                text: "La acción no se podrá deshacer, se necesita confirmación por parte del responsable",
                inputLabel: 'Contraseña del responsable',
                input: 'password',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Aceptar',
                inputValidator: async (value) => {
                    try {
                        // Enviar peticion para comparar contraseña
                        const reqComparePassword = await apiPostComparePassword({
                            id_personal: data.id_personal_responsable,
                            password: hashText(value),
                        });
                        if(!(reqComparePassword.data.content.answer)){
                            return "La contraseña del responsable no coincide"
                        }
                    } catch (e) {
                        console.log(e);
                        return "La contraseña del responsable no coincide"
                    }
                }
            })

            if (masterPassword) {
                try {
                    // Guardar inventario
                    const response = await saveTemporary();
                    if (response.data.type === "success") {
                        const id_inventario_fisico = response.data.content.id_inventario_fisico;
                        // Enviar peticion para aplicar inventario fisico
                        const reqApply = await apiPostApplyPhysicalInvent({ id_inventario_fisico })
                        if (reqApply.data.type === "success") {
                            setSaverIdPi(0);
                            onShowSuccess("Se ha aplicado el inventario fisico exitosamente");
                        } else {
                            throw new Error("Error al agregar inventario fisico");
                        }
                    } else {
                        throw new Error("Error al agregar inventario fisico");
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al agregar inventario físico',
                        text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                        confirmButtonText: 'Aceptar',
                    });
                }
            }
        }
    }

    // Aplicar inventario fisico
    const onApply = async e => {
        e.preventDefault();
        // Envio de información
        await handelApply();
    }

    // Guardar inventario fisico
    const onSave = async e => {
        e.preventDefault();
        // Envio de información
        await handleSave();
    }

    /* ------------------ FUNCIONES PARA PRODUCTOS --------------------- */
    // Cambio en valores de fila de producto
    const handleChangeRow = e => {
        const index = Number(e.target.getAttribute('index'));
        // Cambiar id de Producto Seleccionado
        const auxProds = [...data.productos];
        auxProds[index] = {
            ...auxProds[index],
            [e.target.name]: e.target.value,
        };
        setData({
            ...data,
            productos: auxProds,
        })
    }
    // Cambiar datos de un producto
    const setForceBoxData = (dataProduct = {}, index = 0) => {
        const auxProds = [...data.productos];
        auxProds[index] = {
            ...auxProds[index],
            ...dataProduct,
            fecha_consumo: moment(dataProduct.fecha_consumo).format('YYYY-MM-DD'),
        };
        setData({
            ...data,
            productos: auxProds,
        })
    }
    // Eliminar fila de producto
    const handleDeleteRow = e => {
        const index = Number(e.currentTarget.getAttribute('index'));
        // Objeto auxiliar para guardar valores
        const auxProds = [...data.productos];
        // Eliminar valor
        auxProds.splice(index, 1);
        // Guardar valores
        setData({
            ...data,
            productos: auxProds,
        })
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="xl"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Agregar Inventario Físico"
                buttonTitle="Guardar"
                handleClick={onSave}
                auxButtonTitle="Aplicar"
                handleAuxButton={onApply}
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        :
                        <Form>
                            <Row>
                                <Col md="4" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_almacen" className="form-label">
                                            Selecciona un Almacén: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_almacen"
                                            id="id_almacen"
                                            value={data.id_almacen}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un almacen</option>
                                            {
                                                stores.map(({ glb_id_almacen, glb_nombre }, index) => (
                                                    <option value={glb_id_almacen} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_personal_realiza" className="form-label">
                                            Personal que reliza: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_personal_realiza"
                                            id="id_personal_realiza"
                                            value={data.id_personal_realiza}
                                            onChange={handleFormChange}
                                            disabled={data.id_almacen === "0"}
                                        >
                                            <option value="0">Seleccione un personal</option>
                                            {
                                                staffs.map(({ id_personal, nombre }, index) => (
                                                    <option value={id_personal} key={index}>
                                                        {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="4" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_personal_responsable" className="form-label">
                                            Personal responsable: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_personal_responsable"
                                            id="id_personal_responsable"
                                            value={data.id_personal_responsable}
                                            onChange={handleFormChange}
                                            disabled={data.id_almacen === "0"}
                                        >
                                            <option value="0">Seleccione un personal</option>
                                            {
                                                masterStaffs.map(({ id_personal, nombre }, index) => (
                                                    <option value={id_personal} key={index}>
                                                        {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="4" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="fecha_inventario" className="form-label">
                                            Fecha Inventario: *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="fecha_inventario"
                                            name="fecha_inventario"
                                            value={data.fecha_inventario}
                                            onChange={handleFormChange}
                                            disabled={data.id_almacen === "0"}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            {
                                data.productos.map((element, index) => (
                                    <ItemRowProduct
                                        index={index}
                                        key={index}
                                        racks={racks}
                                        products={products}
                                        onChangeRow={handleChangeRow}
                                        onDeleteRow={handleDeleteRow}
                                        formIsShowed={show}
                                        rowData={element}
                                        setRowData={setForceBoxData}
                                        typeMovement = {typeMovement}
                                    />
                                ))
                            }
                            <Row style={{ marginTop: "10px" }}>
                                <Col>
                                    <Button
                                        variant="info"
                                        className='waves-effect waves-light mb-20'
                                        disabled={data.id_almacen === "0"}
                                        onClick={() => {
                                            setData({
                                                ...data,
                                                productos: [
                                                    ...data.productos,
                                                    {
                                                        id_rack: "0",
                                                        id_almacen_ubicacion: "0",
                                                        id_caja: "0",
                                                        id_producto: "0",
                                                        cantidad_actual_placas: "",
                                                        lote: "",
                                                        codigo_caja: "",
                                                        fecha_consumo: "",
                                                        tipo_movimiento: '0',
                                                        alto: '0',
                                                        largo: '0'
                                                    }
                                                ]
                                            })
                                        }}
                                    >
                                        <i className='si si-plus' /> Agregar Embalaje
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

/* ==================================================================================
    ======================== Item de Fila para nuevo producto =======================
====================================================================================*/
const ItemRowProduct = ({
    index,
    racks,
    products,
    onChangeRow,
    onDeleteRow,
    formIsShowed,
    rowData,
    setRowData,
    typeMovement
}) => {
    const [levels, setLevels] = useState([]);
    const [boxes, setBoxes] = useState([]);
    const [canWriteData, setCanWriteData] = useState(false);

    const [data, setData] = useState({
        id_rack: "0",
    })

    const onChangeInnerData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    // Verificar si se ha enviado id rack
    useEffect(() => {
        if (formIsShowed && rowData.id_rack !== "0")
            setData(d => ({
                ...d,
                id_rack: rowData.id_rack
            }))
    }, [rowData.id_rack, formIsShowed])

    // Traer niveles del rack
    useEffect(() => {
        const loadData = async () => {
            try {
                const reqRacks = await apiGetLevelsWithRack(data.id_rack);
                setLevels([...reqRacks.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        if (formIsShowed && data.id_rack !== "0")
            loadData();
    }, [data.id_rack, formIsShowed]);

    // Traer cajas del nivel
    useEffect(() => {
        const loadData = async () => {
            try {
                const reqBoxes = await apiGetPossibleExitsByLevel(rowData.id_almacen_ubicacion);
                setBoxes([...reqBoxes.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        if (formIsShowed)
            loadData();
        return (() => {
            setBoxes([]);
        })
    }, [rowData.id_almacen_ubicacion, formIsShowed]);

    // Cargar datos de la caja
    useEffect(() => {
        const loadData = async () => {
            try {
                const reqInfoBox = await apiGetBoxById(rowData.id_caja);
                setRowData(reqInfoBox.data.content, index);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        if (formIsShowed) {
            // Activar funcion de agregar nueva caja
            if (rowData.id_caja === idAddNewBox) {
                setCanWriteData(true);
            } else if (rowData.id_caja && rowData.id_caja !== "0") {
                setCanWriteData(false);
                loadData();
            }
        }
        // eslint-disable-next-line
    }, [rowData.id_caja, formIsShowed, idAddNewBox, index])

    return (
        <Row>
            <Col xs="12" md="3">
                <div className="form-group">
                    <label htmlFor="id_rack" className="form-label">
                        Rack: *
                    </label>
                    <select
                        className="form-select"
                        name="id_rack"
                        id="id_rack"
                        value={data.id_rack}
                        onChange={onChangeInnerData}
                        index={index}
                    >
                        <option value="0">Seleccione un rack</option>
                        {
                            racks.map(({ id_rack, nombre, codigo_rack }, index) => (
                                <option value={id_rack} key={index}>
                                    {`${nombre} - ${codigo_rack}`}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </Col>
            <Col xs="12" md="2">
                <div className="form-group">
                    <label htmlFor="id_almacen_ubicacion" className="form-label">
                        Nivel: *
                    </label>
                    <select
                        index={index}
                        className="form-select"
                        name="id_almacen_ubicacion"
                        id="id_almacen_ubicacion"
                        value={rowData.id_almacen_ubicacion}
                        onChange={onChangeRow}
                        disabled={data.id_rack === "0"}
                    >
                        <option value="0">Seleccione un nivel</option>
                        {
                            levels.map(({ id_almacen_ubicacion, nombre, codigo_ubicacion }, index) => (
                                <option value={id_almacen_ubicacion} key={index}>
                                    {`${nombre} - ${codigo_ubicacion}`}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </Col>
            <Col xs = "12" md = "3">
                <div className="form-group">
                    <label htmlFor="tipo_movimiento" className ="form-label">
                        Tipo de Movimiento: *
                    </label>
                    <select 
                        name="tipo_movimiento" 
                        id="tipo_movimiento" 
                        className='form-select'
                        value={rowData.tipo_movimiento}
                        onChange={onChangeRow}
                        index={index}
                    >
                        <option value="0">Selecione el tipo de movimiento:</option>
                        {
                            typeMovement.map( ({glb_id_tipo_salida, glb_nombre}, index) => (
                                <option value={glb_id_tipo_salida} key={index}>
                                    {glb_nombre}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </Col>
            <Col 
                xs="12" 
                md="2"
                hidden ={rowData.tipo_movimiento === '1' ? false:true}
            >
                <div className="form-group">
                    <label htmlFor="id_caja" className="form-label">
                        Embalaje: *
                    </label>
                    <select
                        className="form-select"
                        name="id_caja"
                        id="id_caja"
                        value={rowData.id_caja}
                        onChange={onChangeRow}
                        index={index}
                        disabled={rowData.id_almacen_ubicacion === "0"}
                    >
                        <option value="0">Seleccione un embalaje</option>
                        <option value={idAddNewBox}>Agregar nuevo Embalaje</option>
                        {
                            boxes.map(({ id_caja, codigo_caja, lote, cantidad_actual }, index) => (
                                <option value={id_caja} key={index}>
                                    {`${codigo_caja} - ${lote} - cantidad: ${cantidad_actual}`}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </Col>
            <Col xs="12" md="3">
                <div className="form-group">
                    <label htmlFor="id_producto" className="form-label">
                        Producto: *
                    </label>
                    <select
                        index={index}
                        className="form-select"
                        name="id_producto"
                        id="id_producto"
                        value={rowData.id_producto}
                        onChange={onChangeRow}
                    >
                        <option value="0">Seleccione un producto</option>
                        {
                            products.map(({ pr_id_producto, pr_nombre, codigo_proveedor }, index) => (
                                <option value={pr_id_producto} key={index}>
                                    {`${pr_nombre} - ${codigo_proveedor}`}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </Col>
            <Col 
                xs="12" 
                md="1"
                hidden ={rowData.tipo_movimiento === '2' ? false:true}
            >
                <div className="form-group">
                    <label htmlFor="largo" className="form-label">
                        Largo: *
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        id="largo"
                        name="largo"
                        value={rowData.largo}
                        onChange={onChangeRow}
                        index={index}
                        min="1"
                    />
                </div>
            </Col>
            <Col 
                xs="12" 
                md="1"
                hidden ={rowData.tipo_movimiento === '2' ? false:true}
            >
                <div className="form-group">
                    <label htmlFor="alto" className="form-label">
                        Alto: *
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        id="alto"
                        name="alto"
                        value={rowData.alto}
                        onChange={onChangeRow}
                        index={index}
                        min="1"
                    />
                </div>
            </Col>
            <Col 
                xs="12"
                md="2"
                hidden ={rowData.tipo_movimiento === '1' ? false:true}
            >
                <div className="form-group">
                    <label htmlFor="codigo_caja" className="form-label">
                        Codigo: *
                    </label>
                    <input
                        index={index}
                        type="text"
                        className="form-control"
                        id="codigo_caja"
                        name="codigo_caja"
                        value={rowData.codigo_caja}
                        onChange={onChangeRow}
                        disabled={!canWriteData}
                    />
                </div>
            </Col>
            <Col 
                xs="12" 
                md="2"
                hidden ={rowData.tipo_movimiento === '1' ? false:true}
            >
                <div className="form-group">
                    <label htmlFor="lote" className="form-label">
                        Lote: *
                    </label>
                    <input
                        index={index}
                        type="text"
                        className="form-control"
                        id="lote"
                        name="lote"
                        value={rowData.lote}
                        onChange={onChangeRow}
                        disabled={!canWriteData}
                    />
                </div>
            </Col>
            <Col xs="12" md="2">
                <div className="form-group">
                    <label htmlFor="fecha_consumo" className="form-label">
                        Fecha consumo: *
                    </label>
                    <input
                        index={index}
                        type="date"
                        className="form-control"
                        id="fecha_consumo"
                        name="fecha_consumo"
                        value={rowData.fecha_consumo}
                        onChange={onChangeRow}
                    />
                </div>
            </Col>
            <Col xs="9" md="1">
                <div className="form-group">
                    <label htmlFor="cantidad_actual_placas" className="form-label">
                        Existencia:*
                    </label>
                    <input
                        index={index}
                        type="number"
                        className="form-control"
                        name="cantidad_actual_placas"
                        id="cantidad_actual_placas"
                        onChange={onChangeRow}
                        value={rowData.cantidad_actual_placas}
                        min="1"
                    />
                </div>
            </Col>
            <Col xs="3" md="1" style={{ paddingTop: "30px" }}>
                <OverlayTooltip
                    placement="top"
                    tooltip="Eliminar"
                >
                    <Button
                        size="sm"
                        variant="danger"
                        className='waves-effect waves-light'
                        onClick={onDeleteRow}
                        index={index}
                    >
                        <i className='si si-trash' />
                    </Button>
                </OverlayTooltip>
            </Col>
        </Row>
    )
}

export default AddPhysicalInvent;