import { useCallback, useContext, useEffect, useState } from 'react';
import {
    apiGetAllProducts,
    apiGetDetailsProductPrepedido,
    apiGetPrepedidoById, 
    apiGetAllProviders
} from '../../../common/api-invent';

import { Button, Col, Form, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { backgroundColors } from '../../../utilities/backgroundColorsRowProduct';
import Load from '../../Load/load.component';
import { OverlayTooltip } from '../../common.component';
import Modals from '../modal.component';

// Contexto
import ReloadContext from "../../../context/reload/reload.context";

const idReloadComponent = 38

const PrepedidosModal = ({show, setShow, id, onSave, isviewDetail = false }) => {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([])
    const [prepedido, setPrepedido] = useState([])
    const [proveedores, setProveedores] = useState([])
    const [proveedor, setProveedor] = useState(0)
    const { onReloadComponent } = useContext(ReloadContext);

    const addRow = useCallback(() => {
        const row = {
            id_producto: 0,
            num_cajas: 1,
            cm2_caja: 0,
            precio_x_caja: 0,
            moneda_codigo_sat: '',
        }
        setPrepedido((prepedido) => [
            ...prepedido,
            row
        ]);
    }, [])

    useEffect(() => {
        const getInitialData = async () => {
            try {
                const responseProducts = await apiGetAllProducts()
                setProducts([...responseProducts.data.content])

                const responseProveedores = await apiGetAllProviders()
                setProveedores([...responseProveedores.data.content])

                if (id){
                    const responsePrepedido = await apiGetPrepedidoById(id) 
                    const prepedido = responsePrepedido.data?.content
                    setPrepedido([...prepedido.detalles])
                    console.log(prepedido.id_proveedor)
                    setProveedor(prepedido.id_proveedor)
                }else{
                   addRow()
                }
            } catch (e) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos',
                    text: e.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
                    confirmButtonText: 'Aceptar',
                });
            }
            setLoading(false)
        }
        if(show) getInitialData()

        return () => {
            setPrepedido([]);
            setProveedor([0])
        } 
    }, [id, addRow, show])

    const handleChange = (data, index) => {
        const aux = prepedido;
        aux[index] = {
            ...aux[index],
            ...data
        };
        setPrepedido([...aux]);
    }

    const onDelete = (index) => {
        const aux = [...prepedido]
        aux.splice(index, 1);
        setPrepedido([...aux])

    }

    const handleSubmit = async () => {
        try{
            await onSave({id_proveedor: proveedor, detalles: prepedido}, id)
            Swal.fire({
                title: "El prepedido se ha generado exitosamente",
                icon: "success",
                confirmButtonText: 'Aceptar'
            });
            onReloadComponent(idReloadComponent)
            setShow(false)
        }catch(error){
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Error al agregar prepedido',
                text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                confirmButtonText: 'Aceptar',
            });
        }
    }

    return(
        <Modals
            show={show}
            hideModal={() => {
                setShow(false);
            }}
            modalTitle="Prepedidos"
            buttonTitle="Guardar prepedido"
            size="xl"
            handleClick={isviewDetail === true ? null : handleSubmit}
        >
             {
                loading
                ? <Load
                    size={150}
                    marginBottom="10px"
                    marginTop="10px"
                /> 
                : <Form>
                    <Row>
                        <Col md="3" xs="12">
                            <div className="form-group">
                                <label htmlFor="proveedor" className="form-label">
                                    Proveedor: *
                                </label>
                                <select
                                className="form-select"
                                name="proveedor"
                                id="proveedor"
                                value={proveedor}
                                onChange={(ev) => setProveedor(ev.target.value)}>
                                    <option disabled value="0">Seleccione un proveedor</option>
                                    {
                                        proveedores.map(({ id_proveedor, nombre }) => (
                                            <option value={id_proveedor} key={id_proveedor}>
                                                {nombre}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </Col>
                    </Row>
                    { 
                        prepedido.map((detalle, index) => 
                            <RowDetail 
                                products={products}  
                                detalle={detalle} key={index} 
                                updateState={(data) => handleChange(data, index)} 
                                handleDelete={() => onDelete(index)}
                                backgroundColor={backgroundColors[index % 6]}
                                isviewDetail={isviewDetail}
                            />
                        )}
                    <hr/>
                    {
                        isviewDetail 
                        ? null
                        : (<Row>
                            <Col>
                                <Button
                                    variant="info"
                                    className='waves-effect waves-light'
                                    onClick={() => addRow()}
                                >
                                    <i className='si si-plus' /> Agregar Prepedido
                                </Button>
                            </Col>
                        </Row>)
                    }
                    
                </Form>      
             }
        </Modals>
    )
}

const RowDetail = ({products, detalle, updateState, handleDelete, backgroundColor, isviewDetail}) => {
    const {id_producto, num_cajas, cm2_caja, precio_x_caja, moneda_codigo_sat} = detalle
    
    const fetchDetailProduct = async (id) => {
            const details = await apiGetDetailsProductPrepedido(id)
            return details.data.content
    }

    const handleChangeProduct = async (ev) => {
        const id = ev.target.value
        try{
            const details = await fetchDetailProduct(id)
            updateState(details)
        }catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error al cargar datos',
                text: error.response?.data?.msg ?? 'Intentelo de nuevo más tarde',
                confirmButtonText: 'Aceptar',
            });
        }
    }

    const handleChangeBoxes = (ev) => {
        const num_cajas = ev.target.value
        updateState({num_cajas: parseInt(num_cajas)})
    }
    
    return(
        <div
            style={{borderColor: `${backgroundColor}`}}
            className='rowProduct'
        >
            <Row>
                <Col md="3" xs="12">
                    <div className="form-group">
                        <label htmlFor="product" className="form-label">
                            Producto: *
                        </label>
                        <select
                        className="form-select"
                        name="id_producto"
                        id="id_producto"
                        value={id_producto}
                        onChange={handleChangeProduct}>
                             <option disabled value="0">Seleccione un producto</option>
                             {
                                products.map(({ pr_nombre, pr_id_producto }, index) => (
                                    <option value={pr_id_producto} key={index}>
                                        {pr_nombre}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </Col>
                <Col xs="12" md="2">
                    <div className="form-group">
                        <label htmlFor="num_cajas" className="form-label">
                            Cantidad: *
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="num_cajas"
                            name="num_cajas"
                            value={num_cajas}
                            min='1'
                            onChange={handleChangeBoxes}
                        />
                    </div>
                </Col>
                <Col xs="12" md="2">
                    <div className="form-group">
                        <label htmlFor="cm2_caja" className="form-label">
                            cm2 x caja:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="cm2_caja"
                            name="cm2_caja"
                            value={cm2_caja}
                            disabled
                        />
                    </div>
                </Col>
                <Col xs="12" md="2">
                    <div className="form-group">
                        <label htmlFor="cm2_pedido" className="form-label">
                            cm2 x pedido:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="cm2_pedido"
                            name="cm2_pedido"
                            value={(Number(cm2_caja) * num_cajas)}
                            disabled
                        />
                    </div>
                </Col>
                <Col xs="12" md="2">
                    <div className="form-group">
                        <label htmlFor="cm2_corporativo" className="form-label">
                            cm2 corporativo:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="cm2_corporativo"
                            name="cm2_corporativo"
                            value={isNaN(Number(precio_x_caja) / cm2_caja) ? 0 : Number(precio_x_caja) / cm2_caja }
                            disabled
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="12" md="2">
                    <div className="form-group">
                        <label htmlFor="precio_x_caja" className="form-label">
                            Precio x caja:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="precio_x_caja"
                            name="precio_x_caja"
                            value={precio_x_caja}
                            disabled
                        />
                    </div>
                </Col>
                <Col xs="12" md="2">
                    <div className="form-group">
                        <label htmlFor="costo_prepedido" className="form-label">
                            Costo prepedido:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="costo_prepedido"
                            name="costo_prepedido"
                            value={Number(precio_x_caja) * num_cajas}
                            disabled
                        />
                    </div>
                </Col>
                <Col xs="12" md="2">
                    <div className="form-group">
                        <label htmlFor="moneda_codigo_sat" className="form-label">
                            Moneda:
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="moneda_codigo_sat"
                            name="moneda_codigo_sat"
                            value={moneda_codigo_sat}
                            disabled
                        />
                    </div>
                </Col>

                {
                    isviewDetail 
                    ? null
                    : (
                        <Col xs="12" md="2" style={{ paddingTop: "30px" }}>
                            <OverlayTooltip
                                placement="top"
                                tooltip="Eliminar"
                            >
                                <Button
                                    size="sm"
                                    variant="danger"
                                    className='waves-effect waves-light'
                                    onClick={handleDelete}
                                    
                                >
                                    <i className='si si-trash' />
                                </Button>
                            </OverlayTooltip>
                        </Col>
                    )
                }
            </Row>
        </div>
    )
}

export default PrepedidosModal