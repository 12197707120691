import { useState, useContext, useEffect } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import {
    Button
} from 'react-bootstrap';
import Load from '../../Load/load.component';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import { apiPutScriptData, apiGetScript, apiGetTiposDatos  } from '../../../common/api-invent';
import { apiGetRoles,  } from '../../../common/api-general';
import { decrypt_script, encrypt_script } from '../../../utilities/encrypt-checker';
import { useTranslation } from 'react-i18next';
const EditReportSQLData = ({ show, setShow , id}) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 36;
    const [loading, setLoading] = useState(true);
    const [numCampos, setnumCampos] = useState(0);
    const [arreglo, setArreglo] = useState([]);
    const{t}=useTranslation();
    // Carga de data    
    const [providers, setProviders] = useState([]);
    // Carga de data    
    const [tiposDato, setTiposDato] = useState([]);
    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });
    let parametrosData = []
    // Hook para formulario
    const [data, setData] = useState({
        nombre: '',
        nombre_old: '',
        script: '',
        roles: [],
        password: '',
        parametros: [],
        descripcion: '',
    });
    
    // Moficacion de campos
    const onInputChange = e => {
        let temp = data.roles.indexOf(e.target.value)
        if (temp !== -1) {
            data.roles.splice(temp, 1)
        }else{
          data.roles.push(e.target.value)  
        }
    }
    //Libreria
    const [code, setCode] = useState(""); 
    const onChangeCode = (value) =>{
       setCode(value);
    }
    
    // Cargar tipos de datos
    useEffect(() => {
        const loadData = async () => {
            try {
                // Informacion de proveedores
                const reqPro = await apiGetTiposDatos();
                setTiposDato([...reqPro.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setTiposDato([]);
        }
    }, [setTiposDato, show]);

    // Llena datos con informacion del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Cargar Data
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                
                const reqPro = await apiGetRoles();
                let reqDetail = await apiGetScript(id);
                
                reqDetail.data.content.roles = reqDetail.data.content.roles.split(',');
                reqDetail.data.content.script = await decrypt_script(JSON.stringify(reqDetail.data.content.script));
                setData({
                    ...reqDetail.data.content,
                    nombre_old: reqDetail.data.content.nombre
                });
                setCode(reqDetail.data.content.script);
                setProviders([...reqPro.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setLoading(false);
        }
    }, [setData, setLoading, show, id, setProviders]);

     

    //Validación para formulario
    const isFormValid = () => {
        data.script=code;
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre del reporte',
                show: true
            });
            return false;
        }
        if(data.id_rol === '')
        {
            setAlert({
                title: 'Error',
                message: 'Debe seleccionar un rol',
                show: true,
            });
            return false;
        }
        if (data.descripcion === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere descripcion del reporte',
                show: true
            });
            return false;
        }
        if (data.password === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere la contraseña del documento',
                show: true
            });
            return false;
        }
        if (data.script === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere script para generar el reporte',
                show: true
            });
            return false;
        }
        
        return true;
    }

    // Insertar nuevo query
    const _handleSubmit = async (closeModal = true) => {
        
        if (isFormValid()) {
            try {
                //Codear a B64 el script
                data.script=code;
                data.script = await encrypt_script(data.script);
                const response = await apiPutScriptData(data);
                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Query añadido correctamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    data.script=code;
                    // Limpiar datos
                    setData({
                        nombre: '',
                        script: '',
                        roles: [],
                        password: '',
                        parametros: [],
                    });
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar el script");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nuevo script',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
                const scripted = await decrypt_script(JSON.stringify(data.script));
                setData({...data,
                    script: scripted
                }) 
            }
        }
    }

    //Obtener params dinámicamente
    const getParams = (index, e, tipo_dato) => 
    {
        let parametrosData2 = {
            "parametro":e.currentTarget.name,
            "id_tipo": e.currentTarget.value,
            "tipo_dato":tipo_dato
        } 
        parametrosData[index] = parametrosData2
        data.parametros=JSON.parse(JSON.stringify(parametrosData));
    }
    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(true);
    }

    
    //Mostrar Parámetros
    const showParameters = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        
        //Obtener los nombres de los parametros
        //data.script=code;
        if (code === '') {
            setAlert({
                title: 'Script vacio o sin parámetros',
                message: 'Se requiere script',
                show: true
            });
            setnumCampos(0);
            setArreglo([]);
            return false;
        }
        let PrinActi = /\[.*?\]/g;
        let PrinAct2 = code.match(PrinActi);
        PrinAct2 = (PrinAct2 == null) ? [] : code.match(PrinActi);
        if (PrinAct2.length >= 0) {

            PrinAct2.map((value, index) =>{
                return PrinAct2[index] = PrinAct2[index].replace('[', '').replace(']', '');
            })
        }
        setnumCampos(PrinAct2.length)
        PrinAct2 = PrinAct2.filter((valor, indice) => {
            return PrinAct2.indexOf(valor) === indice;
          }
        );
        setArreglo(PrinAct2);
     }
    return (
        <>
            <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="xl"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={t('editar')+" Query"}
                buttonTitle={t('agregar_y_cerrar')}
                handleClick={handleSaveClose}
                //auxButtonTitle="Agregar y guardar"
                //handleAuxButton={handleSave}
            >
               {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="50px"
                            marginTop="50px"
                        />
                        : 
                <Form>
                    <Row>
                        <Col md="12" xs="12">
                            <div className="form-group">
                                <label htmlFor="nombre" className="form-label">
                                    {t('nombre')}: *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nombre"
                                    name="nombre"
                                    value={data.nombre}
                                    onChange={handleFormChange}
                                    placeholder={t('nombre')+" "+t('reporte')}
                                />
                            </div>
                        </Col>
                        <Col md="12" xs="12">
                            <div className="form-group">
                                <label htmlFor="descripcion" className="form-label">
                                    {t('descripcion')}: *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="descripcion"
                                    name="descripcion"
                                    value={data.descripcion}
                                    onChange={handleFormChange}
                                    placeholder={t('descripcion')}
                                />
                            </div>
                        </Col>
                        <Col md="12" xs="12">
                            <div className="form-group">
                                <label htmlFor="password" className="form-label">
                                    {t('contrasena')}: *
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    value={data.password}
                                    onChange={handleFormChange}
                                    placeholder="*******"
                                />
                            </div>
                        </Col>
                        <Col md="12" xs="12">
                            <div className='form-group'>
                                <label htmlFor='id_rol' className='form-label'>
                                    {t('seleccione')+" "+t('rol')}: * 
                                </label>
                                {
                                                providers.map(({ id_rol, rol }, index) => (
                                                    
                                                   <div>
                                                        <input
                                                        type="checkbox"
                                                        id={rol}
                                                        name={rol}
                                                        value={id_rol}
                                                        onChange={onInputChange}
                                                        defaultChecked={data.roles.includes(id_rol.toString())}
                                                        />
                                                        <label for={rol}>{rol}</label>
                                                   </div>
                                                ))
                                            }
                                
                            </div>
                        </Col>
                        <Col className="mt-30" md="12" xs="12">
                        <CodeEditor
                            value={code}
                            id="script"
                            name="script"
                            language="sql"
                            data-color-mode="dark"
                            placeholder={t('inserte_sql')}
                            onChange={(evn) => onChangeCode(evn.target.value)}
                            padding={15}
                            style={{
                                fontSize: 15,
                                backgroundColor: "#f5f5f5",
                            }}
                            />
                        </Col>
                        <Col className="mt-30 mb-20" md="12" xs="12">
                            <Button
                                variant="success"
                                onClick={showParameters}
                            >
                                {t('obtener_parametros')}
                            </Button>
                        </Col>
                          
                        {
                            numCampos > 0 && arreglo.length > 0
                            ?
                            arreglo.map((item, index) => 
                                
                                <div key={index} className='row mt-5'>
                                     <Col md="4" xs="4">
                                     <label htmlFor="nombre" className="form-label">
                                        {t('parametro')}: 
                                    </label>
                                        <div className="form-group mt-5">
                                            <input
                                                name={item}
                                                id={item}
                                                type="text"
                                                className="form-control"
                                                placeholder={item}
                                                value={item}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    
                                    <Col md="8" xs="8">
                                    <label htmlFor="nombre" className="form-label">
                                        {t('tipo_dato')}: 
                                    </label>
                                        <div className="form-group mt-5">
                                            <select name={item} key={index} className="form-select" onChange={e => getParams(index, e)}>
                                                <option value="0">{t('seleccione')+" "+t('tipo_dato')}</option>
                                                {
                                                    tiposDato.map((item,index)=>
                                                        <option value={item.id_tipo}>{item.tipo}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </Col>

                                </div>

                            )
                            : <></>
                        }
                            
                        
                    </Row>
                </Form>
}
            </Modals>
        </>
        </>
    );
}

export default EditReportSQLData;