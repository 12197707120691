import {
    Row,
    Col,
    Button
} from 'react-bootstrap';
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react';
import { OverlayTooltip } from '../../../common.component'; 
import { launchSwal } from '../../../../utilities/funcs';
import { fields } from './fields.constant';
import { apiGetProductByTypeAndStore, apiGetRack, apiGetLevel, apiGetExistNotInArray, apiGetAllRacks, apiGetLevelsWithRack } from '../../../../common/api-invent';

export const Item = ({
    rowData,
    index,
    stores,
    typesMovement,
    onChangeField,
    onDeleteRow,
    setForm,
    form}) => {
    
    const {store, type_movement, product, rack_origin, level_origin, caja, rack, level} = rowData;


    const [products, setProducts] = useState([]);
    const [racks, setRacks] = useState([]);
    const [levels, setLevels] = useState([]);
    const [cajas, setCajas] = useState([]);
    const [allRacks, setAllracks] = useState([]);
    const [allLevels, setAllLevels] = useState([]);


    //OBTENEMOS LOS PRODUCTOS
    useEffect(() => {
        const getProducts = async () => {
            try{
                const response = await apiGetProductByTypeAndStore(type_movement,store);
                setProducts(response.data.content);
            }catch(e){
                launchSwal('error', 'Error al cargar datos')
            }
        }
        if(store === '0' || type_movement === '0') return
        getProducts()
    }, [store, type_movement])


    //OBTENEMOS LOS RACKS
    useEffect(() => {
        const getRacks = async () => {
            const response = await apiGetRack(store, product, type_movement);
            setRacks(response.data.content)
        }
        if(product === '0') return 
        getRacks()
    },[store, product, type_movement])

    //OBTENEMOS LOS NIVELES
    useEffect(() => {
        const getLevels = async () => {
            const response = await apiGetLevel(rack_origin, product, type_movement);
            setLevels(response.data.content)
        }
        if(rack_origin === '0') return 
        getLevels()
    },[rack_origin, product, type_movement])
    
    //OBTEBEMOS LAS CAJAS
    useEffect(() => {
        const getCajas = async () => {
            const exist = form.map((el, indexElement) => indexElement === index ? '0' : el.caja)
            const data = {
                id_almacen_ubicacion: level_origin,
                id_producto: product,
                id_tipo_salida: type_movement,
                id_existencias: exist
            }
            let response = await apiGetExistNotInArray(data);
            if (type_movement === '1'){
                response = response.data.content.map((el) => {
                    return {
                        nombre: `Caja ${el.id_caja} - Existencias: ${parseInt(el.cantidad_actual)}`,
                        id: el.id_entrada,
                        cantidad_actual: el.cantidad_actual,
                        id_existencia: el.id_existencia
                    }
                    
                })
            }else{
                response = response.data.content.map((el) => {
                    let nombre = '-';
                    for(let i = 0;  i < el.medidas.length; i++){
                        if (el.medidas[i].nombre === 'Largo'){
                            nombre = `${el.medidas[i].nombre} ${el.medidas[i].valor}${el.medidas[i].unidad} ${nombre}` 
                        }else if(el.medidas[i].nombre === 'Alto'){
                            nombre = `${nombre} ${el.medidas[i].nombre} ${el.medidas[i].valor}${el.medidas[i].unidad}`  
                        }
                    }
                    nombre = `${nombre} - Existencias: ${el.cantidad_actual}`
                    return {
                        nombre: nombre,
                        id: el.id_pedaceria_entrada,
                        cantidad_actual: el.cantidad_actual,
                        id_existencia: el.id_existencia,
                    }    
                })
            }
            if(response.length > 0) {
                setCajas(response)
            }
        }
        if(level_origin === '0') return 
        getCajas()
    },[level_origin, product, type_movement, form, index])

    //Obtener todos los racks del almacen
    useEffect(() => {
        const getAllRacksByStore = async () => {
            const response = await apiGetAllRacks(store);
            setAllracks(response.data.content)
        }
        if(store === '0') return 
        getAllRacksByStore()
    },[store])

    //Obtener todos los niveles del rack
    useEffect(() => {
        const getAllLevels = async () => {
            const response = await apiGetLevelsWithRack(rack);
            setAllLevels(response.data.content)
        }
        if(rack === '0') return 
        getAllLevels()
    },[rack])

    const cleanFields = (field, index) => {
        const fieldsToCLean = fields[field].cleanFields
        let aux = [...form]
        fieldsToCLean.forEach(element => {
            aux[index][element] = '0'
        });
        setForm([...aux])
    }

    const handleChange = (e) => {
        const index = Number(e.target.getAttribute('index'));
        cleanFields(e.target.name, index)
        onChangeField(e)
    }

    return (
        <div>
            <Row>
                <h4>Origen:</h4>
                <Row>
                    <Col xs="12" md="2">
                        <div className="form-group">
                            <label htmlFor="store" className="form-label">
                                Almacén: *
                            </label>
                            <select
                                className="form-select"
                                name="store"
                                id="store"
                                index={index}
                                value={store}
                                onChange={handleChange}
                            >
                                <option disabled value="0">Seleccione un almacén</option>
                                {
                                    stores.map(({ glb_nombre, glb_id_almacen }, index) => (
                                        <option value={glb_id_almacen} key={index}>
                                            {glb_nombre}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </Col>
                    <Col xs = "12" md = "2">
                        <div className="form-group">
                                <label htmlFor="type_movement" className ="form-label">
                                Tipo de Movimiento: *
                            </label>
                            <select 
                                name="type_movement" 
                                id="type_movement" 
                                className='form-select'
                                index={index}
                                value={type_movement}
                                onChange={handleChange}
                            >
                                <option disabled value="0">Selecione el tipo de movimiento:</option>
                                {
                                                typesMovement.map( ({glb_id_tipo_salida, glb_nombre}, index) => (
                                                    <option value={glb_id_tipo_salida} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                            </select>
                        </div>
                    </Col>
                    <Col xs="12" md="2">
                        <div className="form-group">
                            <label htmlFor="product" className="form-label">
                                Producto: *
                            </label>
                            <select
                                className="form-select"
                                id="product"
                                name="product"
                                index={index}
                                value={product}
                                onChange={handleChange}
                                disabled={store === '0' || type_movement === '0'}
                            >
                                <option disabled value="0">Selecione el producto:</option>
                                {
                                    products.map( ({id_producto, producto}, index) => (
                                        <option value={id_producto} key={index}>
                                            {producto}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </Col>
                    <Col xs="12" md="2">
                        <div className="form-group">
                            <label htmlFor="rack_origin" className="form-label">
                                Rack: *
                            </label>
                            <select
                                className="form-select"
                                name="rack_origin"
                                id="rack_origin"
                                index={index}
                                value={rack_origin}
                                disabled={product === '0'}
                                onChange={handleChange}
                            >
                                <option disabled value="0">Seleccione un rack</option>
                                {
                                    racks.map(({ nombre_rack, codigo_rack, id_rack }, index) => (
                                        <option value={id_rack} key={index}>
                                            {`${nombre_rack} - ${codigo_rack}`}
                                        </option>
                                    )) 
                                }
                            </select>
                        </div>
                    </Col>
                    <Col xs="12" md="2">
                        <div className="form-group">
                            <label htmlFor="level_origin" className="form-label">
                                Nivel: *
                            </label>
                            <select
                                className="form-select"
                                name="level_origin"
                                id="level_origin"
                                index={index}
                                value={level_origin}
                                disabled={rack_origin === '0'}
                                onChange={handleChange}
                            >
                                <option disabled value="0">Seleccione un nivel</option>
                                {
                                    levels.map(({ nombre_nivel, codigo_ubicacion, id_almacen_ubicacion }, index) => (
                                        <option value={id_almacen_ubicacion} key={index}>
                                            {`${nombre_nivel} - ${codigo_ubicacion}`}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </Col>
                    <Col xs="12" md="2">
                        <div className="form-group">
                            <label htmlFor="caja" className="form-label">
                                {type_movement === '1' ? 'Seleccione la caja *' : ' Medidas: *'}
                            </label>
                            <select
                                className="form-select"
                                name="caja"
                                id="caja"
                                index={index}
                                value={caja}
                                disabled={level_origin === '0'}
                                onChange={handleChange}
                            >
                                <option disabled value="0">{type_movement === '1' ? 'Seleccione una caja' : ' Medidas una pedaceria'}</option>
                                    {
                                        cajas.map(({ id_existencia, nombre }, index) => (
                                            <option value={id_existencia } key={index}>
                                                {nombre}
                                            </option>
                                        ))
                                    }
                            </select>
                        </div>
                    </Col>
                </Row>
            </Row>
            <Row>
                <h4>Destino:</h4>
                <Row>
                    <Col xs="12" md="2">
                        <div className="form-group">
                            <label htmlFor="rack" className="form-label">
                                Rack: *
                            </label>
                            <select
                                className="form-select"
                                name="rack"
                                id="rack"
                                index={index}
                                value={rack}
                                onChange={handleChange}
                            >
                                <option disabled value="0">Seleccione un rack</option>
                                {
                                    allRacks.map(({ nombre, codigo_rack, id_rack }, index) => (
                                        <option value={id_rack} key={index}>
                                            {`${nombre} - ${codigo_rack}`}
                                        </option>
                                    )) 
                                }
                            </select>
                        </div>
                    </Col>
                    <Col xs="12" md="2">
                        <div className="form-group">
                            <label htmlFor="level" className="form-label">
                                Nivel: *
                            </label>
                            <select
                                className="form-select"
                                name="level"
                                id="level"
                                index={index}
                                value={level}
                                disabled={rack === '0'}
                                onChange={handleChange}
                            >
                                <option disabled value="0">Seleccione un nivel</option>
                                {
                                    allLevels.map(({id_almacen_ubicacion, nombre, codigo_ubicacion}, index) => (
                                        <option key={index} value={id_almacen_ubicacion}>{`${nombre} - ${codigo_ubicacion}`}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </Col>
                    <Col xs="12" md="2" style={{ paddingTop: "30px" }}>
                        <OverlayTooltip
                            placement="top"
                            tooltip="Eliminar"
                        >
                            <Button
                                size="sm"
                                variant="danger"
                                className='waves-effect waves-light'
                                onClick={onDeleteRow}
                                index={index}
                            >
                                <i className='si si-trash' />
                            </Button>
                        </OverlayTooltip>
                    </Col>
                </Row>
            </Row>
            <hr />
        </div>
    )
}

Item.propTypes = {
    rowData: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    stores: PropTypes.array.isRequired,
    typesMovement: PropTypes.array.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onDeleteRow: PropTypes.func.isRequired,
    setForm: PropTypes.func.isRequired,
    form: PropTypes.array.isRequired
}